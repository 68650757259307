import React, { useEffect, useReducer } from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  useParams,
} from "react-router-dom";
import AuthLayout from "./AuthLayout.jsx";
import UserLayout from "./UserDashLayout.jsx";
import AgencyContext from "context/AgencyContext";
import agencyReducer from "reducers/agencyReducer";
import CreatePassword from "views/pages/CreatePassword.jsx";
import Error404 from "views/pages/Error404.jsx";
import api from "components/API/api";
import RegisterLayout from "layouts/RegisterLayout";

// import ChequePickup from "views/ChequePickup.jsx";
import UserDocumentUpload from "views/UserProfile/DocumentUpload.jsx";
// import ChequeIssues from "views/ChequeIssues.jsx";
// import ChequeProcessing from "views/ChequeProcessing.jsx";
import TrainingContext from "context/TrainingContext.js";
import RegisterContext from "context/RegistrationContext";
import registrationReducer from "reducers/registrationReducer";
import trainingReducer from "reducers/trainingReducer";

const initialState = () => {
  // const user = localStorage.getItem("user");
  // const profile = localStorage.getItem("profile");
  // const agency = localStorage.getItem("agency");

  // return {
  //   user: user && JSON.parse(user),
  //   agency: agency && JSON.parse(agency),
  //   profile: profile && JSON.parse(profile),
  // };
  return {
    user: null,
    agency: null,
    profile: null,
    images: [],
  };
};
const initialRegistrationState = () => {
  return {
    agency: null,
    user: null,
  };
};

const initialTraining = () => {
  return {
    selectedTraining: { trainingId: null, index: 0 },
    trainingList: [],
  };
};

function Admin(props) {
  const [agency, agencyDispatch] = useReducer(agencyReducer, initialState());
  let { slug } = useParams();
  console.log(slug)
  useEffect(() => {
    loadAgencyFromSlug(slug);
  }, []);

  const [training, trainingDispatch] = useReducer(
    trainingReducer,
    initialTraining()
  );
  const [registration, registrationDispatch] = useReducer(
    registrationReducer,
    initialRegistrationState()
  );

  const loadAgencyFromSlug = (slug) => {
    console.log(slug)
    api()
      .get(`/agency/get/${slug}`)
      .then((result) => {

        agencyDispatch({
          type: "SET_AGENCY",
          payload: { agency: result.data },
        });
      })
      .catch((e) => {
        props.history.push(`/${slug}/error`);
        console.log(e);
      });
  };
  return (
    <AgencyContext.Provider value={{ agency, agencyDispatch }}>
      <TrainingContext.Provider value={{ training, trainingDispatch }}>
        <RegisterContext.Provider
          value={{ registration, registrationDispatch }}
        >
          <Switch>
            <Route
              path="/:slug/auth"
              render={(props) => <AuthLayout {...props} />}
            />
            <Route
              path="/:slug/register"
              render={(props) => <RegisterLayout {...props} />}
            />
            <Route
              path="/:slug/upload-image"
              render={(props) => <UserDocumentUpload {...props} />}
            />
            {/* <Route
              exact
              path="/:slug/cheque-pickup"
              render={(props) => <ChequePickup {...props} />}
            />
            <Route
              exact
              path="/:slug/cheque-issues"
              render={(props) => <ChequeIssues {...props} />}
            />
            <Route
              exact
              path="/:slug/cheque-processing"
              render={(props) => <ChequeProcessing {...props} />}
            /> */}
            {/* <Route path="/:slug/admin" render={(props) => <AdminLayout {...props} />} /> */}
            <Route
              path="/:slug/create-password"
              render={(props) => <CreatePassword {...props} />}
            />
            {/* <Route path="/:slug/admin/create-shift" render={(props) => <CreateShifts {...props} />} /> */}
            <Route
              path="/:slug/error"
              render={(props) => <Error404 {...props} />}
            />
            <Route
              path="/:slug"
              render={(props) => <UserLayout {...props} />}
            />
            <Route render={(props) => <Error404 {...props} />} />
          </Switch>
        </RegisterContext.Provider>
      </TrainingContext.Provider>
    </AgencyContext.Provider>
  );
}

export default Admin;
