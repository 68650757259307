import React, { useEffect, useState } from "react";
import moment from "moment";
import classnames from "classnames";

export default function MonthPicker({ date, onChange }) {
  const [open, setOpen] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(
    new Date(date).getMonth() || new Date().getMonth(),
  );

  const [tempYear, setTempYear] = useState(
    new Date(date).getFullYear() || new Date().getFullYear(),
  );

  useEffect(() => {
    if (
      new Date(date).getFullYear() != year ||
      new Date(date).getMonth() != month
    ) {
      onChange(new Date(moment([year, month])));
    }
  }, [month, year]);

  useEffect(() => {
    setYear(new Date(date).getFullYear());
    setTempYear(new Date(date).getFullYear());
    setMonth(new Date(date).getMonth());
  }, [date]);

  const allMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <div
      className={classnames("rdt", { rdtOpen: open })}
      onBlur={() => {
        setOpen(false);
      }}
      tabindex="0"
    >
      <span
        onClick={() => {
          setOpen(true);
        }}
        style={{ cursor: "pointer" }}
      >
        {allMonths[month]} {year}
      </span>
      <div className="rdtPicker">
        <div className="rdtMonths">
          <table>
            <thead>
              <tr>
                <th className="rdtPrev">
                  <span
                    onClick={() => {
                      setTempYear(tempYear - 1);
                    }}
                  >
                    ‹
                  </span>
                </th>
                <th
                  className="rdtSwitch"
                  colspan="2"
                  data-value="2020"
                  onClick={() => setOpen(false)}
                >
                  {tempYear}
                </th>
                <th className="rdtNext">
                  <span
                    onClick={() => {
                      setTempYear(tempYear + 1);
                    }}
                  >
                    ›
                  </span>
                </th>
              </tr>
            </thead>
          </table>
          <table>
            <tbody>
              <tr>
                <td
                  data-value="0"
                  className={classnames("rdtMonth", {
                    rdtActive: tempYear === year && month === 0,
                  })}
                  onClick={() => {
                    setMonth(0);
                    setYear(tempYear);
                  }}
                >
                  Jan
                </td>
                <td
                  className={classnames("rdtMonth", {
                    rdtActive: tempYear === year && month === 1,
                  })}
                  onClick={() => {
                    setYear(tempYear);
                    setMonth(1);
                  }}
                >
                  Feb
                </td>
                <td
                  className={classnames("rdtMonth", {
                    rdtActive: tempYear === year && month === 2,
                  })}
                  onClick={() => {
                    setYear(tempYear);
                    setMonth(2);
                  }}
                >
                  Mar
                </td>
                <td
                  className={classnames("rdtMonth", {
                    rdtActive: tempYear === year && month === 3,
                  })}
                  onClick={() => {
                    setYear(tempYear);
                    setMonth(3);
                  }}
                >
                  Apr
                </td>
              </tr>
              <tr>
                <td
                  className={classnames("rdtMonth", {
                    rdtActive: tempYear === year && month === 4,
                  })}
                  onClick={() => {
                    setYear(tempYear);
                    setMonth(4);
                  }}
                >
                  May
                </td>
                <td
                  className={classnames("rdtMonth", {
                    rdtActive: tempYear === year && month === 5,
                  })}
                  onClick={() => {
                    setYear(tempYear);
                    setMonth(5);
                  }}
                >
                  Jun
                </td>
                <td
                  className={classnames("rdtMonth", {
                    rdtActive: tempYear === year && month === 6,
                  })}
                  onClick={() => {
                    setYear(tempYear);
                    setMonth(6);
                  }}
                >
                  Jul
                </td>
                <td
                  className={classnames("rdtMonth", {
                    rdtActive: tempYear === year && month === 7,
                  })}
                  onClick={() => {
                    setYear(tempYear);
                    setMonth(7);
                  }}
                >
                  Aug
                </td>
              </tr>
              <tr>
                <td
                  className={classnames("rdtMonth", {
                    rdtActive: tempYear === year && month === 8,
                  })}
                  onClick={() => {
                    setYear(tempYear);
                    setMonth(8);
                  }}
                >
                  Sep
                </td>
                <td
                  className={classnames("rdtMonth", {
                    rdtActive: tempYear === year && month === 9,
                  })}
                  onClick={() => {
                    setYear(tempYear);
                    setMonth(9);
                  }}
                >
                  Oct
                </td>
                <td
                  className={classnames("rdtMonth", {
                    rdtActive: tempYear === year && month === 10,
                  })}
                  onClick={() => {
                    setYear(tempYear);
                    setMonth(10);
                  }}
                >
                  Nov
                </td>
                <td
                  className={classnames("rdtMonth", {
                    rdtActive: tempYear === year && month === 11,
                  })}
                  onClick={() => {
                    setYear(tempYear);
                    setMonth(11);
                  }}
                >
                  Dec
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
