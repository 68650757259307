import React, { useState, useRef, useEffect, useContext } from "react";
import RegistrationContext from "../../../context/RegistrationContext";
import { Row, Button, Col, Progress } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import TrainingContext from "context/TrainingContext";

let isIOS =
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

function TrainingVideo({ section, history, sectionId, index, lastModule }) {
  const { training, trainingDispatch } = useContext(TrainingContext);
  const { slug } = useParams();
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(!isIOS);
  const [played, setPlayed] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [skip, setSkip] = useState(false);

  const readyRef = useRef(isReady);
  readyRef.current = isReady;

  useEffect(() => {
    const intervalId = setInterval(async () => {
      let currentTime = await playerRef.current.getCurrentTime();
      let duration = await playerRef.current.getDuration();
      const newPlayed = (currentTime / duration) * 100;
      if (newPlayed > 98 && !completed) {
        setCompleted(true);
        trainingDispatch({ type: "SET_DONE" });
      }
      setPlayed(newPlayed);
    }, 500);

    let timeout = setTimeout(() => {
      if (!readyRef.current) {
        setSkip(true);
      }
    }, 10000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {}, [index]);
  return (
    <>
      <div
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            left: 0,
            top: 0,
            zIndex: 999,
          }}
        ></div>
        <ReactPlayer
          style={{ zIndex: 1 }}
          url={`https://www.youtube.com/watch?v=${section.url}`}
          playing={playing}
          controls={false}
          ref={playerRef}
          width={"100%"}
          loop={false}
          onReady={() => {
            setIsReady(true);
            console.log("ready");
          }}
        />
      </div>
      <Progress value={played}></Progress>
      <p style={{ textAlign: "center" }}>
        Please watch the video to the end to continue.
      </p>
      <div style={{ textAlign: "center" }}>
        {!isReady ? (
          <Button>
            {skip
              ? "Video didn't load.. You can skip the video."
              : "Loading..."}
          </Button>
        ) : (
          <>
            <Button
              onClick={() => {
                setPlaying(false);
              }}
            >
              Pause
            </Button>
            <Button
              onClick={() => {
                setPlaying(true);
              }}
            >
              Play
            </Button>
            <Button
              onClick={() => {
                let currentTime = playerRef.current.getCurrentTime();
                playerRef.current.seekTo(currentTime - 10);
              }}
            >
              Back 10 sec
            </Button>
          </>
        )}
      </div>
      <Row>
        <Col>
          <Link to={`/${slug}/register/training`}>
            <Button style={{ marginTop: 16 }}>Menu</Button>
          </Link>
        </Col>
        <Col style={{ textAlign: "right" }}>
          {/*<Button
            style={{ marginTop: 16 }}
            color={"primary"}
            disabled={!(section.done || completed || skip)}
            onClick={(e) => {
              if (!section.done) {
                trainingDispatch({
                  type: "SET_DONE",
                });
                setCompleted(false);
              }
              trainingDispatch({
                type: "SET_SELECTED_INDEX",
                _index: index + 1,
              });
            }}
          >
            {lastModule ? "Finish" : "Next"}
          </Button>*/}

          <Button 
            style={{ marginTop: 16 /*, display: "none" */}} // showing dev next as requested by agency: David
            color={"primary"}
            onClick={(e) => {
              trainingDispatch({
                type: "SET_DONE",
              });
              trainingDispatch({
                type: "SET_SELECTED_INDEX",
                _index: index + 1,
              });
            }}
          >
            Next
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default TrainingVideo;
