// const setUser = (user) => {
//   return saveStateToLocalStorage({ user: {...user} })
// }

// const removeUser = () => {
//   return saveStateToLocalStorage({})
// }

// const saveStateToLocalStorage = (state) => {
//   localStorage.setItem("userState", JSON.stringify(state));
//   return state;
// };

const userReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };
    case "SET_AGENCY":
      return {
        ...state,
        agency: action.agency,
      };
    case "REMOVE_USER":
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};

export default userReducer;
