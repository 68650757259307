import React from "react";
import { Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

export default function LoadingOverlay(props) {
  return (
    <SweetAlert
      style={{
        display: "block",
        marginTop: "-100px",
        background: "transparent",
      }}
      title={
        <div>
          <p>{props.message}</p>
          <Spinner color="light" style={{ width: "3rem", height: "3rem" }} />
        </div>
      }
      showConfirm={false}
    />
  );
}
