import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  CardHeader,
  Row,
  Col,
  Button,
  FormGroup,
} from "reactstrap";
import { Modal } from "@material-ui/core";
import moment from "moment";
import AgencyContext from "context/AgencyContext";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import api from "components/API/api";
import {
  Business,
  LocationOn,
  Visibility,
  Android,
  Apple,
  Clear,
} from "@material-ui/icons";
import { Link, useHistory, useParams } from "react-router-dom";
import { statusEnum } from "components/Utilities/utilities";
import { startOfDay, startOfNextDay } from "utils/dateUtils";
import {
  afternoonTimeRange,
  morningTimeRange,
  nightTimeRange,
  shiftTimeType,
} from "utils/dateUtils";
import { AGENCY_INFO } from "AgencyInfo";

const Module = function ({ shift }) {
  console.log("shift", shift);
  const history = useHistory();
  const [openPickUpModal, setOpenPickUpModal] = useState(false);

  // Opens modal to assign user to driver
  const handleOpenPickUpModal = () => {
    setOpenPickUpModal(true);
  };

  const handleClosePickUpModal = () => {
    setOpenPickUpModal(false);
  };

  ////////////////////////////////////////////////////
  //Pick up modal
  ////////////////////////////////////////////////////
  const PickUpModal = () => {
    return (
      <Modal
        open={openPickUpModal}
        onClose={handleClosePickUpModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup
          style={{ width: 450, backgroundColor: "#fff", borderRadius: 10 }}
        >
          <div style={{ padding: 15 }}>
            {/* <div style={{ justifyContent: "center", display: "flex" }}> */}
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Col md="10">
                <h5>
                  Pick up shifts up to 30 days in advance, view your own work
                  history, and more via the {AGENCY_INFO.name} Worker's App
                </h5>
              </Col>
              <Col>
                <Button
                  style={{ marginTop: "-5px" }}
                  onClick={() => handleClosePickUpModal()}
                  color="default"
                  size="md"
                  className="btn-link btn-icon"
                >
                  <Clear style={{ marginLeft: -1 }} />
                </Button>{" "}
              </Col>
            </Row>

            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                marginTop: 15,
              }}
            >
              <Button color="default" size="sm">
                <a
                  href={AGENCY_INFO.IOS}
                  style={{ color: "white" }}
                >
                  <Apple /> Download for Ios
                </a>
              </Button>
              <Button color="success" size="sm">
                <a
                  href={AGENCY_INFO.ANDROID}
                  style={{ color: "white" }}
                >
                  <Android />
                  Download for Android
                </a>
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  return (
    shift && (
      <Card className="no-transition" style={{ backgroundColor: "#fcfcfc" }}>
        <CardHeader style={{ cursor: "pointer" }}>
          <div className="d-flex flex-column align-items-center">
            <CardTitle tag="h6">
              {shift.start && moment(shift.start).format("ddd MMM DD, YYYY")}
            </CardTitle>
            <CardTitle tag="h6">
              @{shift.start && moment(shift.start).format("hh:mm a")}
              {" - "}
              {shift.end && moment(shift.end).format("hh:mm a")}
            </CardTitle>
          </div>
        </CardHeader>
        <CardBody>
          <div className="d-flex flex-column">
            <div className="d-flex  m-1">
              <div style={{ flex: 1 }}>
                <Business />
              </div>
              <span style={{ flex: 3 }}>{shift.businessName}</span>
            </div>
            <div className="d-flex m-1">
              <div style={{ flex: 1 }}>
                <LocationOn />
              </div>
              <span style={{ flex: 3 }}>{shift.businessLocation}</span>
            </div>
            <div className="d-flex flex-row align-items-center">
              <Button
                onClick={() => handleOpenPickUpModal()}
                color="primary"
                size="sm"
              >
                Pick Up Shift
              </Button>
              <Button
                style={{ display: "flex", justifyContent: "flex-end" }}
                onClick={() =>
                  history.push({
                    pathname: `/${AGENCY_INFO.slug}/shift-board/${shift._id}`,
                    state: shift,
                  })
                }
                size="md"
                className="btn-link btn-icon"
              >
                <Visibility style={{ marginLeft: -1 }} />
              </Button>
            </div>
            {PickUpModal()}
          </div>
        </CardBody>
      </Card>
    )
  );
};

export default function ShiftBoard() {
  const [shifts, setShifts] = useState([]);
  const context = useContext(AgencyContext);
  const agency = context.agency.agency;
  const [isLoading, setIsLoading] = useState(false);
  const [rawData, setRawData] = useState([]);
  const [selectedShiftFilter, setSelectedShiftFilter] = useState("Morning");

  useEffect(() => {
    let tempData = [];
    let timeRange;
    switch (selectedShiftFilter) {
      case "Morning":
        timeRange = morningTimeRange(new Date());
        rawData.map((shift) => {
          if (moment(shift.start).isBetween(timeRange.start, timeRange.end)) {
            tempData.push(shift);
          }
        });
        setShifts(tempData);

        break;
      case "Afternoon":
        timeRange = afternoonTimeRange(new Date());
        rawData.map((shift) => {
          if (moment(shift.start).isBetween(timeRange.start, timeRange.end)) {
            tempData.push(shift);
          }
        });
        setShifts(tempData);
        break;
      default:
        timeRange = nightTimeRange(new Date());
        rawData.map((shift) => {
          if (moment(shift.start).isBetween(timeRange.start, timeRange.end)) {
            tempData.push(shift);
          }
        });
        setShifts(tempData);
    }
  }, [selectedShiftFilter, rawData]);

  useEffect(() => {
    if (agency) {
      fetchData(new Date());
    }
  }, [agency]);

  const fetchData = async (date) => {
    try {
      setIsLoading(true);
      const { data } = await api().get("/shift/available/worker", {
        params: {
          start: startOfDay(date),
          end: startOfNextDay(date),
        },
      });
      console.log(data);
      setRawData(data);
      setIsLoading(false);
      //console.log(data.shifts);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  console.log(window.innerWidth)

  return (
    <div className="content">
      <Row>
        <Col>
          <Card className="no-transition">
            <CardHeader>
              <div className="d-flex justify-content-between">
                <CardTitle tag="h4">Shift Board</CardTitle>
              </div>
            </CardHeader>
            <CardBody>
              <Row
                className="d-flex justify-content-around"
                md="12"
                style={{ marginBottom: 20 }}
              >
                <Button
                  color={
                    selectedShiftFilter === "Morning" ? "primary" : "default"
                  }
                  onClick={() => setSelectedShiftFilter("Morning")}
                >
                  {window.innerWidth > 600 ? "Morning" : "Morn"}
                </Button>
                <Button
                  color={
                    selectedShiftFilter === "Afternoon" ? "primary" : "default"
                  }
                  onClick={() => setSelectedShiftFilter("Afternoon")}
                >
                  {window.innerWidth > 600 ? "Afternoon" : "After"}
                </Button>
                <Button
                  color={
                    selectedShiftFilter === "Night" ? "primary" : "default"
                  }
                  onClick={() => setSelectedShiftFilter("Night")}
                >
                  {window.innerWidth > 600 ? "Night" : "Evng"}
                </Button>
              </Row>
              {isLoading ? (
                <LoadingOverlay />
              ) : (
                <Row>
                  {shifts.length > 0 ? (
                    shifts.map((shift, i) => (
                      <Col lg="3" md="6" sm="8">
                        <Module shift={shift} key={i} index={i} />
                      </Col>
                    ))
                  ) : (
                    <p>
                      {" "}
                      Uh oh, it seems like {`there's`} nothing available. Check
                      back later!
                    </p>
                  )}
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
