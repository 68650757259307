import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { FormGroup, Button, Row, Col, Input, Label } from "reactstrap";

function PdfViewer({ pdf, submit, handleBack }) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [privacyCheck, setPrivacyCheck] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <FormGroup>
      <Row
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          margin: 40,
          marginRight: 0,
        }}
      >
        <Button onClick={() => window.open(URL.createObjectURL(pdf), "_blank")}>
          Download
        </Button>
      </Row>

      <Document
        file={pdf}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={() => <div>Loading...</div>}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: 20,
          marginBottom: 40,
        }}
      >
        <Button
          onClick={() => pageNumber !== 1 && setPageNumber(pageNumber - 1)}
        >
          Previous
        </Button>
        <p>
          Page {pageNumber} of {numPages}
        </p>
        <Button
          onClick={() =>
            pageNumber !== numPages && setPageNumber(pageNumber + 1)
          }
        >
          Next
        </Button>
      </div>
      <Row
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "start",
        }}
      >
        <Col style={{ color: "#faa632", fontWeight: "bold" }}>
          <FormGroup check inline>
            <Label check>
              <Input
                type="checkbox"
                value={privacyCheck}
                onChange={() => {
                  if (privacyCheck === false) {
                    setPrivacyCheck(true);
                  } else {
                    setPrivacyCheck(false);
                  }
                }}
              />
              <span className="form-check-sign" />
              <Row
                style={{ color: "#faa632", fontWeight: "bold", marginLeft: 5 }}
              >
                I agree to the Terms of Use and Privacy Policy
              </Row>
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col xs="6" align="left">
          <Button onClick={handleBack}>back</Button>
        </Col>
        <Col xs="6" align="right">
          <Button
            color="success"
            disabled={!privacyCheck}
            onClick={() => {
              submit();
            }}
          >
            Register
          </Button>
        </Col>
      </Row>
    </FormGroup>
  );
}

export default PdfViewer;
