import React, { useState, useContext, useEffect, Fragment } from "react";
import RegistrationContext from "context/RegistrationContext";
import {
  Card,
  CardBody,
  Container,
  CardHeader,
  Row,
  Col,
  Button,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";
import { faTimes, faLock } from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "react-bootstrap-sweetalert";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import LoadingOverlay from "components/OverLay/LoadingOverlay";

import api from "components/API/api";
import AgencyContext from "context/AgencyContext";
import TrainingContext from "context/TrainingContext";
import { setConstantValue } from "typescript";
import useUnload from "components/Hooks/useUnload";
const ONLOAD_MESSAGE = `Training must be completed for us to send you to work. If you do not complete now, you must complete at a later time by logging into your account from the login button on the top right corner.`;

export default function TrainingMenu(props) {
  const { registration, registrationDispatch } = useContext(
    RegistrationContext,
  );
  const { training, trainingDispatch } = useContext(TrainingContext);

  const { agency } = useContext(AgencyContext);

  const [alert, setAlert] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { slug } = useParams();

  const sectionIsCorrect = (section, index, currentTraining) =>
    section.done &&
    (!section.quiz || section.quiz.correctAnswer === section.quiz.userAnswer);
  const sectionIsLocked = (section, index, currentTraining) =>
    section.done
      ? false
      : index === 0
      ? false
      : currentTraining.content[index - 1].isCorrect
      ? false
      : currentTraining.content[index - 1].done
      ? false
      : true;
  const history = useHistory();

  useEffect(() => {
    if (!registration.user && !agency.user) {
      setAlert(
        <SweetAlert
          danger
          title="Please, register your user before training."
          onConfirm={() => {
            setAlert(null);
            props.history.push(`/${slug}/register`);
          }}
        />,
      );
    } else {
      if (training.trainingList && training.trainingList.length <= 0) {
        let tempTraining = [];
        let agencyId =
          (registration.agency && registration.agency._id) ||
          (agency.agency && agency.agency._id);
        let userId =
          (registration.user && registration.user._id) ||
          (agency.user && agency.user._id);
        setIsLoading(true);
        api()
          // .get(`/qualification/mandatory/${registration.agency._id}`)
          .get("/qualification/mandatory/incomplete", {
            params: { userId: userId, agencyId: agencyId },
          })
          .then((res) => {
            let qualifications = res.data;
            console.log(qualifications);
            qualifications.length &&
              qualifications.forEach((qualification) => {
                let newTrainings = qualification.trainings.filter((t) => {
                  return !tempTraining.some((temp) => temp._id === t._id);
                });
                tempTraining = [...tempTraining, ...newTrainings];
              });
            setIsLoading(false);
            trainingDispatch({
              type: "SET_ALL_TRAINING_DATA",
              trainingList: tempTraining,
            });
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  }, [agency.user]);

  // const unloading = () => {
  //   setAlert(
  //     <SweetAlert
  //       danger
  //       // title={`Are you sure you want to `}
  //       onConfirm={() => {
  //         setAlert(null);
  //       }}
  //     >
  //       {ONLOAD_MESSAGE}
  //     </SweetAlert>,
  //   );
  //   return "hey you are missing something";
  // };
  // const onUnload = (...args) => cb.current?.(...args);

  //   window.addEventListener("beforeunload", onUnload);

  //   return () => window.removeEventListener("beforeunload", onUnload);

  // useEffect(() => {
  //   window.addEventListener("beforeunload", unloading);

  //   return () => {
  //     window.removeEventListener("beforeunload", unloading);
  //   };
  // }, []);

  useUnload((e) => {
    e.preventDefault();
    e.returnValue = { ONLOAD_MESSAGE };
    setAlert(
      <SweetAlert
        warning
        // title={`Are you sure you want to `}
        onConfirm={() => {
          setAlert(null);
        }}
      >
        {ONLOAD_MESSAGE}
      </SweetAlert>,
    );
  });
  const getTrainingHistory = () => {
    const history = training.reduce((acc, { title, attempts }, idx) => {
      if (attempts !== undefined) {
        acc.push({ title, attempts });
      }
      return acc;
    }, []);
    return history;
  };

  const createUserAccount = async (user) => {
    let { data } = await api().post("/users/register-worker", user);
    return data;
  };

  const onFinishTraining = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    // try {
    const today = new Date();
    const todayPlusOneYear = new Date();
    todayPlusOneYear.setFullYear(todayPlusOneYear.getFullYear() + 1);

    let currentDate = new Date();

    // let emergency = [
    //   {
    //     firstName: registration.user.emergencyFirstName,
    //     lastName: registration.user.emergencyLastName,
    //     phone: registration.user.emergencyPhone,
    //     relationship: registration.user.emergencyRelationship,
    //   },
    // ];

    // if (registration.user.emergencyFirstName2) {
    //   emergency.push({
    //     firstName: registration.user.emergencyFirstName2,
    //     lastName: registration.user.emergencyLastName2,
    //     phone: registration.user.emergencyPhone2,
    //     relationship: registration.user.emergencyRelationship2,
    //   });
    // }
    // let dataToSend = {
    //   // General Information
    //   firstName: registration.user.firstName,
    //   lastName: registration.user.lastName,
    //   availability: registration.user.availability,
    //   gender: registration.user.gender,
    //   dob: new Date(registration.user.dob),
    //   phone: registration.user.phone,
    //   email: registration.user.email,
    //   address: registration.user.address,
    //   city: registration.user.city,
    //   province: registration.user.province,
    //   postalCode: registration.user.postalCode,
    //   sin: registration.user.sin,
    //   legallyWork: registration.user.legallyWork,
    //   // selected: false,
    //   workStatus: registration.user.workStatus,
    //   emergency,

    //   agencyId: registration.user.agencyId,
    //   // profileType: "Worker",
    //   password: registration.user.password,
    // };

    // //create user account
    // let { user } = await createUserAccount(dataToSend);
    let userId =
      (registration.user && registration.user._id) ||
      (agency.user && agency.user._id);

    if (userId) {
      let promises = [];
      // const axiosConfig = {
      //   headers: {
      //     "content-type": "multipart/form-data",
      //   },
      // };
      //send training data
      if (training.trainingList && training.trainingList.length > 0) {
        training.trainingList.forEach((t) => {
          let expires = new Date(moment().add(t.expire, "days"));
          let trainingComplete = api().post("/training-history", {
            training: t._id,
            user: userId,
            expires: expires,
            completedDate: new Date(),
            completed: true,
          });
          promises.push(trainingComplete);
        });
      }

      try {
        await Promise.all(
          promises.map((promise) => {
            promise
              .then((response) => {
                console.log(response);
                console.log(
                  "successfully completed the training",
                  response.data.message,
                );
              })
              .catch((message) => {
                console.log(message);
              });
          }),
        ).then(() => {
          setAlert(null);
          setIsLoading(false);
          setAlert(
            <SweetAlert
              success
              title={`Registration successful!`}
              onConfirm={() => {
                setAlert(null);
                registrationDispatch({ type: "REMOVE_USER" });
                trainingDispatch({ type: "RESET" });
                if (registration.user) {
                  props.history.push(`/${slug}/auth/login`);
                } else {
                  props.history.push(
                    `/${props.match.params.slug}/upcoming-shifts`,
                  );
                }
              }}
            >
              {registration.user
                ? " Your registration and training is complete! You may now close this window."
                : "You have successfully completed the trainings. You may close this window"}
            </SweetAlert>,
          );
          registrationDispatch({ type: "REMOVE_USER" });
          trainingDispatch({ type: "RESET" });
          return false;
        });
      } catch (error) {
        setAlert(null);
        setAlert(
          <SweetAlert
            danger
            title={`An error has occured: ${error.code}`}
            onConfirm={() => {
              setAlert(null);
            }}
          >
            {error.code} Please log into your account to complete the training.
          </SweetAlert>,
        );
      }
    } else {
      setAlert(null);
      setAlert(
        <SweetAlert
          danger
          title={`Training Couldn't be completed.`}
          onConfirm={() => {
            setAlert(null);
          }}
        >
          Please log into your account to complete the training.
        </SweetAlert>,
      );
      setIsLoading(false);
      return false;
    }
    //  catch (e) {
    //   console.log("error setting up user, ", e);
    //   setAlert(null);
    //   setAlert(
    //     <SweetAlert
    //       danger
    //       title={`An unexpected error has occurred`}
    //       onConfirm={() => {
    //         setAlert(null);
    //       }}
    //     >
    //       {e.message}
    //     </SweetAlert>,
    //   );
    //   console.log(e);
    //   setIsLoading(false);
    //   return false;
    // }
  };

  const RenderNextButton = () => {
    let no_incompleteTrainings =
      training.trainingList &&
      training.trainingList.length &&
      training.trainingList.reduce((acc, t) => {
        if (!t.done) {
          acc += 1;
        }
        return acc;
      }, 0);

    let alreadyStarted = false;
    let firstIncompleteIndex = 0;
    let firstIncompleteTraining = null;

    //find if the user has already started the training;
    // console.log(training.trainingList);
    if (training.trainingList && training.trainingList.length) {
      for (let i = 0; i < training.trainingList.length; i++) {
        let content = training.trainingList[i].content;
        console.log("content", content);
        for (let j = 0; j < content.length; j++) {
          if (content[j].done || content[j].isCorrect) {
            alreadyStarted = true;
            break;
          }
          if (alreadyStarted) break;
        }
      }

      //find the first training that user can continue on.
      for (let i = 0; i < training.trainingList.length; i++) {
        let content = training.trainingList[i].content;
        for (let j = 0; j < content.length; j++) {
          if (!content[j].done) {
            firstIncompleteIndex = j;
            firstIncompleteTraining = training.trainingList[i]._id;
            break;
          }
        }
        if (firstIncompleteTraining) {
          break;
        }
      }
    }
    // return (
    //   <Button
    //     color="primary"
    //     style={{ marginTop: 16 }}
    //     disabled={isLoading}
    //     onClick={() => {
    //       onFinishTraining();
    //     }}
    //   >
    //     Finish
    //   </Button>
    // );
    if (no_incompleteTrainings > 0) {
      return (
        <Button
          color="primary"
          style={{ marginTop: 16 }}
          disabled={isLoading}
          onClick={() => {
            trainingDispatch({
              type: "SET_SELECTED_TRAINING",
              _id: firstIncompleteTraining,
              _index: firstIncompleteIndex,
            });
            history.push(`/${slug}/register/training/module`);
          }}
        >
          {alreadyStarted ? "Continue" : "Start"}
        </Button>
      );
    }
    return (
      <Button
        color="primary"
        style={{ marginTop: 16 }}
        disabled={isLoading}
        onClick={() => {
          onFinishTraining();
        }}
      >
        Finish
      </Button>
    );
  };

  const RenderIcon = ({ isLocked, isCorrect, isDone }) => {
    // if (isLocked) {
    //   return <FontAwesomeIcon icon={faLock} style={{ color: "gray" }} />;
    // }
    if (isDone) {
      return <FontAwesomeIcon icon={faCheckSquare} style={{ color: "gray" }} />;
    } else {
      return <FontAwesomeIcon icon={faSquare} style={{ color: "gray" }} />;
    }
    // else if (isComplete) {
    //   return <FontAwesomeIcon icon={check-square} style={{ color: "gray" }} />;
    // }

    // else } else {
    //   return <FontAwesomeIcon icon={faTimes} style={{ color: "gray" }} />;
    // }
  };

  // const RenderList = () => {
  //   return training.map((section, index) => {
  //     const isLocked = sectionIsLocked(section, index);
  //     const isCorrect = sectionIsCorrect(section);
  //     if (isCorrect || isLocked) {
  //       return null;
  //     }
  //     return (
  //       <Fragment key={section.id}>
  //         <Link
  //           to={isLocked ? "#" : `/${slug}/register/training/${section.id}`}
  //         >
  //           <Row
  //             style={{
  //               height: 40,
  //               alignContent: "center",
  //               backgroundColor: "#e9ecef",
  //               margin: 0,
  //               marginTop: 16,
  //               borderRadius: 8,
  //             }}
  //           >
  //             <Col>
  //               <span style={{ color: "gray" }}>{section.title}</span>
  //             </Col>
  //             <Col style={{ textAlign: "right" }}>
  //               <RenderIcon
  //                 isLocked={isLocked}
  //                 isCorrect={isCorrect}
  //                 isDone={section.done}
  //               />
  //             </Col>
  //           </Row>
  //         </Link>
  //       </Fragment>
  //     );
  //   });
  // };

  const RenderAllTrainings = ({ training }) => {
    return (
      <Fragment key={training._id}>
        <div
          onClick={() => {
            if (!training.isLocked) {
              trainingDispatch({
                type: "SET_SELECTED_TRAINING",
                _id: training._id,
                _index: 0,
              });
              history.push(`/${slug}/register/training/module`);
            }
          }}
        >
          <Row
            style={{
              height: 40,
              alignContent: "center",
              backgroundColor: "#e9ecef",
              margin: 0,
              marginTop: 16,
              borderRadius: 8,
            }}
          >
            <Col>
              <span style={{ color: "gray" }}>{training.name}</span>
            </Col>
            <Col style={{ textAlign: "right" }}>
              <RenderIcon isDone={training.done} />
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  };
  return (
    <Container>
      <Card style={{ marginTop: 20 }}>
        <Container>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h4>Training</h4>
            </div>
            <p>
              Please allow some time to complete this training module and submit
              your application.
            </p>
            <hr />
          </CardHeader>
          <CardBody>
            {isLoading ? (
              <div className="justify-content-center d-flex">
                <Spinner />
              </div>
            ) : training.trainingList && training.trainingList.length ? (
              training.trainingList.map((t) => {
                // console.log(t);
                return <RenderAllTrainings training={t} key={t._id} />;
              })
            ) : (
              "No trainings available at the moment"
            )}

            <div style={{ textAlign: "right" }}>
              <RenderNextButton />
            </div>
            {agency.user && agency.user._id && (
              <div style={{ textAlign: "center" }}>
                <p
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => {
                    props.history.push(
                      `/${props.match.params.slug}/upcoming-shifts`,
                    );
                  }}
                >
                  I will do the training later
                </p>
                (You will lose all the progress if you click this.)
              </div>
            )}
          </CardBody>
        </Container>
      </Card>

      {alert}
    </Container>
  );
}
