import React from "react";
import ReactDOM from "react-dom";
//import registerServiceWorker from "./registerServiceWorker";
import App from "./App";

ReactDOM.render(
  <App
    style={{
      fontFamily: `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
    }}
  />,
  document.getElementById("root")
);

// For keeping a cache on a user's device
//registerServiceWorker();
