import React, { useState, useEffect, useContext } from "react";
import { Card, CardTitle, CardBody, CardHeader, Row, Col } from "reactstrap";
import moment from "moment";
import AgencyContext from "context/AgencyContext";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import api from "components/API/api";
import { Business, LocationOn } from "@material-ui/icons";
import { statusEnum } from "components/Utilities/utilities";

const Module = function ({ shift }) {
  console.log("shift", shift)
  return (
    shift && 
    <Card className="no-transition" style={{ backgroundColor: "#fcfcfc" }}>
      <CardHeader style={{ cursor: "pointer" }}>
        <div className="d-flex flex-column align-items-center">
          <CardTitle tag="h6">
            {shift.start && moment(shift.start).format("ddd MMM DD, YYYY")}
          </CardTitle>
          <CardTitle tag="h6">
            @{shift.start && moment(shift.start).format("hh:mm a")}
            {" - "}
            {shift.end && moment(shift.end).format("hh:mm a")}
          </CardTitle>
        </div>
      </CardHeader>
      <CardBody>
        <div className="d-flex flex-column">
          <div className="d-flex  m-1">
            <div style={{ flex: 1 }}>
              <Business />
            </div>
            <span style={{ flex: 3 }}>{shift.businessName}</span>
          </div>
          <div className="d-flex m-1">
            <div style={{ flex: 1 }}>
              <LocationOn />
            </div>
            <span style={{ flex: 3 }}>{shift.businessLocation}</span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default function UpComingShifts() {
  const [shifts, setShifts] = useState([]);
  const context = useContext(AgencyContext);
  const agency = context.agency.agency;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (agency) {
      loadLiveBusinesses(agency);
    }
  }, [agency]);

  const loadLiveBusinesses = function (agency) {
    setIsLoading(true);
    api()
      .get("/selected-for-work/user", {
        params: {
          from: new Date(),
          till: moment().add(30, "days").format(),
          agency: agency._id,
        },
      })
      .then((res) => {
        let history = []
          res.data.history &&
          res.data.history.map((data) => {
            console.log("worker shift data", data);
            // console.log("statusEnum",statusEnum)
            if (
              data.status === statusEnum.selectedForWork.selected ||
              data.status === statusEnum.selectedForWork.Backup
            ) {
              history.push({
                _id: data._id,
                start: data.shift && data.shift.start,
                end: data.shift && data.shift.end,
                actualStart: data.actualStartTime,
                actualEnd: data.actualEndTime,
                payRate: data.shift && data.shift.payRate,
                payBasis: data.shift && data.shift.payBasis,
                estimatedPayDate: data.shift && data.shift.estimatedPayDate,
                description: data.shift && data.shift.description,
                businessLocation: data.shift?.businessLocation,
                businessName: data.shift?.businessName,
              })
            }
          });
        let newHistory =
          history &&
          history.length > 0 && 
          history.sort((d1, d2) => {
            return moment(d1.start).isBefore(d2.start) ? -1 : 1;
          });
        setShifts(newHistory);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  return (
    <div className="content">
      <Row>
        <Col>
          <Card className="no-transition">
            <CardHeader>
              <div className="d-flex justify-content-between">
                <CardTitle tag="h4">My Upcoming Shifts</CardTitle>
              </div>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <LoadingOverlay />
              ) : (
                <Row>
                  {shifts.length > 0 ? (
                    shifts.map((shift, i) => (
                      <Col lg="3" md="6" sm="8">
                        <Module shift={shift} key={i} index={i} />
                      </Col>
                    ))
                  ) : (
                    <p>You do not have any scheduled upcoming shifts.</p>
                  )}
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
