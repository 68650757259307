import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Button,
  CardImg,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody,
  CardHeader,
  Collapse,
  Row,
  Col,
  FormGroup,
} from "reactstrap";
import { Modal } from "@material-ui/core";
import AgencyContext from "context/AgencyContext";
import { useHistory, useParams } from "react-router-dom";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import {
  Business,
  LocationOn,
  Visibility,
  Android,
  Apple,
  Clear,
  Schedule,
  ArrowBack,
  KeyboardBackspace,
  CalendarToday,
} from "@material-ui/icons";
import moment from "moment";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import api from "components/API/api";
import { AGENCY_INFO } from "AgencyInfo";




export default function ShiftDetails(props) {
  const shift = props?.location?.state;
  console.log(shift);
  let history = useHistory();
  const context = useContext(AgencyContext);
  const agency = context.agency.agency;
  const [date, setDate] = useState(
    context.agency.selectedLiveDate || new Date()
  );
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [openPickUpModal, setOpenPickUpModal] = useState(false);
  const [latitude, setLatitude] = useState(43.735207);
  const [longitude, setLongitude] = useState(-79.604908);

  // Opens modal to assign user to driver
  const handleOpenPickUpModal = () => {
    setOpenPickUpModal(true);
  };

  const handleClosePickUpModal = () => {
    setOpenPickUpModal(false);
  };

  useEffect(() => {
   if(shift._id) fetchShift();
  }, [shift])

  const fetchShift = async () => {
    setIsLoading(true);
    try {
      const {data} = await api().get(`/shift/${shift._id}`);
      const business = data.shift?.business;
      if (business?.latitude && business?.longitude) {
        setLatitude(business.latitude);
        setLongitude(business.longitude);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  ////////////////////////////////////////////////////
  //Pick up modal
  ////////////////////////////////////////////////////
  const PickUpModal = () => {
    return (
      <Modal
        open={openPickUpModal}
        onClose={handleClosePickUpModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup
          style={{ width: 450, backgroundColor: "#fff", borderRadius: 10 }}
        >
          <div style={{ padding: 15 }}>
            {/* <div style={{ justifyContent: "center", display: "flex" }}> */}
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Col md="10">
                <h5>Pick up shifts up to 30 days in advance, view your own work history, and more via the {AGENCY_INFO.name} Worker's App</h5>
              </Col>
              <Col>
                <Button
                  style={{ marginTop: "-5px" }}
                  onClick={() => handleClosePickUpModal()}
                  color="default"
                  size="md"
                  className="btn-link btn-icon"
                >
                  <Clear style={{ marginLeft: -1 }} />
                </Button>{" "}
              </Col>
            </Row>

            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                marginTop: 15,
              }}
            >
              <Button color="default" size="sm">
               <a href={AGENCY_INFO.IOS} style={{ color: "white"}}><Apple /> Download for Ios</a> 
              </Button>
              <Button color="success" size="sm">
                <a href={AGENCY_INFO.ANDROID} style={{ color: "white"}}><Android />
                Download for Android</a>
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };


  const MyMapComponent = compose(
    withProps({
      /**
       * Note: create and replace your own key in the Google console.
       * https://console.developers.google.com/apis/dashboard
       * The key "AIzaSyBkNaAGLEVq0YLQMi-PYEMabFeREadYe1Q" can be ONLY used in this sandbox (no forked).
       */
      googleMapURL:
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyAQb0IZzBXaOlY8YvwGxHh8A-PgbSVlpT4",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `400px` }} />,
      mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap
  )(props => (
    <GoogleMap defaultZoom={8} defaultCenter={{ lat: latitude, lng: longitude }}>
      {props.isMarkerShown && (
        <Marker position={{ lat: latitude, lng: longitude }} />
      )}
    </GoogleMap>
  ));

  return (
    <div className="content">
      <Row>
        <Col>
          <Card className="no-transition">
            <CardHeader>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Col md="1">
                  <Button
                    onClick={() => history.goBack()}
                    color="default"
                    size="md"
                    className="btn-link btn-icon"
                  >
                    <KeyboardBackspace />
                  </Button>{" "}
                </Col>
                <Col>
                  <CardTitle tag="h5">Shift Info</CardTitle>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div className="d-flex flex-column">
                <div className="d-flex m-1">
                  <div style={{ flex: 1 }}>
                    Date: <CalendarToday />
                  </div>
                  <span style={{ flex: 3 }}>
                    {moment(shift.start).calendar()}
                  </span>
                </div>
                <div className="d-flex m-1">
                  <div style={{ flex: 1 }}>
                    Time: <Schedule />
                  </div>
                  <span style={{ flex: 3 }}>
                    {moment(shift.start).format("hh:mm a")} -{" "}
                    {moment(shift.end).format("hh:mm a")}
                  </span>
                </div>
                <div className="d-flex  m-1">
                  <div style={{ flex: 1 }}>
                   Business:<Business />
                  </div>
                  <span style={{ flex: 3 }}>{shift?.businessName}</span>
                </div>
                <div className="d-flex m-1">
                  <div style={{ flex: 1 }}>
                   Location:<LocationOn />
                  </div>
                  <span style={{ flex: 3 }}>{shift?.businessLocation}</span>
                </div>
               
                {shift?.businessIntersection && (
                  <div className="d-flex m-1">
                    <div style={{ flex: 1 }}>
                      Intersection <LocationOn />
                    </div>
                    <span style={{ flex: 3 }}>
                      {shift?.businessIntersection}
                    </span>
                  </div>
                )}
               <MyMapComponent isMarkerShown />

                <div style={{ display: "flex", justifyContent: "center", marginTop: 5}}>
                  <Button
                    onClick={() => handleOpenPickUpModal()}
                    color="primary"
                    size="sm"
                  >
                    Pick Up Shift
                  </Button>
                </div>
                {PickUpModal()}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
