//Ask user to upload images if user hasn't uploaded all images or admin deleted images as inappropriate
import Axios from "axios";
import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import Select from "react-select";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Spinner,
  Container,
} from "reactstrap";
import SignatureCanvas from "react-signature-canvas";
import { useHistory, useLocation, useParams } from "react-router-dom";
import AgencyContext from "context/AgencyContext";
import TrainingContext from "context/TrainingContext";
import api from "components/API/api";

import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import { login } from "views/pages/Login";
import moment from "moment";
import MaskedInput from "react-text-mask";
import { validateSin } from "components/Utilities/utilities";

export default function DocumentUpload(props) {
  const { agency, agencyDispatch } = useContext(AgencyContext);
  const { training, trainingDispatch } = useContext(TrainingContext);
  const [requiredDocuments, setRequiredDocuments] = useState([]);
  const [workStatus, setWorkStatus] = useState({ value: "" });
  const [vaccineStatus, setVaccineStatus] = useState(null);
  const [errorWorkStatus, setErrorWorkStatus] = useState("");
  const [vaccineError, setVaccineError] = useState("");
  const history = useHistory();
  const { slug } = useParams();
  const isInitialMount = useRef(true);

  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let redirect = params.get("redirect");

  useEffect(() => {
    if (!agency.user) {
      history.push(`/${slug}/auth/login`);
    }
    if (agency.user && agency.user.workStatus !== undefined) {
      setWorkStatus({ value: agency.user.workStatus });
    }

    if (agency?.user?.vaccineStatus !== undefined) {
      setVaccineStatus({ value: agency.user.vaccineStatus });
    }
  }, []);

  useEffect(() => {
    if (!isInitialMount.current) {
      login(props.history, props.match.params.slug, training, agency, redirect);
    } else {
      isInitialMount.current = false;
    }
  }, [training, agency]);

  // const [images, setImage] =
  useEffect(() => {
    let requiredDocs = [
      { name: "1", error: "", image: null },
      { name: "3", error: "", image: null },
      { name: "signature", error: "", image: null },
    ];
    if (workStatus) {
      switch (workStatus.value) {
        case 0:
          requiredDocs = [...requiredDocs];
          break;
        case 1:
          requiredDocs = [
            { name: "5", error: "", image: null },
            ...requiredDocs,
          ];
          break;
        case 2:
          requiredDocs = [
            { name: "7", error: "", image: null },
            ...requiredDocs,
          ];
          break;
        default:
      }
      setRequiredDocuments(
        requiredDocs.filter(
          (docs) => !agency.images.some((img) => img.index === docs.name)
        )
      );
    }
  }, [workStatus, agency.images]);

  const [sinError, setSinError] = useState("");
  const [sin, setSin] = useState("");
  const [sinExpiry, setSinExpiry] = useState("");
  const [sinExpiryError, setSinExpiryError] = useState("");

  useEffect(() => {
    agency.user?.sin &&
      agency.user?.sin != "000000000" &&
      setSin(agency?.user.sin);
  }, [agency.user?.sin]);

  const [sinStartsWith9, isSinExpired, sinAboutToExpire] = useMemo(() => {
    const sinStartsWith9 = agency.user?.sin?.startsWith("9") || false;
    const isSinExpired = agency.user?.sinExpiry
      ? moment(agency.user.sinExpiry).isSameOrBefore(moment())
      : false;

    const sinAboutToExpire = agency.user?.sinExpiry
      ? moment(agency.user.sinExpiry).isSameOrBefore(moment().add(30, "days"))
      : false;

    if (sinStartsWith9 && (isSinExpired || sinAboutToExpire)) {
      if (!requiredDocuments.some((_doc) => _doc.name === "3")) {
        setRequiredDocuments((_requiredDocuments) => [
          ..._requiredDocuments,
          { name: "3", error: "", image: null },
        ]);
      }
    }
    return [sinStartsWith9, isSinExpired, sinAboutToExpire];
  }, [agency.user?.sin, requiredDocuments]);

  const uploadFile = (file, signedRequest) => {
    Axios.put(signedRequest, file)
      .then(() => {
        console.log("successfully uploaded the image");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const signAndUpLoadPromise = (userId, imageName, image) => {
    let signedPromise = api()
      .get("/image/sign-s3/put", {
        params: {
          "file-path": `userDocs/${userId}/${imageName}`,
          "file-type": image.type,
        },
      })
      .then(({ data }) => {
        console.log(data);
        uploadFile(image, data.signedUrl);
        return true;
      });
    return signedPromise;
  };

  let signaturePad = {};

  const _renderImages = (name, document) => {
    let saveDocument = (file, docName) => {
      setRequiredDocuments(
        requiredDocuments.map((doc) => {
          if (doc.name === docName) {
            doc.error = "";
            doc.image = file;
          }
          return doc;
        })
      );
    };
    return (
      <Row key={document.name}>
        <Label sm="2">{name}</Label>
        <Col sm="5">
          {document.name === "signature" ? (
            <>
              <Row
                style={{
                  maxHeight: "200px",
                  width: "95%",
                  margin: "auto",
                }}
              >
                <SignatureCanvas
                  style={{ height: "100%" }}
                  penColor="orange"
                  ref={(ref) => {
                    signaturePad = ref;
                  }}
                  canvasProps={{
                    className: "signatureCanvas",
                  }}
                  onEnd={() => {
                    let signature = signaturePad
                      .getTrimmedCanvas()
                      .toDataURL("image/png");
                    saveDocument(signature, document.name);
                  }}
                  id="signature"
                />
              </Row>
              <Row>
                <Col sm="10">
                  <Button
                    width="100%"
                    color="secondary"
                    onClick={() => {
                      signaturePad.clear();
                      saveDocument(null, document.name);
                    }}
                  >
                    Clear
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <ImageUpload
              addImageToState={(imageName, file) => {
                saveDocument(file, document.name);
              }}
              removeImageFromState={() => {
                saveDocument(null, document.name);
              }}
            />
          )}
          <FormText color="danger" tag="span">
            {document.error}
          </FormText>
        </Col>
      </Row>
    );
  };

  const handleSubmit = async () => {
    let error = false;
    if (!workStatus) {
      error = true;
      setErrorWorkStatus("Please select a work status");
    }
    let newRequiredDocuments = requiredDocuments.map((document) => {
      if (!document.image) {
        document.error = "You must upload an image.";
      }
      return document;
    });
    if (!newRequiredDocuments.every((doc) => doc.image)) {
      setRequiredDocuments(newRequiredDocuments);
      error = true;
    }

    if (!vaccineStatus || !(vaccineStatus.value || vaccineStatus.value === 0)) {
      console.log("vaccine error");
      setVaccineError("* Must add your vaccine status!");
      error = true;
    } else {
      setVaccineError("");
    }

    // if (!sin || validateSin(sin) === false) {
    //   setSinError("Must enter a valid sin.");
    //   error = true;
    // } else {
    //   setSinError("");
    // }

    if (sin?.startsWith("9")) {
      if (!sinExpiry) {
        setSinExpiryError("* Must have expiry date for sin starting with 9.");
        error = true;
      } else if (moment().isSameOrAfter(sinExpiry, "date")) {
        setSinExpiryError("* The sin is already expired.");
        error = true;
      } else {
        setSinExpiryError("");
      }
    } else {
      setSinExpiryError("");
    }

    if (!error) {
      setIsLoading(true);
      let promises = [];
      //first set to upload required documents
      for (let i = 0; i < requiredDocuments.length; i++) {
        let doc = requiredDocuments[i];
        let image = doc.image;
        if (doc.name === "signature") {
          const response = await fetch(image);
          image = await response.blob();
        }
        promises.push(
          signAndUpLoadPromise(agency.user._id, doc.name + ".jpeg", image)
        );
      }
      let updates = {};
      if (!agency.user.workStatus) {
        updates.workStatus = workStatus.value;
      }
      // if (sin) {
      //   updates.sin = sin;
      // }
      if (sinExpiry) {
        updates.sinExpiry = sinExpiry;
      }

      if (vaccineStatus.value || vaccineStatus.value === 0) {
        updates.vaccineStatus = vaccineStatus.value;
      }

      //now update the user missing documents
      if (Object.keys(updates).length > 0) {
        updates.userId = agency.user._id;
        promises.push(api().patch(`users/me`, updates));
      }
      try {
        await Promise.all(
          promises.map((promise) => {
            promise
              .then((response) => {
                // console.log(response);
                console.log("each promise running");
              })
              .catch((message) => {
                console.log(message);
              });
          })
        ).then(() => {
          agencyDispatch({
            type: "UPDATE_USER",
            payload: { updates },
          });

          agencyDispatch({
            type: "UPDATE_IMAGES",
            payload: {
              updates: requiredDocuments.map((d) => ({ index: d.name })),
            },
          });
        });
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
        history.push(`/${slug}/upcoming-shifts`);
      }
    }
  };
  return (
    <Container>
      <Card style={{ marginTop: 20 }}>
        <Container>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h4>Before you continue...</h4>
            </div>
            <p>
              Please allow some time to fill the following info before you
              contine.
            </p>
            <hr />
          </CardHeader>
          {/* {isLoading ? (
            <div className="justify-content-center d-flex">
              <Spinner />
            </div>
          ) : ( */}
          <CardBody>
            {agency.user &&
              !agency.user.workStatus &&
              agency.user.workStatus !== 0 && (
                <Row>
                  <Label md="2">* Work Status</Label>
                  <Col md="5">
                    <FormGroup>
                      <Select
                        id="workStatus"
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="singleSelect"
                        value={workStatus}
                        onChange={(value) => {
                          setWorkStatus(value);
                        }}
                        options={[
                          {
                            value: 0,
                            label: "Permanent Residence/Citizen",
                          },
                          { value: 1, label: "Work Permit" },
                          {
                            value: 2,
                            label: "Student Visa",
                          },
                        ]}
                        placeholder="Select a work status"
                      />

                      <FormText color="danger" tag="span">
                        {errorWorkStatus}
                      </FormText>
                    </FormGroup>
                  </Col>
                </Row>
              )}

            {agency.user &&
              !agency.user.vaccineStatus &&
              agency.user.vaccineStatus !== 0 && (
                <Row>
                  <Label md="2">* Vaccine Status</Label>
                  <Col md="5">
                    <FormGroup>
                      <Select
                        id="vaccineStatus"
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="singleSelect"
                        value={vaccineStatus}
                        onChange={(value) => {
                          setVaccineStatus(value);
                        }}
                        options={[
                          {
                            value: 0,
                            label: "No vaccine yet!",
                          },
                          { value: 1, label: "Received 1st dose" },
                          {
                            value: 2,
                            label: "Received 2nd dose/Fully vaccinated",
                          },
                        ]}
                        placeholder="Select your current vaccine status"
                      />

                      <FormText color="danger" tag="span">
                        {vaccineError}
                      </FormText>
                    </FormGroup>
                  </Col>
                </Row>
              )}

            <Row>
              {sinStartsWith9
                ? isSinExpired
                  ? "Your sin number has expired. Please update it with a new expiry date. Otherwise you can't be selected for upcoming work. If you have received a new number please contact the agency."
                  : sinAboutToExpire
                  ? "Your sin number is about to expire. Please update it with a new date. If you have received a new number please contact the agency."
                  : !agency?.user?.sinExpiry
                  ? "It seems your SIN number starts with 9 and We haven't received your sin number expiry date. Please take a moment to update your sin number expiry date."
                  : ""
                : ""}
            </Row>
            {sinStartsWith9 && (isSinExpired || sinAboutToExpire) ? (
              <Row>
                <Label sm="2">* Social Insurance Number</Label>
                <Col sm="5">
                  <FormGroup>
                    <MaskedInput
                      mask={[
                        /\d/,
                        /\d/,
                        /\d/,
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      id="sin"
                      disabled
                      className="form-control"
                      autoComplete="off"
                      maxLength="12"
                      placeholder="xxx xxx xxx"
                      // onChange={(e) => {
                      //   //Allows the user to type in any format. Only numbers will be added to the state
                      //   let input = e.target.value;
                      //   let replacedSin = input.replace(/[^0-9]/g, "");
                      //   setSin(replacedSin);
                      // }}
                      value={sin || ""}
                    />
                    <FormText color="danger" tag="span">
                      {sinError}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
            ) : null}
            {sinStartsWith9 ? (
              <Row>
                <Label sm="2">*Sin Expiry Date</Label>
                <Col sm="5">
                  <FormGroup>
                    <Input
                      type="date"
                      autoComplete="off"
                      maxLength="3"
                      onChange={(e) => {
                        setSinExpiry(e.target.value);
                      }}
                      value={sinExpiry}
                      id="sinExpiry"
                      max="2099-12-12"
                    />
                    <FormText color="danger" tag="span">
                      {sinExpiryError}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
            ) : null}

            {requiredDocuments.map((document) => {
              switch (document.name) {
                case "1":
                  return _renderImages(
                    "* Government Issued Photo ID (passport or driver's license)",
                    document
                  );
                case "3":
                  return _renderImages("* Proof of Sin", document);
                case "5":
                  return _renderImages("* Work Permit", document);
                case "7":
                  return _renderImages("* Student Visa", document);
                case "signature":
                  return _renderImages("* Signature", document);
                default:
                  return null;
              }
            })}

            <Row>
              <Col
                sm="10"
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <Button
                  color="success"
                  size="lg"
                  onClick={() => handleSubmit()}
                  disabled={isLoading}
                >
                  {isLoading ? <Spinner /> : "Continue"}
                </Button>
                <Button
                  color="secondary"
                  size="lg"
                  onClick={() =>
                    redirect
                      ? history.push(redirect)
                      : history.push(`/${slug}/upcoming-shifts`)
                  }
                >
                  I will do it later
                </Button>
              </Col>
              <Col sm="2"></Col>
            </Row>
          </CardBody>
          {/* )} */}
        </Container>
      </Card>
    </Container>
  );
}
