import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import MaskedInput from "react-text-mask";
import moment from "moment";
import ReactDatetime from "react-datetime";
import api from "components/API/api";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Spinner,
  Container,
} from "reactstrap";
import {
  Android,
  Apple,
} from "@material-ui/icons";
import AgencyContext from "context/AgencyContext";
import { AGENCY_INFO } from "AgencyInfo";
// import { fs, auth, firebase } from "components/Firebase/firebase.js";

//sendsms function
// const fn_sendSmsManual = firebase.functions().httpsCallable("sendSmsManual");

class ChequePickup extends Component {
  static contextType = AgencyContext;
  constructor(props) {
    super(props);
    this.defaultState = {
      firstName: null,
      lastName: null,
      weekStart: null,
      weekEnd: null,
      business: null,
      phone: null,
      pinBadgeNumber: null,

      errorFirstName: "",
      errorLastName: "",
      errorWeekStart: "",
      errorWeekEnd: "",
      errorLocation: "",
      errorPhone: "",
      errorPinBadgeNumber: "",

      thours: 0,
      tminutes: 0,
      tseconds: 0,

      // Loading
      isLoading: false,

      isPickupTime: false,
      businessList: [],
      agencyData: false,
    };
    this.state = { ...this.defaultState };

    this.fromNumber = "6479057870";
  }

  componentDidMount() {
    // this.getAllBusiness();
    api()
      .get("/server-timestamp")
      .then(({ data }) => {
        console.log(data);
        this.isServiceAvailable(new Date(data.utc_timestamp));
      })
      .catch((e) => {
        console.log(e);
      });
  }

  componentDidUpdate() {
    if (
      this.context.agency &&
      this.context.agency.agency &&
      this.context.agency.agency._id &&
      !this.state.agencyData
    ) {
      console.log(this.context);
      this.setState({ agencyData: true });
      this.getAllBusiness();
    }
  }

  getAllBusiness = async () => {
    try {
      console.log(this.props);
      // this.setState({ loading: true });
      console.log(this.context);
      const { data } = await api().get(`business/list`, {
        params: {
          agency:
            this.context.agency &&
            this.context.agency.agency &&
            this.context.agency.agency._id,
        },
      });
      //Looping through the business data for action button implementation
      console.log(data);
      this.setState({
        businessList: data.map((business) => {
          return { value: business._id, label: business.name };
        }),
      });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  };

  //time counter until pickup
  myInterval = setInterval(() => {
    const { thours, tminutes, tseconds } = this.state;

    const day = new Date().getDay();
    let countDownDate;
    if (day >= 1 && day <= 6) {
      countDownDate = new Date("December 31, 2022 09:00:00").getTime();
    } else {
      const cdt = new Date("December 31, 2022 09:00:00");
      const CDT = cdt.setHours(cdt.getHours() + 24);
      countDownDate = CDT;
      console.log("sunday");
    }

    const now = new Date().getTime();
    const distance = countDownDate - now;
    //console.log(distance);

    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 48)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (distance < 0) {
      this.setState({ isPickupTime: true });
    } else {
      this.setState(({ thours, tminutes, tseconds }) => ({
        thours: hours,
        tminutes: minutes,
        tseconds: seconds,
      }));
    }
  }, 1000);

  //serverDate: a type of new Date() from vanilla JS.
  // If betweenn Monday - Saturday and 10am-4pm, we will enable it.
  isServiceAvailable = (serverDate) => {
    console.log(serverDate);
    let day = serverDate.getDay(); //sun = 0, sat = 6
    let hour = serverDate.getHours(); // 0 - 23

    if (day >= 1 && day <= 6) {
      if (hour >= 10 && hour <= 15) {
        // open until 3:59:59 pm.
        this.setState({ isPickupTime: true });
      } else {
        this.setState({ isPickupTime: false });
      }
    } else {
      this.setState({ isPickupTime: false });
    }
  };

  scrollToFirstError = () => {
    //Convert to camelcase by adding prototype to String
    String.prototype.toLowerCaseFirstLetter = function () {
      return this.charAt(0).toLowerCase() + this.slice(1);
    };
    try {
      for (let prop in this.state) {
        if (prop.match(/^error+/) && this.state[prop]) {
          //console.log(prop, this.state[prop]);
          const id = prop.replace(/^error+/, "").toLowerCaseFirstLetter();
          var element = document.getElementById(id);
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          //By default scrollintoview takes some time to scroll smoothly so I set a settimeout function to
          //delay the focus so that it can normally scroll
          setTimeout(() => {
            element.focus();
          }, 500);
          break;
        }
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  handleSubmit = () => {
    let tempState = this.state; // Copy of state
    let hasError = false;
    // Start of Error Handling
    // if (tempState.firstName === null || tempState.firstName === "") {
    //   this.setState({ errorFirstName: "* Must have a first name." });
    //   hasError = true;
    // } else {
    //   this.setState({ errorFirstName: "" });
    // }

    // if (tempState.lastName === null || tempState.lastName === "") {
    //   this.setState({ errorLastName: "* Must have a last name." });
    //   hasError = true;
    // } else {
    //   this.setState({ errorLastName: "" });
    // }

    if (tempState.business === null || tempState.business === "") {
      this.setState({ errorLocation: "* Must have a location." });
      hasError = true;
    } else {
      this.setState({ errorLocation: "" });
    }

    if (tempState.pinBadgeNumber == null || tempState.pinBadgeNumber == "") {
      this.setState({
        errorPinBadgeNumber: "* Must have a pin or badge number.",
      });
      //Workers have a pin/badge number per workplace. Since this request is for ONE workplace, we need either pin or badge.
      hasError = true;
    } else {
      this.setState({ errorPinBadgeNumber: "" });
    }

    if (!tempState.weekStart) {
      this.setState({
        errorWeekStart: "* Must have a start date for the week",
      });
      hasError = true;
    } else {
      this.setState({ errorWeekStart: "" });
    }

    if (!tempState.weekEnd) {
      this.setState({ errorWeekEnd: "* Must have a end date for the week" });
      hasError = true;
    } else {
      this.setState({ errorWeekEnd: "" });
    }

    // if (tempState.phone == null || tempState.phone == "") {
    //   this.setState({ errorPhone: "* Must have a phone number." }); // No phone number
    //   hasError = true;
    // } else {
    //   if (tempState.phone.length != 10 || tempState.phone < 0) {
    //     this.setState({
    //       errorPhone:
    //         "* Please input a valid phone number in the format ###-###-####.",
    //     }); //invalid phone number
    //     hasError = true;
    //   } else {
    //     this.setState({ errorPhone: "" });
    //   }
    // }
    if (hasError) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            title="Please ensure all mandatory fields (*) are filled"
            onConfirm={this.hideAlert}
          />
        ),
      });
      return;
    } else {
      this.prepareAndSendDataToServer(tempState); // runs function after loading is set as true.
    }
  };

  //Convert every obj element to lowercase if it has string
  toLowerCase(obj) {
    let newObj = {};
    for (let prop in obj) {
      //console.log(prop, obj[prop]);
      if (typeof obj[prop] === "string") {
        newObj[prop] = obj[prop].toLowerCase();
      } else {
        newObj[prop] = obj[prop];
      }
    }
    return newObj;
  }

  checkIfRegisteredUser = async (phone) => {
    let isRegisteredUser = false;
    await this.userDataRef
      .where("phone", "==", phone)
      .get()
      .then((querySnapshot) => {
        console.log(querySnapshot.docs.length);
        if (querySnapshot.docs.length > 0) {
          isRegisteredUser = true;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return isRegisteredUser;
  };

  prepareAndSendDataToServer = async (tempState) => {
    if (this.state.isLoading === true) {
      return;
    }
    this.setState({ isLoading: true });
    api()
      .post("/cheque-pickup/v2", {
        // firstName: tempState.firstName,
        // lastName: tempState.lastName,
        weekStart: new Date(tempState.weekStart._d),
        weekEnd: new Date(tempState.weekEnd._d),
        business: tempState.business?.value,
        businessName: tempState.business?.label,
        pin: tempState.pinBadgeNumber,
        // phone: tempState.phone,
        agency:
          this.context.agency &&
          this.context.agency.agency &&
          this.context.agency.agency._id,
      })
      .then(({ data }) => {
        this.setState({
          // ...this.defaultState,
          isLoading: false,
          alert: (
            <SweetAlert
              success
              title="Your pickup request has been sent. You will receive a confirmation sms shortly."
              onConfirm={this.hideAlert}
            >
              Your cheque request number is: {data.chequePickUpNumber}
            </SweetAlert>
          ),
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          isLoading: false,
          alert: (
            <SweetAlert
              warning
              title="Error creating request!"
              onConfirm={this.hideAlert}
            >
              {e.response.data.message}
            </SweetAlert>
          ),
        });
      });
  };

  //actually send sms to firestore function.

  sendSms(message, fromNumber, number) {
    let sendingNumbers = [];
    sendingNumbers.push(number);

    if (sendingNumbers.length > 0) {
      console.log("message sending proceeded");
      // fn_sendSmsManual({
      //   message,
      //   fromNumber,
      //   numbers: sendingNumbers,
      // })
      //   .then((result) => {
      //     console.log(result.data);
      //     //console.log(result.data);
      //   })
      //   .catch((e) => {
      //     //console.log(e);
      //     console.log("Error sending SMS ", e);
      //   });
    } else {
      console.log("Something wrong with sending number");
    }
  }

  disableFutureDates = (current) => {
    const today = new Date();
    return current.isBefore(today);
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
    this.scrollToFirstError();
  };

  loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={
          <Spinner color="light" style={{ width: "3rem", height: "3rem" }} />
        }
        onConfirm={this.hideAlert}
        showConfirm={false}
      />
    );
  };

  render() {
    return (
      <div className="content" style={{ padding: 0 }}>
        {this.state.isLoading ? (
          this.loadingOverlay()
        ) : (
          <>
            {this.state.isPickupTime ? (
              //  ? (
              <Row style={{ margin: 0, padding: 0 }}>
                {/* <Col lg="3"></Col> */}
                <Col>
                  <Card style={{ marginTop: 20 }} className="no-transition">
                    <Container>
                      <CardHeader
                        style={{ alignItems: "center", textAlign: "center" }}
                      >
                        <div
                          style={{
                            borderColor: "#D8CBC7",
                            borderStyle: "solid",
                            borderWidth: "0.3px",
                            width: "100%",
                            height: 0,
                            marginTop: "35px",
                            marginBottom: "15px",
                          }}
                        />
                        <CardTitle tag="h4" style={{ fontWeight: "bold" }}>
                          Request Cheque Pickup
                        </CardTitle>
                        <p>
                          Please fill out the following form to request a cheque
                          pickup. Once you have completed this form, you will
                          receive a cheque pickup number.
                        </p>
                        <div
                          style={{
                            borderColor: "#D8CBC7",
                            borderStyle: "solid",
                            borderWidth: "0.3px",
                            width: "100%",
                            height: 0,
                            marginTop: "35px",
                            marginBottom: "25px",
                          }}
                        />
                      </CardHeader>
                      <CardBody>
                        <Form
                          action="/"
                          className="form-horizontal"
                          method="get"
                        >
                          {/* <Row>
                            <Label sm="2">* First Name</Label>
                            <Col sm="5">
                              <FormGroup>
                                <Input
                                  type="text"
                                  autoComplete="off"
                                  onChange={(e) =>
                                    this.setState({ firstName: e.target.value })
                                  }
                                  value={this.state.firstName || ""}
                                  id="firstName"
                                />
                                <FormText color="danger" tag="span">
                                  {this.state.errorFirstName}
                                </FormText>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Label sm="2">* Last Name</Label>
                            <Col sm="5">
                              <FormGroup>
                                <Input
                                  type="text"
                                  autoComplete="off"
                                  onChange={(e) =>
                                    this.setState({ lastName: e.target.value })
                                  }
                                  value={this.state.lastName || ""}
                                  id="lastName"
                                />
                                <FormText color="danger" tag="span">
                                  {this.state.errorLastName}
                                </FormText>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Label sm="2">* Phone Number</Label>
                            <Col sm="5">
                              <FormGroup>
                                <MaskedInput
                                  mask={[
                                    "(",
                                    /[1-9]/,
                                    /\d/,
                                    /\d/,
                                    ")",
                                    "-",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    "-",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                  ]}
                                  className="form-control"
                                  autoComplete="off"
                                  maxLength="15"
                                  placeholder="(xxx)-xxx-xxxx"
                                  value={this.state.phone}
                                  onChange={(e) => {
                                    //Allows the user to type in any format. Only numbers will be added to the state
                                    let input = e.target.value;
                                    let replacedPhone = input.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    this.setState({ phone: replacedPhone });
                                  }}
                                />
                                <FormText color="danger" tag="span">
                                  {this.state.errorPhone}
                                </FormText>
                              </FormGroup>
                            </Col>
                          </Row> */}

                          <Row>
                            <Label sm="2">* Location</Label>
                            <Col sm="5">
                              <FormGroup>
                                <Select
                                  className="react-select primary"
                                  classNamePrefix="react-select"
                                  name="singleSelect"
                                  value={this.state.business}
                                  onChange={(value) =>
                                    this.setState({ business: value })
                                  }
                                  id="dayOfWeek"
                                  options={this.state.businessList}
                                  placeholder="Select a location"
                                />
                                <FormText color="danger" tag="span">
                                  {this.state.errorLocation}
                                </FormText>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Label sm="2">* Pin OR Badge Number</Label>
                            <Col sm="5">
                              <FormGroup>
                                <Input
                                  type="text"
                                  autoComplete="off"
                                  placeholder="Must have pin OR badge #"
                                  onChange={(e) =>
                                    this.setState({
                                      pinBadgeNumber: e.target.value,
                                    })
                                  }
                                  value={this.state.pinBadgeNumber || ""}
                                  id="pinBadgeNumber"
                                />
                                <FormText color="danger" tag="span">
                                  {this.state.errorPinBadgeNumber}
                                </FormText>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Label sm="2">* Start of Week</Label>
                            <Col sm="5">
                              <FormGroup>
                                <ReactDatetime
                                  input={false}
                                  timeFormat={false}
                                  isValidDate={this.disableFutureDates}
                                  value={this.state.weekStart}
                                  onChange={(value) => {
                                    this.setState({
                                      weekStart: value,
                                    });
                                  }}
                                />
                                <FormText color="danger" tag="span">
                                  {this.state.errorWeekStart}
                                </FormText>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Label sm="2">* End of Week</Label>
                            <Col sm="5">
                              <FormGroup>
                                <ReactDatetime
                                  input={false}
                                  timeFormat={false}
                                  isValidDate={this.disableFutureDates}
                                  value={this.state.weekEnd}
                                  onChange={(value) => {
                                    this.setState({
                                      weekEnd: value,
                                    });
                                  }}
                                />
                                <FormText color="danger" tag="span">
                                  {this.state.errorWeekEnd}
                                </FormText>
                              </FormGroup>
                            </Col>
                          </Row>

                          <div
                            style={{
                              width: "100%",
                              height: 0,
                              marginTop: "35px",
                              marginBottom: "35px",
                            }}
                          />
                          <Row>
                            <Col sm="10" align="right">
                              <Button
                                color="success"
                                size="lg"
                                onClick={() => this.handleSubmit()}
                              >
                                Send Request
                              </Button>
                            </Col>
                            <Col sm="2"></Col>
                          </Row>
                          <div
                            style={{
                              width: "100%",
                              height: 0,
                              marginTop: "35px",
                              marginBottom: "25px",
                            }}
                          />
                        </Form>
                      </CardBody>
                    </Container>
                  </Card>
                </Col>
                {/* <Col lg="3"></Col> */}
              </Row>
            ) : (
              <div>
                <Card
                  style={{
                    width: "22rem",
                    backgroundColor: "#838a71",
                    color: "white",
                    fontWeight: "600",
                    marginTop: "20%",
                    marginLeft: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  className="text-center"
                >
                  <CardBody>
                    <p style={{ fontWeight: "800", fontSize: "18px" }}>
                      Cheque Pickup Times:
                    </p>
                    <p>Monday - Saturday : 10am-4pm</p>
                  </CardBody>
                </Card>

                <p className="text-center">
                  <strong>Time Left Until Pickup:</strong>{" "}
                  {this.state.thours < 10
                    ? `0${this.state.thours}`
                    : `${this.state.thours}`}{" "}
                  :
                  {this.state.tminutes < 10
                    ? `0${this.state.tminutes}`
                    : this.state.tminutes}{" "}
                  :
                  {this.state.tseconds < 10
                    ? `0${this.state.tseconds}`
                    : this.state.tseconds}
                </p>
                <div style={{display: "flex", justifyContent: "center", margin: "3%",  marginBottom: "1%"}}>
                Download the {AGENCY_INFO.name} Worker's app to schedule your cheque appointments and pick up shifts up to 30 days in advance.
                </div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", margin: "1%"}}>
                 Please clicks on links below <a href={AGENCY_INFO.ANDROID} style={{ color: "blue", margin: 3}}><Android />
                Download for Android</a> and   <a href={AGENCY_INFO.IOS} style={{ color: "blue", margin: 3}}><Apple /> Download for Ios</a> 
               
                </div>
              </div>
            )}
          </>
        )}
        {this.state.alert}
      </div>
    );
  }
}

export default ChequePickup;
