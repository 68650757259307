/* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import MaskedInput from "react-text-mask";
//import ReactDatetime from "react-datetime";
import { validateEmail } from "components/Utilities/utilities.js";
import AgencyContext from "context/AgencyContext";
import api from "components/API/api";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Spinner,
  Container,
} from "reactstrap";

// import { fs, auth, firebase } from "components/Firebase/firebase.js";

class ChequeIssues extends Component {
  static contextType = AgencyContext;
  constructor(props) {
    super(props);
    this.defaultState = {
      firstName: null,
      lastName: null,
      //date: null,
      //dateEnd: null,
      business: null,
      supervisor: null,
      dayOfWeek: null,
      shift: null,
      lineNumber: null,
      pinBadgeNumber: null,
      comments: null,
      phone: null,
      email: null,

      errorFirstName: "",
      errorLastName: "",
      //errorDate: "",
      //errorDateEnd: "",
      errorLocation: "",
      errorDayOfWeek: "",
      errorShift: "",
      errorComments: "",
      errorPhone: "",
      errorEmail: "",

      // Loading
      isLoading: false,

      businessList: [],
      agencyData: false,
    };
    this.state = this.defaultState;
  }

  componentDidMount() {
    // this.getAllBusiness();
    // this.chequeIssuesDataRef = fs.collection("cheque_issues");
    // this.chequeIssuesCountersRef = fs.collection("counters").doc("cheque_issues");
  }

  componentDidUpdate() {
    if (
      this.context.agency &&
      this.context.agency.agency &&
      this.context.agency.agency._id &&
      !this.state.agencyData
    ) {
      console.log(this.context);
      this.setState({ agencyData: true });
      this.getAllBusiness();
    }
  }

  getAllBusiness = async () => {
    try {
      console.log(this.props);
      // this.setState({ loading: true });
      const { data } = await api().get(`business/list`, {
        params: {
          agency:
            this.context.agency &&
            this.context.agency.agency &&
            this.context.agency.agency._id,
        },
      });
      //Looping through the business data for action button implementation
      console.log(data);
      this.setState({
        businessList: data.map((business) => {
          return { value: business._id, label: business.name };
        }),
      });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  };

  scrollToFirstError = () => {
    //Convert to camelcase by adding prototype to String
    String.prototype.toLowerCaseFirstLetter = function () {
      return this.charAt(0).toLowerCase() + this.slice(1);
    };
    try {
      for (let prop in this.state) {
        if (prop.match(/^error+/) && this.state[prop]) {
          //console.log(prop, this.state[prop]);
          const id = prop.replace(/^error+/, "").toLowerCaseFirstLetter();
          var element = document.getElementById(id);
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          //By default scrollintoview takes some time to scroll smoothly so I set a settimeout function to
          //delay the focus so that it can normally scroll
          setTimeout(() => {
            element.focus();
          }, 500);
          break;
        }
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  handleSubmit = () => {
    let tempState = this.state; // Copy of state
    let hasError = false;
    // Start of Error Handling
    // if (tempState.firstName === null || tempState.firstName === "") {
    //   this.setState({ errorFirstName: "* Must have a first name." });
    //   hasError = true;
    // } else {
    //   this.setState({ errorFirstName: "" });
    // }

    // if (tempState.lastName === null || tempState.lastName === "") {
    //   this.setState({ errorLastName: "* Must have a last name." });
    //   hasError = true;
    // } else {
    //   this.setState({ errorLastName: "" });
    // }

    if (tempState.shift === null || tempState.shift === "") {
      this.setState({ errorShift: "* Must have a shift." });
      hasError = true;
    } else {
      this.setState({ errorShift: "" });
    }

    if (tempState.business === null || tempState.business === "") {
      this.setState({ errorLocation: "* Must have a business." });
      hasError = true;
    } else {
      this.setState({ errorLocation: "" });
    }

    // if (tempState.phone == null || tempState.phone == "") {
    //   this.setState({ errorPhone: "* Must have a phone number." }); // No phone number
    //   hasError = true;
    // } else {
    //   if (tempState.phone.length != 10 || tempState.phone < 0) {
    //     this.setState({ errorPhone: "* Please input a valid phone number." }); //invalid phone number
    //     hasError = true;
    //   } else {
    //     this.setState({ errorPhone: "" });
    //   }
    // }

    // if (tempState.email == null || tempState.email == "") {
    //   this.setState({ errorEmail: "* Must have an email" }); // No email text
    // } else {
    //   if (tempState.email != null && validateEmail(tempState.email) === false) {
    //     this.setState({ errorEmail: "* Please input a valid email" }); //invalid email text
    //     hasError = true;
    //   } else {
    //     this.setState({ errorEmail: "" });
    //   }
    // }

    if (tempState.comments === null || tempState.comments === "") {
      this.setState({ errorComments: "* Please tell us about your problem." });
      hasError = true;
    } else {
      this.setState({ errorComments: "" });
    }

    if (hasError) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            title="Please ensure all mandatory fields (*) are filled"
            onConfirm={this.hideAlert}
          />
        ),
      });
      return;
    } else {
      this.prepareAndSendDataToServer(tempState); // runs function after loading is set as true.
    }
  };

  //Convert every obj element to lowercase if it has string
  toLowerCase(obj) {
    let newObj = {};
    for (let prop in obj) {
      //console.log(prop, obj[prop]);
      if (typeof obj[prop] === "string") {
        newObj[prop] = obj[prop].toLowerCase();
      } else {
        newObj[prop] = obj[prop];
      }
    }
    return newObj;
  }

  prepareAndSendDataToServer = async (tempState) => {
    if (this.state.isLoading === true) {
      return;
    }
    this.setState({ isLoading: true });
    api()
      .post("/cheque-issues/v2", {
        firstName: tempState.firstName,
        lastName: tempState.lastName,
        business: tempState.business && tempState.business.value,
        supervisor: tempState.supervisor,
        shift: tempState.shift.label,
        lineNumber: tempState.lineNumber,
        pin: tempState.pinBadgeNumber,
        comment: tempState.comments,
        phone: tempState.phone,
        email: tempState.email,
        agency:
          this.context.agency &&
          this.context.agency.agency &&
          this.context.agency.agency._id,
      })
      .then(({ data }) => {
        this.setState({
          ...this.defaultState,
          isLoading: false,
          alert: (
            <SweetAlert
              success
              title="Your ticket has been sent"
              onConfirm={this.hideAlert}
            >
              Thank you for the report! Someone will be contacting you shortly
              for more information. Please ensure that you are prepared to
              answer the phone during business hours.
            </SweetAlert>
          ),
        });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
          alert: (
            <SweetAlert
              warning
              title="An unexpected error has occurred"
              onConfirm={this.hideAlert}
            >
              {e.response?.data.message || e.message}
            </SweetAlert>
          ),
        });
      });
    // await auth.signInAnonymously()
    // console.log("anon logged in!");
    // firebase.auth().onAuthStateChanged(async (user) => {
    //   if (user) {

    //     this.chequeIssuesCountersRef.get().then((doc) => {
    //       let chequeTicket = doc.data().total
    //       this.chequeIssuesDataRef.add({
    //         firstName: tempState.firstName,
    //         lastName: tempState.lastName,
    //         business: tempState.business.label,
    //         supervisor: tempState.supervisor,
    //         shift: tempState.shift.label,
    //         lineNumber: tempState.lineNumber,
    //         pinBadgeNumber: tempState.pinBadgeNumber,
    //         comments: tempState.comments,
    //         chequeTicket: chequeTicket,
    //         phone: tempState.phone,
    //         email: tempState.email,
    //         pickedUp: false,
    //         createdDate: new Date()
    //       })
    //         .then(() => {
    //           this.chequeIssuesCountersRef.update({ total: firebase.firestore.FieldValue.increment(1) })
    //           console.log("data sent")
    //           this.setState({
    //             ...this.defaultState,
    //             isLoading: false,
    //             alert: (
    //               <SweetAlert
    //                 success
    //                 title="Your ticket has been sent"
    //                 onConfirm={this.hideAlert}
    //               >
    //                 Thank you for the report! Someone will be contacting you shortly for more information.
    //                 Please ensure that you are prepared to answer the phone during business hours.
    //               </SweetAlert>
    //             ),
    //           });
    //         })
    //     })
    //   } else {
    //     this.setState({
    //       isLoading: false,
    //       alert: (
    //         <SweetAlert
    //           warning
    //           title="An unexpected error has occurred"
    //           onConfirm={this.hideAlert}
    //         >
    //           Please check your internet connection and try again.
    //         </SweetAlert>
    //       ),
    //     });
    //   }
    // })
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
    this.scrollToFirstError();
  };

  loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={
          <Spinner color="light" style={{ width: "3rem", height: "3rem" }} />
        }
        onConfirm={this.hideAlert}
        showConfirm={false}
      />
    );
  };

  disableFutureDates = (current) => {
    const today = new Date();
    return current.isBefore(today);
  };

  render() {
    return (
      <div className="content" style={{ padding: 0 }}>
        {this.state.isLoading ? (
          this.loadingOverlay()
        ) : (
          <Row style={{ margin: 0, padding: 0 }}>
            <Col>
              <Card style={{ marginTop: 20 }} className="no-transition">
                <Container>
                  <CardHeader
                    style={{ alignItems: "center", textAlign: "center" }}
                  >
                    <div
                      style={{
                        borderColor: "#D8CBC7",
                        borderStyle: "solid",
                        borderWidth: "0.3px",
                        width: "100%",
                        height: 0,
                        marginTop: "35px",
                        marginBottom: "15px",
                      }}
                    />
                    <CardTitle tag="h4" style={{ fontWeight: "bold" }}>
                      Report Cheque Issue
                    </CardTitle>
                    <p>
                      Please fill out the following form to report an issue with
                      your cheque. After your ticket has been submitted, please
                      ensure that you are prepared to answer the phone during
                      business hours.
                    </p>
                    <div
                      style={{
                        borderColor: "#D8CBC7",
                        borderStyle: "solid",
                        borderWidth: "0.3px",
                        width: "100%",
                        height: 0,
                        marginTop: "35px",
                        marginBottom: "25px",
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <Form action="/" className="form-horizontal" method="get">
                      {/* <Row>
                        <Label sm="2">* First Name</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="text"
                              autoComplete="off"
                              onChange={(e) =>
                                this.setState({ firstName: e.target.value })
                              }
                              value={this.state.firstName || ""}
                              id="firstName"
                            />
                            <FormText color="danger" tag="span">
                              {this.state.errorFirstName}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row> */}

                      {/* <Row>
                        <Label sm="2">* Last Name</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="text"
                              autoComplete="off"
                              onChange={(e) =>
                                this.setState({ lastName: e.target.value })
                              }
                              value={this.state.lastName || ""}
                              id="lastName"
                            />
                            <FormText color="danger" tag="span">
                              {this.state.errorLastName}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row> */}

                      {/* <Row>
                        <Label sm="2">* Phone Number</Label>
                        <Col sm="5">
                          <FormGroup>
                            <MaskedInput
                              mask={[
                                "(",
                                /[1-9]/,
                                /\d/,
                                /\d/,
                                ")",
                                "-",
                                /\d/,
                                /\d/,
                                /\d/,
                                "-",
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                              ]}
                              className="form-control"
                              autoComplete="off"
                              maxLength="15"
                              placeholder="(xxx)-xxx-xxxx"
                              value={this.state.phone}
                              onChange={(e) => {
                                //Allows the user to type in any format. Only numbers will be added to the state
                                let input = e.target.value;
                                let replacedPhone = input.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                this.setState({ phone: replacedPhone });
                              }}
                              id="phone"
                            />
                            <FormText color="danger" tag="span">
                              {this.state.errorPhone}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Label sm="2">* Email</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="email"
                              autoComplete="off"
                              placeholder="Enter your email"
                              onChange={(e) =>
                                this.setState({ email: e.target.value })
                              }
                              value={this.state.email || ""}
                              id="email"
                            />
                            <FormText color="danger" tag="span">
                              {this.state.errorEmail}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row> */}

                      {/*
                      <Row>
                        <Label sm="2">* Start Date of Of Problem</Label>
                        <Col sm="5">
                          <FormGroup>
                            <ReactDatetime
                              input={false}
                              timeFormat={false}
                              isValidDate={this.disableFutureDates}
                              value={this.state.date}
                              id="date"
                              onChange={(value) => {
                                this.setState({
                                  date: value,
                                  dayOfWeek: value._d.getDay()
                                })
                              }}
                            />
                            <FormText color="danger" tag="span">
                              {this.state.errorDate}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Label sm="2">* End Date of Of Problem</Label>
                        <Col sm="5">
                          <FormGroup>
                            <ReactDatetime
                              input={false}
                              timeFormat={false}
                              isValidDate={this.disableFutureDates}
                              value={this.state.dateEnd}
                              id="dateEnd"
                              onChange={(value) => {
                                this.setState({
                                  dateEnd: value,
                                  dayOfWeekEnd: value._d.getDay()
                                })
                              }}
                            />
                            <FormText color="danger" tag="span">
                              {this.state.errorDateEnd}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                      */}
                      <Row>
                        <Label sm="2">* Shift</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="singleSelect"
                              value={this.state.shift}
                              onChange={(value) =>
                                this.setState({ shift: value })
                              }
                              id="shift"
                              options={[
                                { value: "m", label: "Morning" },
                                { value: "a", label: "Afternoon" },
                                { value: "n", label: "Night" },
                              ]}
                              placeholder="Select a shift"
                            />
                            <FormText color="danger" tag="span">
                              {this.state.errorShift}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Label sm="2">* Location</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="singleSelect"
                              value={this.state.business}
                              onChange={(value) =>
                                this.setState({ business: value })
                              }
                              id="business"
                              options={this.state.businessList}
                              placeholder="Select a location"
                            />
                            <FormText color="danger" tag="span">
                              {this.state.errorLocation}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Label sm="2">Supervisor Name (Optional)</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="text"
                              autoComplete="off"
                              onChange={(e) =>
                                this.setState({ supervisor: e.target.value })
                              }
                              value={this.state.supervisor || ""}
                              id="supervisor"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Label sm="2">Line Number (Optional)</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="text"
                              autoComplete="off"
                              onChange={(e) =>
                                this.setState({ lineNumber: e.target.value })
                              }
                              value={this.state.lineNumber || ""}
                              id="lineNumber"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Label sm="2">Pin or Badge Number (Optional)</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="text"
                              autoComplete="off"
                              onChange={(e) =>
                                this.setState({
                                  pinBadgeNumber: e.target.value,
                                })
                              }
                              value={this.state.pinBadgeNumber || ""}
                              id="pinBadgeNumber"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Label sm="2">* Comments</Label>
                        <Col sm="5">
                          <FormGroup>
                            <textarea
                              name="comments"
                              style={{
                                width: "100%",
                              }}
                              rows="5"
                              autoComplete="off"
                              placeholder="Please give us as many details as possible (dates, hours worked, etc)"
                              onChange={(e) =>
                                this.setState({ comments: e.target.value })
                              }
                              value={this.state.comments || ""}
                              id="comments"
                            ></textarea>
                            <FormText color="danger" tag="span">
                              {this.state.errorComments}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>

                      <div
                        style={{
                          width: "100%",
                          height: 0,
                          marginTop: "35px",
                          marginBottom: "35px",
                        }}
                      />
                      <Row>
                        <Col sm="10" align="right">
                          <Button
                            color="success"
                            size="lg"
                            onClick={() => this.handleSubmit()}
                          >
                            Report Issue
                          </Button>
                        </Col>
                        <Col sm="2"></Col>
                      </Row>
                      <div
                        style={{
                          width: "100%",
                          height: 0,
                          marginTop: "35px",
                          marginBottom: "25px",
                        }}
                      />
                    </Form>
                  </CardBody>
                </Container>
              </Card>
            </Col>
          </Row>
        )}
        {this.state.alert}
      </div>
    );
  }
}

export default ChequeIssues;
