import React, { useState, useEffect, useContext } from "react";
import RegistrationContext from "../../../context/RegistrationContext";
import {
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Row,
  Col,
  Alert,
  ModalHeader,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import TrainingContext from "context/TrainingContext";

const MAX_WRONG_ANSWERS = 3;

const WrongMessageAlert = ({ section, toggle, modal }) => {
  return (
    <Modal toggle={toggle} isOpen={modal}>
      <ModalHeader>Please answer the question</ModalHeader>
      <ModalBody>You must answer the question to proceed.</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle} style={{ marginRight: 12 }}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

function TrainingCommunicationAnswer(props) {
  const { slug } = useParams();
  const { trainingDispatch } = useContext(TrainingContext);
  const [answer, setAnswer] = useState(null);
  const [modal, setModal] = useState(false);
  const { section, history } = props;

  const toggle = () => setModal(!modal);

  useEffect(() => {
    setAnswer(section.quiz.userAnswer);
  }, [section]);

  return (
    <>
      <h5>{section.quiz.question}</h5>
      <WrongMessageAlert section={section} modal={modal} toggle={toggle} />
      <Input
        type="text"
        onChange={(e) => setAnswer(e.target.value)}
        value={answer}
      />
      <Row>
        <Col>
          <Link to={`/${slug}/register/training`}>
            <Button style={{ marginTop: 16 }}>Back to menu</Button>
          </Link>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <Button
            style={{ marginTop: 16 }}
            color={"primary"}
            disabled={answer ? false : true}
            onClick={(e) => {
              trainingDispatch({
                type: "SET_COMMUNICATION_ANSWER",
                // sectionId: section.id,
                answer,
              });
              if (
                section.quiz.userAnswer !== null ||
                section.quiz.userAnswer !== ""
              ) {
                setAnswer("");
                // history.push(`/${slug}/register/training/${section.id + 1}`);
                trainingDispatch({
                  type: "SET_SELECTED_INDEX",
                  _index: props.index + 1,
                });
              } else {
                toggle();
              }
            }}
          >
            {props.lastModule ? "Finish" : "Submit"}
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default TrainingCommunicationAnswer;
