/* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import MaskedInput from "react-text-mask";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import Resizer from "react-image-file-resizer";
import api from "components/API/api";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import {
  validateEmail,
  validateSin,
} from "components/Utilities/utilities.js";
import "components/CustomStyles/sigCanvas.css";
import RegistrationContext from "context/RegistrationContext";
import Axios from "axios";
import moment from "moment";
import TermsOfUse from "./TermsOfUse";
import PrivacyPolicy from "./PrivacyPolicy";
import EmploymentAgreement from "./EmploymentAgreement";
import { AGENCY_INFO } from "AgencyInfo";
import { PROVINCE_LIST } from "constants.js";

const sinMask = [
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
];

const sinRegistration = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
];

const phoneMask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  "-",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const postalCodeMask = [
  /[A-Za-z]/,
  /\d/,
  /[A-Za-z]/,
  " ",
  /\d/,
  /[A-Za-z]/,
  /\d/,
];

class CreateNewUser extends Component {
  static contextType = RegistrationContext;
  constructor(props) {
    super(props);
    this.defaultState = {
      allAgencies: [],
      agency: null,
      rawSignature: null,
      progress: 0,
      firstName: null,
      lastName: null,
      step: 0,
      availability: [
        {
          active: false,
          m: true,
          a: true,
          n: true,
        },
        {
          active: false,
          m: true,
          a: true,
          n: true,
        },
        {
          active: false,
          m: true,
          a: true,
          n: true,
        },
        {
          active: false,
          m: true,
          a: true,
          n: true,
        },
        {
          active: false,
          m: true,
          a: true,
          n: true,
        },
        {
          active: false,
          m: true,
          a: true,
          n: true,
        },
        {
          active: false,
          m: true,
          a: true,
          n: true,
        },
      ],
      gender: null,
      dob: null, // must be 18 +
      phone: null,
      address: null,
      pinNumber: null,
      badgeNumber: null,
      city: null,
      province: null,
      postalCode: null,
      sinRegisteredNumber: null,
      sin: null,
      sinExists: true,
      sinExpiry: null,
      studyPermitExpiry: undefined,
      workPermitExpiry: undefined,
      sinToSend: null,
      legallyWork: null,
      selected: false,
      completedTraining: {
        general: {
          completedDate: null,
          completed: false,
          expireDate: null,
        },
      },
      workStatus: null,
      emergencyFirstName: null, //the first emergency contact is mandatory
      emergencyLastName: null,
      emergencyPhone: null,
      emergencyRelationship: null,
      emergencyFirstName2: null, //the second emergeny contact is optional, so no error handling or checks
      emergencyLastName2: null,
      emergencyPhone2: null,
      emergencyRelationship2: null,
      email: null,
      confirmEmail: null,
      password: null,

      // States for images, 1 and 2 are government license, 3 and 4 are sin,
      // 5 and 6 are work permits (appears if workstatus is set to work permit)
      // 7 and 8 are student work visa (appears if workstatus is set to student visa)
      image1: null,
      image2: null,
      image3: null,
      image4: null,
      image5: null,
      image6: null,
      image7: null,
      image8: null,
      image9: null,

      //Errors
      errorSinRegisteredNumber: "",
      errorAgency: "",
      errorFirstName: "",
      errorLastName: "",
      errorAvailability: "",
      errorGender: "",
      errorAge: "",
      errorPhone: "",

      errorAddress: "",
      errorCity: "",
      errorProvince: "",
      errorPostalCode: "",
      errorSin: "",
      errorSinExpiry: "",
      errorWorkPermitExpiry: "",
      errorStudyPermitExpiry: "",
      errorCompletedTraining: "",
      errorLegallyWork: "",
      errorWorkStatus: "",
      errorImage1: "",
      errorImage3: "",
      errorImage5: "",
      errorImage7: "",
      errorImage9: "",
      errorEmergencyFirstName: "",
      errorEmergencyLastName: "",
      errorEmergencyPhone: "",
      errorEmergencyRelationship: "",
      errorEmail: "",
      errorConfirmEmail: "",
      errorPassword: "",
      errorSignature: "",
      errorVaccine: "",

      // Loading
      isLoading: false,
      showWorkPermit: false,
      showStudentVisa: false,
      vaccineStatus: null,
    };
    this.state = { ...this.defaultState };
  }

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    const business = urlParams.get("business");
    this.setState({ business });
    this.scrollToTop();
  }

  handleUserLeave = (e) => {
    const confirmationMessage = "Data would be sent if you leave the page";
    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  };

  scrollToFirstError = () => {
    //Convert to camelcase by adding prototype to String
    String.prototype.toLowerCaseFirstLetter = function () {
      return this.charAt(0).toLowerCase() + this.slice(1);
    };
    try {
      for (let prop in this.state) {
        if (prop.match(/^error+/) && this.state[prop]) {
          const id = prop.replace(/^error+/, "").toLowerCaseFirstLetter();
          var element = document.getElementById(id);
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });

          //By default scrollintoview takes some time to scroll smoothly so I set a settimeout function to
          //delay the focus so that it can normally scroll
          setTimeout(() => {
            element.focus();
          }, 500);
          break;
        }
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1200,
        1200,
        "JPEG",
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });

  createUserAccount = async (user) => {
    let { data } = await api().post("/users/register-worker", user);
    return data;
  };

  goToRegistrationComplete = (user) => {
    //the user has been created
    this.context.registrationDispatch({
      type: "SET_USER",
      user: user,
    });

    let slug = this.props.match.params.slug;
    this.props.history.push(`/${slug}/login`);
  };

  uploadFile = (file, signedRequest) => {
    Axios.put(signedRequest, file)
      .then(() => {
        console.log("successfully uploaded the image");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  signAndUpLoadPromise = (userId, imageName, image) => {
    let signedPromise = api()
      .get("/image/sign-s3/put", {
        params: {
          "file-path": `userDocs/${userId}/${imageName}`,
          "file-type": image.type,
        },
      })
      .then(({ data }) => {
        console.log(data);
        this.uploadFile(image, data.signedUrl);
        return true;
      });
    return signedPromise;
  };

  prepareAndSendDataToServer = async (tempState, signature, pdf) => {
    if (this.state.isLoading) {
      return;
    }
    this.setState({ isLoading: true });

    try {
      let emergency = [
        {
          firstName: tempState.emergencyFirstName,
          lastName: tempState.emergencyLastName,
          phone: tempState.emergencyPhone,
          relationship: tempState.emergencyRelationship,
        },
      ];

      if (tempState.emergencyFirstName2) {
        emergency.push({
          firstName: tempState.emergencyFirstName2,
          lastName: tempState.emergencyLastName2,
          phone: tempState.emergencyPhone2,
          relationship: tempState.emergencyRelationship2,
        });
      }

      // documents added
      const documents = [
        {
          type: 0, // goverment Id,
          fileName: "1.jpeg",
          status: 0,
        },
        {
          type: 5, // signature,
          fileName: "signature.jpeg",
          status: 0,
        },
        {
          type: 6, // agreement,
          fileName: `agreement.pdf`,
          status: 0,
        },
      ];
      if (tempState.sinExists) {
        documents.push({
          type: 2,
          fileName: "3.jpeg", //sin
          documentNumber: tempState.sin,
          expires: tempState.sinExpiry,
          status: 0,
        });
      } else {
        documents.push({
          type: 4,
          fileName: "9.jpeg", //sin registration
          documentNumber: tempState.sinRegisteredNumber,
          expires: new Date(moment().add(90, "days").toDate()),
          status: 0,
        });
      }

      if (tempState.workStatus.value === 1 && tempState.image5) {
        documents.push({
          type: 1,
          fileName: "5.jpeg", // work permit expiry
          expires: tempState.workPermitExpiry,
          status: 0,
        });
      }

      if (tempState.workStatus.value === 2 && tempState.image7) {
        documents.push({
          type: 3,
          fileName: "7.jpeg", //study permit expiry
          expires: tempState.studyPermitExpiry,
          status: 0,
        });
      }

      let dataToSend = {
        // General Information
        firstName: tempState.firstName,
        lastName: tempState.lastName,
        availability: tempState.availability,
        gender: tempState.gender,
        dob: new Date(tempState.dob),
        phone: tempState.phone,
        email: tempState.email,
        address: tempState.address,
        city: tempState.city,
        province: tempState.province.value,
        postalCode: tempState.postalCode,
        legallyWork: tempState.legallyWork,
        workStatus: tempState.workStatus.value,
        business: this.state.business,
        emergency,

        agencyId:
          this.context.registration.agency &&
          this.context.registration.agency._id,
        password: tempState.password,
        vaccineStatus: tempState.vaccineStatus?.value,
        documents: documents,
      };

      if (this.state.sin?.startsWith("9") && this.state.sinExpiry) {
        dataToSend.sinExpiry = this.state.sinExpiry;
      }
      if (tempState.sinExists) {
        dataToSend.sin = tempState.sin;
      }

      //create user account
      let { user } = await this.createUserAccount(dataToSend);

      if (user) {
        let promises = [];

        if (tempState.image1 != null) {
          promises.push(
            this.signAndUpLoadPromise(user._id, "1.jpeg", tempState.image1)
          );
        }
        if (tempState.sinExists && tempState.image3 != null) {
          promises.push(
            this.signAndUpLoadPromise(user._id, "3.jpeg", tempState.image3)
          );
        }
        if (tempState.workStatus.value === 1 && tempState.image5 != null) {
          promises.push(
            this.signAndUpLoadPromise(user._id, "5.jpeg", tempState.image5)
          );
        }
        if (tempState.workStatus.value === 2 && tempState.image7 != null) {
          promises.push(
            this.signAndUpLoadPromise(user._id, "7.jpeg", tempState.image7)
          );
        }
        if (!tempState.sinExists && tempState.image9 != null) {
          promises.push(
            this.signAndUpLoadPromise(user._id, "9.jpeg", tempState.image9)
          );
        }

        if (signature != null) {
          const response = await fetch(signature);
          const blob = await response.blob();

          promises.push(
            this.signAndUpLoadPromise(user._id, "signature.jpeg", blob)
          );
        }

        if (pdf != null) {
          promises.push(
            this.signAndUpLoadPromise(user._id, "agreement.pdf", pdf)
          );
        }
        try {
          await Promise.all(
            promises.map((promise) => {
              promise
                .then((response) => {
                  console.log("successfully uploaded images");
                })
                .catch((message) => {
                  console.log(message);
                });
            })
          ).then(() => {
            this.setState({
              isLoading: false,
            });
          });
         
        } catch (error) {
          this.setState({ isLoading: false });
          this.setState({
            alert: (
              <SweetAlert
                danger
                title={`An error has occured uploading your images: ${error.code}.`}
                onConfirm={() => {
                  this.setState({ alert: null });
                  this.goToRegistrationComplete(user);
                }}
              >
                {error.code}
              </SweetAlert>
            ),
          });
          return false;
        }
        this.setState({
          alert: (
            <SweetAlert
              success
              title={`Success!.`}
              onConfirm={() => {
                this.setState({ alert: null });
                this.goToRegistrationComplete(user);
              }}
            >
              Your account has been created. Please check your email for
              training invitation code. Once you log in you will need to enter
              your invitation code in order to complete your trainings.
            </SweetAlert>
          ),
        });

      } else {
        this.setState({
          alert: (
            <SweetAlert
              danger
              title={`Account couldn't be created`}
              onConfirm={this.hideAlert}
            >
              Sorry! The account couldn't be created.
            </SweetAlert>
          ),
          isLoading: false,
        });
        return false;
      }
    } catch (e) {
      this.setState({
        alert: (
          <SweetAlert
            danger
            title={
              (e.response && e.response.data && e.response.data.message) ||
              e.message
            }
            onConfirm={this.hideAlert}
          />
        ),
        isLoading: false,
      });
      return false;
    }
  };

  handleSubmitEmailSin = async () => {
    let tempState = this.state; // Copy of state
    let hasError = false;

    if (tempState.sinExists && (tempState.sin == null || tempState.sin == "")) {
      this.setState({ errorSin: "* Must have a SIN." }); //No sin
      hasError = true;
    } else {
      if (
        tempState.sinExists &&
        (validateSin(tempState.sin) === false || tempState.sin?.length != 9)
      ) {
        this.setState({ errorSin: "* Please input a valid SIN." }); //invalid sin
        hasError = true;
      } else if (tempState.sinExists && validateSin(tempState.sin) === true) {
        this.setState({ errorSin: "" });
      }
    }

    if (
      !tempState.sinExists &&
      (tempState.sinRegisteredNumber == null ||
        tempState.sinRegisteredNumber == "")
    ) {
      this.setState({
        errorSinRegisteredNumber: "* Must have a SIN registration number.",
      }); //No sin
      hasError = true;
    } else {
      if (!tempState.sinExists && tempState.sinRegisteredNumber?.length != 10) {
        this.setState({
          errorSinRegisteredNumber:
            "* Please input a valid SIN registration number.",
        }); //invalid sin
        hasError = true;
      } else {
        this.setState({ errorSinRegisteredNumber: "" });
      }
    }

    if (tempState.email == null || tempState.email == "") {
      this.setState({ errorEmail: "* Must have an email" }); // No email text
    } else {
      if (tempState.email != null && validateEmail(tempState.email) === false) {
        this.setState({ errorEmail: "* Please input a valid email" }); //invalid email text
        hasError = true;
      } else {
        this.setState({ errorEmail: "" });
      }
    }

    if (hasError) {
      return;
    }

    this.setState({ isLoading: true });

    try {
      const { data } = await api().post("/users/exists", {
        email: this.state.email,
        sin: this.state.sinExists ? this.state.sin : null,
      });
    
      const { exists, maskEmail } = data;
      if (!exists) {
        this.setState({ isLoading: false });
        this.handleNextStep();
      } else {
        this.setState({
          isLoading: false,
          step: 5, // render login
          alert: (
            <SweetAlert
              title={"Your information is already in our system"}
              onConfirm={() => {
                this.hideAlert();
                !this.state.business &&
                  this.props.history.push(
                    `/${this.props.match.params.slug}/auth/login`
                  );
              }}
            >
              You already have a registration in our system using this sin or
              email. Your registration email is {maskEmail}. To continue your
              registration, please login.
            </SweetAlert>
          ),
        });
      }
    } catch (e) {
      this.setState({
        alert: (
          <SweetAlert
            danger
            title={
              (e.response && e.response.data && e.response.data.message) ||
              e.message
            }
            onConfirm={this.hideAlert}
          />
        ),
        isLoading: false,
      });
    }
  };

  handleSubmitLogin = async () => {
    this.setState({ isLoading: true });
    let slug = this.props.match.params.slug;

    try {
      const { data } = await api().post("/users/apply/worker", {
        email: this.state.email,
        sin: this.state.sin,
        password: this.state.password,
        agencySlug: slug,
        business: this.state.business,
      });
      const { user } = data;
      if (user) {
        this.setState({
          alert: (
            <SweetAlert
              success
              title="Your registration was successful"
              onConfirm={() => this.goToRegistrationComplete(user)}
            />
          ),
          isLoading: false,
        });
      }
    } catch (e) {
      this.setState({
        alert: (
          <SweetAlert
            danger
            title="Your credentials are incorrect"
            onConfirm={this.hideAlert}
          />
        ),
        isLoading: false,
      });
    }
  };

  handleRecoverPassword = async () => {
    try {
      const { data } = await api().post("/users/request/reset-password", {
        email: this.state.email,
        slug: this.props.match.params.slug,
      });
      this.setState({
        alert: (
          <SweetAlert
            success
            title="Check your email to recover your password"
            onConfirm={this.hideAlert}
          />
        ),
        isLoading: false,
      });
    } catch (e) {
      this.setState({
        alert: (
          <SweetAlert
            danger
            title={
              (e.response && e.response.data && e.response.data.message) ||
              e.message
            }
            onConfirm={this.hideAlert}
          />
        ),
        isLoading: false,
      });
    }
  };

  handleSubmit = () => {
    let tempState = this.state; // Copy of state
    let hasError = false;
    // Start of Error Handling

    if (tempState.firstName == null || tempState.firstName == "") {
      this.setState({ errorFirstName: "* Must have a first name." });
      hasError = true;
    } else {
      this.setState({ errorFirstName: "" });
    }

    if (tempState.lastName == null || tempState.lastName == "") {
      this.setState({ errorLastName: "* Must have a last name." });
      hasError = true;
    } else {
      this.setState({ errorLastName: "" });
    }

    if (
      tempState.availability[0].active == false &&
      tempState.availability[1].active == false &&
      tempState.availability[2].active == false &&
      tempState.availability[3].active == false &&
      tempState.availability[4].active == false &&
      tempState.availability[5].active == false &&
      tempState.availability[6].active == false
    ) {
      hasError = true;
      this.setState({
        errorAvailability: "* Must have at least 1 day available for work.",
      });
    } else {
      this.setState({ errorAvailability: "" });
    }

    if (tempState.gender == null || tempState.gender == "") {
      this.setState({ errorGender: "* Must select a gender." });
      hasError = true;
    } else {
      this.setState({ errorGender: "" });
    }

    if (!tempState.dob) {
      this.setState({ errorAge: "* Must have date of birth." });
      hasError = true;
    } else if (new Date().getYear() - new Date(tempState.dob).getYear() < 18) {
      this.setState({ errorAge: "* Must be 18 or older." });
      hasError = true;
    } else {
      this.setState({ errorAge: "" });
    }

    if (tempState.phone == null || tempState.phone == "") {
      this.setState({ errorPhone: "* Must have a phone number." }); // No phone number
      hasError = true;
    } else {
      if (tempState.phone.length != 10 || tempState.phone < 0) {
        this.setState({ errorPhone: "* Please input a valid phone number." }); //invalid phone number
        hasError = true;
      } else {
        this.setState({ errorPhone: "" });
      }
    }

    if (tempState.email == null || tempState.email == "") {
      this.setState({ errorEmail: "* Must have an email" }); // No email text
    } else {
      if (tempState.email != null && validateEmail(tempState.email) === false) {
        this.setState({ errorEmail: "* Please input a valid email" }); //invalid email text
        hasError = true;
      } else {
        this.setState({ errorEmail: "" });
      }
    }

    if (tempState.address == null || tempState.address == "") {
      this.setState({ errorAddress: "* Must have a street address." });
      hasError = true;
    } else {
      this.setState({ errorAddress: "" });
    }

    if (tempState.city == null || tempState.city == "") {
      this.setState({ errorCity: "* Must have a city." });
      hasError = true;
    } else {
      this.setState({ errorCity: "" });
    }

    if (tempState.province == null || tempState.province == "") {
      this.setState({ errorProvince: "* Must have a province." });
      hasError = true;
    } else {
      this.setState({ errorProvince: "" });
    }

    if (tempState.postalCode == null || tempState.postalCode == "") {
      this.setState({ errorPostalCode: "* Must have a postal code." });
      hasError = true;
    } else {
      let regExp = "^[A-Za-z][0-9][A-Za-z][0-9][A-Za-z][0-9]$"; //logic for postal code format A1B2C3, capital letters only
      if (
        tempState.postalCode.match(regExp) == null ||
        tempState.postalCode.length != 6
      ) {
        this.setState({
          errorPostalCode:
            "* Please input a valid postal code in the format A1A1A1.",
        }); //invalid postal code
        hasError = true;
      } else {
        this.setState({ errorPostalCode: "" });
      }
    }

    if (
      !tempState.sinExists &&
      (tempState.sinRegisteredNumber == null ||
        tempState.sinRegisteredNumber == "")
    ) {
      this.setState({
        errorSinRegisteredNumber: "* Must have a SIN registration number.",
      }); //No sin
      hasError = true;
    } else {
      if (!tempState.sinExists && tempState.sinRegisteredNumber?.length != 10) {
        this.setState({
          errorSinRegisteredNumber:
            "* Please input a valid SIN registration number.",
        }); //invalid sin
        hasError = true;
      } else {
        this.setState({ errorSinRegisteredNumber: "" });
      }
    }

    if (tempState.sin?.startsWith("9")) {
      if (!tempState.sinExpiry) {
        this.setState({
          errorSinExpiry: "* Must have expiry date for sin starting with 9.",
        });
        hasError = true;
      } else if (moment().isSameOrAfter(tempState.sinExpiry, "date")) {
        this.setState({ errorSinExpiry: "* The sin is already expired." });
        hasError = true;
      } else {
        this.setState({ errorSinExpiry: "" });
      }
    } else {
      this.setState({ errorSinExpiry: "" });
    }

    if (tempState.completedTraining == null) {
      this.setState({ errorCompletedTraining: "* Must select yes or no" });
      hasError = true;
    } else {
      this.setState({ errorCompletedTraining: "" });
    }

    if (tempState.legallyWork == null) {
      this.setState({ errorLegallyWork: "* Must select yes or no" });
      hasError = true;
    } else {
      this.setState({ errorLegallyWork: "" });
    }

    if (tempState.workStatus == null) {
      this.setState({ errorWorkStatus: "* Must select a work status" });
      hasError = true;
    } else {
      this.setState({ errorWorkStatus: "" });
    }

    if (tempState.workStatus?.value === 1) {
      if (!tempState.workPermitExpiry) {
        this.setState({
          errorWorkPermitExpiry: "Must have expiry date for work permit.",
        });
        hasError = true;
      } else {
        this.setState({ errorWorkPermitExpiry: "" });
      }
    } else {
      this.setState({ errorWorkPermitExpiry: "" });
    }

    if (tempState.workStatus?.value === 2) {
      if (!tempState.studyPermitExpiry) {
        this.setState({
          errorStudyPermitExpiry: "Must have expiry date for study permit.",
        });
        hasError = true;
      } else {
        this.setState({ errorStudyPermitExpiry: "" });
      }
    } else {
      this.setState({ errorStudyPermitExpiry: "" });
    }

    if (tempState.emergencyFirstName == null) {
      this.setState({
        errorEmergencyFirstName:
          "* Must have a first name for the emergency contact.",
      });
      hasError = true;
    } else {
      this.setState({ errorEmergencyFirstName: "" });
    }

    if (tempState.emergencyLastName == null) {
      this.setState({
        errorEmergencyLastName:
          "* Must have a last name for the emergency contact.",
      });
      hasError = true;
    } else {
      this.setState({ errorEmergencyLastName: "" });
    }

    if (tempState.emergencyPhone == null || tempState.emergencyPhone == "") {
      this.setState({
        errorEmergencyPhone:
          "* Must have a phone number for the emergency contact.",
      }); // No phone number
      hasError = true;
    } else {
      if (
        tempState.emergencyPhone.length != 10 ||
        tempState.emergencyPhone < 0
      ) {
        this.setState({
          errorEmergencyPhone:
            "* Please input a valid phone number for the emergency contact.",
        }); //invalid phone number
        hasError = true;
      } else {
        this.setState({ errorEmergencyPhone: "" });
      }
    }

    if (tempState.emergencyRelationship == null) {
      this.setState({
        errorEmergencyRelationship:
          "* Must have a relationship for the emergency contact.",
      });
      hasError = true;
    } else {
      this.setState({ errorEmergencyRelationship: "" });
    }

    if (tempState.image1 == null) {
      this.setState({
        errorImage1:
          "* Must upload an image for the front of the government license",
      });
      hasError = true;
    } else {
      this.setState({ errorImage1: "" });
    }

    if (tempState.sinExists && tempState.image3 == null) {
      this.setState({
        errorImage3: "* Must upload an image for the front of the SIN card",
      });
      hasError = true;
    } else {
      this.setState({ errorImage3: "" });
    }

    if (!tempState.sinExists && tempState.image9 == null) {
      this.setState({
        errorImage9:
          "* Must upload an image for the front of the SIN registration proof",
      });
      hasError = true;
    } else {
      this.setState({ errorImage9: "" });
    }

    if (tempState.image5 == null && tempState.showWorkPermit == true) {
      this.setState({
        errorImage5: "* Must upload an image for the front of the work permit",
      });
      hasError = true;
    } else {
      this.setState({ errorImage5: "" });
    }

    if (tempState.image7 == null && tempState.showStudentVisa == true) {
      this.setState({
        errorImage7: "* Must upload an image for the front of the student visa",
      });
      hasError = true;
    } else {
      this.setState({ errorImage7: "" });
    }

    if (tempState.confirmEmail == null || tempState.confirmEmail == "") {
      this.setState({ errorConfirmEmail: "* Must confirm email." });
      hasError = true;
    } else if (
      tempState.confirmEmail.toLowerCase() !== tempState.email.toLowerCase()
    ) {
      this.setState({ errorConfirmEmail: "* Your emails do not match" });
      hasError = true;
    } else {
      this.setState({ errorConfirmEmail: "" });
    }

    if (tempState.password == null || tempState.password == "") {
      this.setState({ errorPassword: "* Must have a password." });
      hasError = true;
    } else if (tempState.password && tempState.password.length < 8) {
      this.setState({
        errorPassword: "* Your password must be at least 8 characters long.",
      });
      hasError = true;
    } else {
      this.setState({ errorPassword: "" });
    }

    if (!tempState.vaccineStatus && tempState.vaccineStatus !== 0) {
      this.setState({ errorVaccine: "* Must submit your vaccine status." });
      hasError = true;
    } else {
      this.setState({ errorVaccine: "" });
    }

    if (hasError) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            title="Please ensure all mandatory fields (*) are filled"
            onConfirm={this.hideAlert}
          />
        ),
      });
      console.log(this.state);
      return;
    } else {
      this.handleNextStep();
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
    this.scrollToFirstError();
  };

  addImageToState = async (stateName, image) => {
    const resizedImage = await this.resizeFile(image);
    this.setState({ [stateName]: resizedImage });
  };

  removeImageFromState = (stateName) => {
    this.setState({ [stateName]: null });
  };

  handleRadio = (event) => {
    const target = event.target;
    this.setState({
      [target.name]: target.value,
    });
  };

  // day = number between 0 (monday) to 6 (sunday)
  toggleCheckMark = (day) => {
    let tempAvailability = this.state.availability;
    tempAvailability[day].active = !tempAvailability[day].active;
    this.setState({ availability: tempAvailability });
  };

  // MAN = choice of "m", "a", "n" for morning afternoon or night
  toggleShifts = (MAN, day) => {
    let tempAvailability = this.state.availability;

    MAN == "m"
      ? (tempAvailability[day].m = !tempAvailability[day].m) // m
      : MAN == "a"
      ? (tempAvailability[day].a = !tempAvailability[day].a) // a
      : (tempAvailability[day].n = !tempAvailability[day].n); // n

    this.setState({ availability: tempAvailability });
  };

  // handle next to go to next step
  handleNextStep = () => {
    this.setState({ step: this.state.step + 1 });
  };

  // handle to go to prev step
  handlePrevStep = () => {
    this.setState({ step: this.state.step - 1 });
  };

  _renderWorkPermit = () => {
    if (this.state.showWorkPermit == true) {
      return (
        <Row>
          <Label sm="2">* Work Permit</Label>
          <Col sm="4">
            <CardTitle tag="p">* (Front)</CardTitle>
            <ImageUpload
              addImageToState={this.addImageToState}
              imageState="image5"
              removeImageFromState={this.removeImageFromState}
            />
            <FormText color="danger" tag="span">
              {this.state.errorImage5}
            </FormText>
          </Col>
          <Col sm="4"></Col>
        </Row>
      );
    }
  };

  _renderStudentVisa = () => {
    if (this.state.showStudentVisa == true) {
      return (
        <Row>
          <Label sm="2">* Student Visa</Label>
          <Col sm="4">
            <CardTitle tag="p">* (Front)</CardTitle>
            <ImageUpload
              addImageToState={this.addImageToState}
              imageState="image7"
              removeImageFromState={this.removeImageFromState}
            />
            <FormText color="danger" tag="span">
              {this.state.errorImage7}
            </FormText>
          </Col>
          <Col sm="4"></Col>
        </Row>
      );
    }
  };

  _renderAvailability = (dayOfWeekText, day) => {
    return (
      <Row style={{ display: "flex", justifyContent: "flex-start" }}>
        <Col style={{ display: "flex", justifyContent: "flex-start" }} sm="3">
          <FormGroup check inline>
            <Label check>
              <Input
                type="checkbox"
                onChange={() => this.toggleCheckMark(day)}
                checked={this.state.availability[day].active === true}
                id={dayOfWeekText === "Monday" ? "availability" : null}
              />
              <span className="form-check-sign" />
              {dayOfWeekText}
            </Label>
          </FormGroup>
        </Col>

        {this.state.availability[day].active ? (
          <Col
            sm="7"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <FormGroup check inline>
              <Label check>
                <Input
                  type="checkbox"
                  checked={this.state.availability[day].m}
                  onChange={() => this.toggleShifts("m", day)}
                />
                <span className="form-check-sign" />
                Morning
              </Label>
            </FormGroup>

            <FormGroup check inline>
              <Label check>
                <Input
                  type="checkbox"
                  checked={this.state.availability[day].a}
                  onChange={() => this.toggleShifts("a", day)}
                />
                <span className="form-check-sign" />
                Afternoon
              </Label>
            </FormGroup>

            <FormGroup check inline>
              <Label check>
                <Input
                  type="checkbox"
                  checked={this.state.availability[day].n}
                  onChange={() => this.toggleShifts("n", day)}
                />
                <span className="form-check-sign" />
                Night
              </Label>
            </FormGroup>
          </Col>
        ) : null}
      </Row>
    );
  };

  _renderEmailSinForm = () => {
    return (
      <>
        <Row>
          <Label sm="2">* Email</Label>
          <Col sm="5">
            <FormGroup>
              <Input
                type="email"
                autoComplete="off"
                placeholder="Enter your email"
                onChange={(e) => this.setState({ email: e.target.value })}
                value={this.state.email || ""}
                id="email"
              />
              <FormText color="danger" tag="span">
                {this.state.errorEmail}
              </FormText>
            </FormGroup>
          </Col>
        </Row>
        <Row sm="8">
          <Label sm="1"></Label>
          <Row sm="7" style={{ display: "flex", justifyContent: "flex-start" }}>
            <FormGroup check inline>
              <Label check>
                <Input
                  type="checkbox"
                  onChange={() =>
                    this.setState({ sinExists: !this.state.sinExists })
                  }
                  checked={!this.state.sinExists}
                  id={"sin reg"}
                />
                <span className="form-check-sign" />I have a SIN Registration
                Number
              </Label>
            </FormGroup>
          </Row>
        </Row>

        {this.state.sinExists ? (
          <Row>
            <Label sm="2">* Social Insurance Number</Label>
            <Col sm="5">
              <FormGroup>
                <MaskedInput
                  mask={sinMask}
                  id="sin"
                  className="form-control"
                  autoComplete="off"
                  maxLength="12"
                  placeholder="xxx xxx xxx"
                  onChange={(e) => {
                    //Allows the user to type in any format. Only numbers will be added to the state
                    let input = e.target.value;
                    let replacedSin = input.replace(/[^0-9]/g, "");
                    this.setState({ sin: replacedSin });
                  }}
                  value={this.state.sin || ""}
                />
                <FormText color="danger" tag="span">
                  {this.state.errorSin}
                </FormText>
              </FormGroup>
            </Col>
          </Row>
        ) : (
          <Row>
            <Label sm="2">* SIN Registration Number </Label>
            <Col sm="5">
              <FormGroup>
                <MaskedInput
                  mask={sinRegistration}
                  id="sin"
                  className="form-control"
                  autoComplete="off"
                  maxLength="13"
                  placeholder="xxxx xxx xxx"
                  onChange={(e) => {
                    //Allows the user to type in any format. Only numbers will be added to the state
                    let input = e.target.value;
                    let replacedSin = input.replace(/[^0-9]/g, "");
                    this.setState({ sinRegisteredNumber: replacedSin });
                  }}
                  value={this.state.sinRegisteredNumber || ""}
                />
                <FormText color="danger" tag="span">
                  {this.state.errorSinRegisteredNumber}
                </FormText>
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row>
          <Col sm="10" align="right">
            <Button color="success" onClick={() => this.handleSubmitEmailSin()}>
              Continue
            </Button>
          </Col>
          <Col sm="2"></Col>
        </Row>
      </>
    );
  };

  _renderLoginForm = () => {
    return (
      <>
        <Row>
          <Label sm="2">* Email</Label>
          <Col sm="5">
            <FormGroup>
              <Input
                type="email"
                autoComplete="off"
                placeholder="Enter your email"
                onChange={(e) => this.setState({ email: e.target.value })}
                value={this.state.email || ""}
                id="email"
              />
              <FormText color="danger" tag="span">
                {this.state.errorEmail}
              </FormText>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Label sm="2">* Social Insurance Number</Label>
          <Col sm="5">
            <FormGroup>
              <MaskedInput
                mask={sinMask}
                id="sin"
                className="form-control"
                autoComplete="off"
                maxLength="12"
                placeholder="xxx xxx xxx"
                onChange={(e) => {
                  //Allows the user to type in any format. Only numbers will be added to the state
                  let input = e.target.value;
                  let replacedSin = input.replace(/[^0-9]/g, "");
                  this.setState({ sin: replacedSin });
                }}
                value={this.state.sin || ""}
              />
              <FormText color="danger" tag="span">
                {this.state.errorConfirmEmail}
              </FormText>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Label sm="2">* Password</Label>
          <Col sm="5">
            <FormGroup>
              <Input
                type="password"
                autoComplete="off"
                placeholder="Minimum 8 characters"
                onChange={(e) => this.setState({ password: e.target.value })}
                value={this.state.password || ""}
                id="password"
              />
              <FormText color="danger" tag="span">
                {this.state.errorPassword}
              </FormText>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="6" align="left">
            <Button
              color="secundary"
              onClick={() => this.handleRecoverPassword()}
            >
              Recover password
            </Button>
          </Col>
          <Col sm="6" align="right">
            <Button color="success" onClick={() => this.handleSubmitLogin()}>
              Continue
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  _renderMainForm = () => {
    return (
      <Form action="/" className="form-horizontal" method="get">
        <Row>
          <Label sm="2">* First Name</Label>
          <Col sm="5">
            <FormGroup>
              <Input
                type="text"
                autoComplete="off"
                onChange={(e) => this.setState({ firstName: e.target.value })}
                value={this.state.firstName || ""}
                id="firstName"
              />
              <FormText color="danger" tag="span">
                {this.state.errorFirstName}
              </FormText>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Label sm="2">* Last Name</Label>
          <Col sm="5">
            <FormGroup>
              <Input
                type="text"
                autoComplete="off"
                onChange={(e) => this.setState({ lastName: e.target.value })}
                value={this.state.lastName || ""}
                id="lastName"
              />
              <FormText color="danger" tag="span">
                {this.state.errorLastName}
              </FormText>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Label sm="2">* Availability</Label>
          <Col className="checkbox-radios" xs="12" sm="10" md="10">
            {this._renderAvailability("Monday", 0)}
            {this._renderAvailability("Tuesday", 1)}
            {this._renderAvailability("Wednesday", 2)}
            {this._renderAvailability("Thursday", 3)}
            {this._renderAvailability("Friday", 4)}
            {this._renderAvailability("Saturday", 5)}
            {this._renderAvailability("Sunday", 6)}
            <FormText color="danger" tag="span">
              {this.state.errorAvailability}
            </FormText>
          </Col>
        </Row>
        <Row>
          <Label sm="2">* Gender</Label>
          <Col className="checkbox-radios" sm="2">
            <div className="form-check-radio" style={{ marginTop: 8 }}>
              <Label check id="gender">
                <Input
                  defaultValue="M"
                  id="M"
                  name="genderRadio"
                  type="radio"
                  onChange={() => this.setState({ gender: "M" })}
                  checked={this.state.gender === "M"}
                />
                Male <span className="form-check-sign" />
              </Label>
            </div>
            <div className="form-check-radio">
              <Label check>
                <Input
                  defaultValue="F"
                  id="F"
                  name="genderRadio"
                  type="radio"
                  onChange={() => this.setState({ gender: "F" })}
                  checked={this.state.gender === "F"}
                />
                Female <span className="form-check-sign" />
              </Label>
              <FormText color="danger" tag="span">
                {this.state.errorGender}
              </FormText>
            </div>
          </Col>
        </Row>

        <Row>
          <Label sm="2">* Date of Birth</Label>
          <Col sm="5">
            <FormGroup>
              <Input
                type="date"
                autoComplete="off"
                maxLength="3"
                max="9999-12-12"
                onChange={(e) => {
                  this.setState({ dob: e.target.value });
                }}
                value={this.state.dob || ""}
                id="age"
                placeholder="yyyy-mm-dd"
              />
              <FormText color="danger" tag="span">
                {this.state.errorAge}
              </FormText>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Label sm="2">* Phone Number</Label>
          <Col sm="5">
            <FormGroup>
              <MaskedInput
                mask={phoneMask}
                className="form-control"
                autoComplete="off"
                maxLength="15"
                placeholder="(xxx)-xxx-xxxx"
                onChange={(e) => {
                  //Allows the user to type in any format. Only numbers will be added to the state
                  let input = e.target.value;
                  let replacedPhone = input.replace(/[^0-9]/g, "");
                  this.setState({ phone: replacedPhone });
                }}
                value={this.state.phone || ""}
                id="phone"
              />
              <FormText color="danger" tag="span">
                {this.state.errorPhone}
              </FormText>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Label sm="2">* Street Address</Label>
          <Col sm="5">
            <FormGroup>
              <Input
                type="text"
                autoComplete="off"
                onChange={(e) => this.setState({ address: e.target.value })}
                value={this.state.address || ""}
                id="address"
              />
              <FormText color="danger" tag="span">
                {this.state.errorAddress}
              </FormText>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Label sm="2">* City</Label>
          <Col sm="5">
            <FormGroup>
              <Input
                type="text"
                autoComplete="off"
                onChange={(e) => this.setState({ city: e.target.value })}
                value={this.state.city || ""}
                id="city"
              />
              <FormText color="danger" tag="span">
                {this.state.errorCity}
              </FormText>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Label sm="2">* Province</Label>
          <Col sm="5">
            <FormGroup>
              <Select
                className="react-select primary"
                classNamePrefix="react-select"
                name="singleSelect"
                value={this.state.province}
                onChange={(value) => this.setState({ province: value })}
                id="province"
                options={PROVINCE_LIST}
                placeholder="Select a province"
              />
              <FormText color="danger" tag="span">
                {this.state.errorProvince}
              </FormText>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Label sm="2">* Postal Code</Label>
          <Col sm="5">
            <FormGroup>
              <MaskedInput
                mask={postalCodeMask}
                id="postalCode"
                className="form-control"
                autoComplete="off"
                maxLength="8"
                placeholder="A1B 2C3"
                onChange={(e) => {
                  let input = e.target.value;
                  let replacedPostalCode = input.replace(/[^a-zA-Z_0-9-]/g, "");
                  this.setState({ postalCode: replacedPostalCode });
                }}
                value={this.state.postalCode || ""}
              />
              <FormText color="danger" tag="span">
                {this.state.errorPostalCode}
              </FormText>
            </FormGroup>
          </Col>
        </Row>
        {this.state.sinExists ? (
          <Row>
            <Label sm="2">* Social Insurance Number</Label>
            <Col sm="5">
              <FormGroup>
                <MaskedInput
                  mask={sinMask}
                  id="sin"
                  className="form-control"
                  autoComplete="off"
                  maxLength="12"
                  disabled={true}
                  placeholder="xxx xxx xxx"
                  onChange={(e) => {
                    //Allows the user to type in any format. Only numbers will be added to the state
                    let input = e.target.value;
                    let replacedSin = input.replace(/[^0-9]/g, "");
                    this.setState({ sin: replacedSin });
                  }}
                  value={this.state.sin || ""}
                />
                <FormText color="danger" tag="span">
                  {this.state.errorSin}
                </FormText>
              </FormGroup>
            </Col>
            <Col style={{ textAlign: "left" }}>
              <Button
                style={{ marginTop: "0" }}
                color="secundary"
                onClick={() => this.setState({ step: 0 })}
              >
                Edit
              </Button>
            </Col>
          </Row>
        ) : (
          <Row>
            <Label sm="2">* SIN Registration Number </Label>
            <Col sm="5">
              <FormGroup>
                <MaskedInput
                  mask={sinRegistration}
                  id="sin"
                  className="form-control"
                  autoComplete="off"
                  maxLength="13"
                  placeholder="xxxx xxx xxx"
                  disabled={true}
                  onChange={(e) => {
                    //Allows the user to type in any format. Only numbers will be added to the state
                    let input = e.target.value;
                    let replacedSin = input.replace(/[^0-9]/g, "");
                    this.setState({ sinRegisteredNumber: replacedSin });
                  }}
                  value={this.state.sinRegisteredNumber || ""}
                />
                <FormText color="danger" tag="span">
                  {this.state.errorSinRegisteredNumber}
                </FormText>
              </FormGroup>
            </Col>
            <Col style={{ textAlign: "left" }}>
              <Button
                style={{ marginTop: "0" }}
                color="secundary"
                onClick={() => this.setState({ step: 0 })}
              >
                Edit
              </Button>
            </Col>
          </Row>
        )}

        <Row>
          {this.state.sin?.startsWith("9") ? (
            <>
              <Label sm="2">*Sin Expiry Date</Label>
              <Col sm="5">
                <FormGroup>
                  <Input
                    type="date"
                    autoComplete="off"
                    maxLength="3"
                    max="2099-12-12"
                    onChange={(e) => {
                      this.setState({ sinExpiry: e.target.value });
                    }}
                    value={this.state.sinExpiry || ""}
                    id="sinExpiry"
                  />
                  <FormText color="danger" tag="span">
                    {this.state.errorSinExpiry}
                  </FormText>
                </FormGroup>
              </Col>
            </>
          ) : null}
        </Row>

        <Row>
          <Label sm="2">* Legally Allowed to Work?</Label>
          <Col className="checkbox-radios" sm="10">
            <div className="form-check-radio" style={{ marginTop: 8 }}>
              <Label check id="legallyWorkYes">
                <Input
                  defaultValue
                  id="Yes"
                  name="40hRadio"
                  type="radio"
                  onChange={() => this.setState({ legallyWork: true })}
                  checked={this.state.legallyWork}
                />
                Yes <span className="form-check-sign" />
              </Label>
            </div>
            <div className="form-check-radio" id="legallyWork">
              <Label check id="legallyWorkNo">
                <Input
                  defaultValue
                  id="No"
                  name="40hRadio"
                  type="radio"
                  onChange={() => this.setState({ legallyWork: false })}
                  checked={this.state.legallyWork === false}
                />
                No <span className="form-check-sign" />
              </Label>
              <FormText color="danger" tag="span">
                {this.state.errorLegallyWork}
              </FormText>
            </div>
          </Col>
        </Row>
        <Row>
          <Label sm="2">* Work Status</Label>
          <Col sm="5">
            <FormGroup>
              <Select
                id="workStatus"
                className="react-select primary"
                classNamePrefix="react-select"
                name="singleSelect"
                value={this.state.workStatus}
                onChange={(value) => {
                  this.setState({ workStatus: value });
                  if (value.value === 1) {
                    this.setState({
                      showWorkPermit: true,
                      showStudentVisa: false,
                      image7: null,
                      image8: null,
                    });
                  } else if (value.value === 2) {
                    this.setState({
                      showStudentVisa: true,
                      showWorkPermit: false,
                      image5: null,
                      image6: null,
                    });
                  } else {
                    this.setState({
                      showWorkPermit: false,
                      showStudentVisa: false,
                      image5: null,
                      image6: null,
                      image7: null,
                      image8: null,
                    });
                  }
                }}
                options={[
                  {
                    value: 0,
                    label: "Permanent Residence/Citizen",
                  },
                  { value: 1, label: "Work Permit" },
                  {
                    value: 2,
                    label: "Student Visa",
                  },
                ]}
                placeholder="Select a work status"
              />
              <FormText color="danger" tag="span">
                {this.state.errorWorkStatus}
              </FormText>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          {this.state.workStatus?.value === 1 ? (
            <>
              <Label sm="2">* Work Permit Expiry Date</Label>
              <Col sm="5">
                <FormGroup>
                  <Input
                    type="date"
                    autoComplete="off"
                    maxLength="3"
                    max="2099-12-12"
                    onChange={(e) => {
                      this.setState({ workPermitExpiry: e.target.value });
                    }}
                    value={this.state.workPermitExpiry || ""}
                    id="workPermitExpiry"
                  />
                  <FormText color="danger" tag="span">
                    {this.state.errorWorkPermitExpiry}
                  </FormText>
                </FormGroup>
              </Col>
            </>
          ) : null}
        </Row>
        <Row>
          {this.state.workStatus?.value === 2 ? (
            <>
              <Label sm="2">* Study Permit Expiry Date</Label>
              <Col sm="5">
                <FormGroup>
                  <Input
                    type="date"
                    autoComplete="off"
                    maxLength="3"
                    max="2099-12-12"
                    onChange={(e) => {
                      this.setState({ studyPermitExpiry: e.target.value });
                    }}
                    value={this.state.studyPermitExpiry || ""}
                    id="studyPermitExpiry"
                  />
                  <FormText color="danger" tag="span">
                    {this.state.errorStudyPermitExpiry}
                  </FormText>
                </FormGroup>
              </Col>
            </>
          ) : null}
        </Row>

        <Row>
          <Label sm="2">
            * Government Issued Photo ID (passport or driver's license)
          </Label>
          <Col sm="4">
            <CardTitle tag="p">* (Front)</CardTitle>
            <ImageUpload
              addImageToState={this.addImageToState}
              imageState="image1"
              removeImageFromState={this.removeImageFromState}
              id="image1"
            />
            <FormText color="danger" tag="span">
              {this.state.errorImage1}
            </FormText>
          </Col>
          <Col sm="4"></Col>
        </Row>
        {this.state.sinExists ? (
          <Row>
            <Label sm="2">* Proof of Sin</Label>
            <Col sm="4">
              <CardTitle tag="p">* (Front)</CardTitle>
              <ImageUpload
                addImageToState={this.addImageToState}
                imageState="image3"
                id="image3"
                removeImageFromState={this.removeImageFromState}
              />
              <FormText color="danger" tag="span">
                {this.state.errorImage3}
              </FormText>
            </Col>
            <Col sm="4"></Col>
          </Row>
        ) : (
          <Row>
            <Label sm="2">* Proof of Sin Registration</Label>
            <Col sm="4">
              <CardTitle tag="p">* (Front)</CardTitle>
              <ImageUpload
                addImageToState={this.addImageToState}
                imageState="image9"
                id="image9"
                removeImageFromState={this.removeImageFromState}
              />
              <FormText color="danger" tag="span">
                {this.state.errorImage9}
              </FormText>
            </Col>
            <Col sm="4"></Col>
          </Row>
        )}
        {this._renderWorkPermit()}
        {this._renderStudentVisa()}
        <div
          style={{
            width: "100%",
            height: 0,
            marginTop: "35px",
            marginBottom: "25px",
          }}
        />
        <Row>
          <Label sm="2">* Emergency Contact 1</Label>
          <Col sm="4">
            <FormGroup>
              <Input
                type="text"
                autoComplete="off"
                placeholder="* First Name"
                onChange={(e) =>
                  this.setState({
                    emergencyFirstName: e.target.value,
                  })
                }
                value={this.state.emergencyFirstName || ""}
                id="emergencyFirstName"
              />
              <FormText color="danger" tag="span">
                {this.state.errorEmergencyFirstName}
              </FormText>
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup>
              <MaskedInput
                mask={phoneMask}
                className="form-control"
                autoComplete="off"
                maxLength="15"
                placeholder="(xxx)-xxx-xxxx"
                onChange={(e) => {
                  //Allows the user to type in any format. Only numbers will be added to the state
                  let input = e.target.value;
                  let replacedPhone = input.replace(/[^0-9]/g, "");
                  this.setState({ emergencyPhone: replacedPhone });
                }}
                value={this.state.emergencyPhone || ""}
                id="emergencyPhone"
              />
              <FormText color="danger" tag="span">
                {this.state.errorEmergencyPhone}
              </FormText>
            </FormGroup>
          </Col>
          <Label sm="2"></Label>
        </Row>
        <Row>
          <Label sm="2"></Label>
          <Col sm="4">
            <FormGroup>
              <Input
                type="text"
                autoComplete="off"
                placeholder="* Last Name"
                onChange={(e) =>
                  this.setState({
                    emergencyLastName: e.target.value,
                  })
                }
                value={this.state.emergencyLastName || ""}
                id="emergencyLastName"
              />
              <FormText color="danger" tag="span">
                {this.state.errorEmergencyLastName}
              </FormText>
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup>
              <Input
                type="text"
                autoComplete="off"
                placeholder="* Relationship"
                onChange={(e) =>
                  this.setState({
                    emergencyRelationship: e.target.value,
                  })
                }
                value={this.state.emergencyRelationship || ""}
                id="emergencyRelationship"
              />
              <FormText color="danger" tag="span">
                {this.state.errorEmergencyRelationship}
              </FormText>
            </FormGroup>
          </Col>
          <Label sm="2"></Label>
        </Row>
        <div
          style={{
            width: "100%",
            height: 0,
            marginTop: "35px",
            marginBottom: "25px",
          }}
        />
        <Row>
          <Label sm="2">Emergency Contact 2</Label>
          <Col sm="4">
            <FormGroup>
              <Input
                type="text"
                autoComplete="off"
                placeholder="First Name (optional)"
                onChange={(e) =>
                  this.setState({
                    emergencyFirstName2: e.target.value,
                  })
                }
                value={this.state.emergencyFirstName2 || ""}
              />
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup>
              <MaskedInput
                mask={phoneMask}
                className="form-control"
                autoComplete="off"
                maxLength="15"
                placeholder="(xxx)-xxx-xxxx"
                onChange={(e) => {
                  //Allows the user to type in any format. Only numbers will be added to the state
                  let input = e.target.value;
                  let replacedPhone = input.replace(/[^0-9]/g, "");
                  this.setState({ emergencyPhone2: replacedPhone });
                }}
                value={this.state.emergencyPhone2 || ""}
              />
            </FormGroup>
          </Col>
          <Label sm="2"></Label>
        </Row>
        <Row>
          <Label sm="2"></Label>
          <Col sm="4">
            <FormGroup>
              <Input
                type="text"
                autoComplete="off"
                placeholder="Last Name (optional)"
                onChange={(e) =>
                  this.setState({
                    emergencyLastName2: e.target.value,
                  })
                }
                value={this.state.emergencyLastName2 || ""}
              />
              <FormText color="danger" tag="span"></FormText>
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup>
              <Input
                type="text"
                autoComplete="off"
                placeholder="Relationship (optional)"
                onChange={(e) =>
                  this.setState({
                    emergencyRelationship2: e.target.value,
                  })
                }
                value={this.state.emergencyRelationship2 || ""}
              />
            </FormGroup>
          </Col>
          <Label sm="2"></Label>
        </Row>
        <div
          style={{
            borderColor: "#D8CBC7",
            borderStyle: "solid",
            borderWidth: "0.3px",
            width: "100%",
            height: 0,
            marginTop: "35px",
            marginBottom: "25px",
          }}
        />
        <Row>
          <Label md="2">* Vaccine Status</Label>
          <Col md="8">
            <FormGroup>
              <Select
                id="vaccineStatus"
                className="react-select primary"
                classNamePrefix="react-select"
                name="singleSelect"
                value={this.state.vaccineStatus}
                onChange={(value) => {
                  this.setState({
                    vaccineStatus: value,
                  });
                }}
                options={[
                  {
                    value: 0,
                    label: "No vaccine yet!",
                  },
                  { value: 1, label: "Received 1st dose" },
                  {
                    value: 2,
                    label: "Received 2nd dose/Fully vaccinated",
                  },
                ]}
                placeholder="Select your current vaccine status"
              />

              <FormText color="danger" tag="span">
                {this.state.errorVaccine}
              </FormText>
            </FormGroup>
          </Col>
        </Row>
        <div
          style={{
            borderColor: "#D8CBC7",
            borderStyle: "solid",
            borderWidth: "0.3px",
            width: "100%",
            height: 0,
            marginTop: "35px",
            marginBottom: "25px",
          }}
        />
        <Row>
          <Label sm="2">* Email</Label>
          <Col sm="5">
            <FormGroup>
              <Input
                type="email"
                autoComplete="off"
                placeholder="Enter your email"
                disabled={true}
                onChange={(e) => this.setState({ email: e.target.value })}
                value={this.state.email || ""}
                id="email"
              />
              <FormText color="danger" tag="span">
                {this.state.errorEmail}
              </FormText>
            </FormGroup>
          </Col>
          <Col style={{ textAlign: "left" }}>
            <Button
              style={{ marginTop: "0" }}
              color="secundary"
              onClick={() => this.setState({ step: 0 })}
            >
              Edit
            </Button>
          </Col>
        </Row>
        <Row>
          <Label sm="2">* Confirm Email</Label>
          <Col sm="5">
            <FormGroup>
              <Input
                type="email"
                autoComplete="off"
                placeholder="Confirm your email"
                onChange={(e) =>
                  this.setState({ confirmEmail: e.target.value })
                }
                value={this.state.confirmEmail || ""}
                id="confirmEmail"
              />
              <FormText color="danger" tag="span">
                {this.state.errorConfirmEmail}
              </FormText>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Label sm="2">* Password</Label>
          <Col sm="5">
            <FormGroup>
              <Input
                type="password"
                autoComplete="off"
                placeholder="Minimum 8 characters"
                onChange={(e) => this.setState({ password: e.target.value })}
                value={this.state.password || ""}
                id="password"
              />
              <FormText color="danger" tag="span">
                {this.state.errorPassword}
              </FormText>
            </FormGroup>
          </Col>
        </Row>
        <div
          style={{
            borderColor: "#D8CBC7",
            borderStyle: "solid",
            borderWidth: "0.3px",
            width: "100%",
            height: 0,
            marginTop: "35px",
            marginBottom: "25px",
          }}
        />
        <Row>
          <Col xs="6" align="left">
            <Button color="secundary" onClick={() => this.handlePrevStep()}>
              back
            </Button>
          </Col>
          <Col xs="6" align="right">
            <Button
              color="success"
              size="lg"
              onClick={() => this.handleSubmit()}
            >
              Next
            </Button>
          </Col>
        </Row>
        <div
          style={{
            width: "100%",
            height: 0,
            marginTop: "35px",
            marginBottom: "25px",
          }}
        />
      </Form>
    );
  };

  loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={
          <Spinner color="light" style={{ width: "3rem", height: "3rem" }} />
        }
        onConfirm={this.hideAlert}
        showConfirm={false}
      />
    );
  };

  render() {
    return (
      <div>
        {this.state.isLoading ? (
          this.loadingOverlay()
        ) : (
          <Row style={{ margin: 0, padding: 0 }}>
            <Col xl="3" lg="2"></Col>
            <Col xl="6" lg="8">
              <Card className="no-transition">
                <CardHeader
                  style={{ alignItems: "center", textAlign: "center" }}
                >
                   <img
                    src={AGENCY_INFO.registrationBanner}
                    alt={AGENCY_INFO.registrationBannerAltText}
                  ></img>
                  <br></br>
                  <div
                    style={{
                      borderColor: "#D8CBC7",
                      borderStyle: "solid",
                      borderWidth: "0.3px",
                      width: "100%",
                      height: 0,
                      marginTop: "35px",
                      marginBottom: "15px",
                    }}
                  />
                  <CardTitle tag="h4" style={{ fontWeight: "bold" }}>
                    Job Application
                  </CardTitle>
                  <p>
                    Thank you for your interest in working with {AGENCY_INFO.name}.
                    Please read carefully and fill out all information to the
                    best of your knowledge. If this application leads to
                    employment, you understand that false or misleading
                    information in your application or interview may result in
                    your release.
                    <br></br>
                    <br></br>
                    <span style={{ fontWeight: "bold" }}>
                      Please read the following information carefully:
                    </span>
                    <br></br>
                    In this application, you must attach images of certain
                    pieces of identification. All applications must include an
                    image of the front of your sin card and government issued
                    photo ID (such as a driver's license or passport). If you
                    are not a citizen or permanent resident of Canada, you must
                    also include an image of your work permit or student visa as
                    applicable.
                  </p>
                  <div
                    style={{
                      borderColor: "#D8CBC7",
                      borderStyle: "solid",
                      borderWidth: "0.3px",
                      width: "100%",
                      height: 0,
                      marginTop: "35px",
                      marginBottom: "25px",
                    }}
                  />
                  {this.state.step === 0 && this._renderEmailSinForm()}
                  {this.state.step === 1 && (
                    <TermsOfUse
                      handleNextStep={this.handleNextStep}
                      handlePrevStep={this.handlePrevStep}
                    />
                  )}
                  {this.state.step === 2 && (
                    <PrivacyPolicy
                      handleNextStep={this.handleNextStep}
                      handlePrevStep={this.handlePrevStep}
                    />
                  )}
                  {this.state.step === 3 && this._renderMainForm()}
                  {this.state.step === 4 && (
                    <EmploymentAgreement
                      handleNextStep={this.handleNextStep}
                      handlePrevStep={this.handlePrevStep}
                      handleSubmit={(signature, pdf) =>
                        this.prepareAndSendDataToServer(
                          this.state,
                          signature,
                          pdf
                        )
                      }
                      userInfo={{
                        name: this.state.firstName + " " + this.state.lastName,
                        address:
                          this.state.address +
                          "," +
                          this.state.city +
                          "," +
                          this.state.province?.value +
                          "," +
                          this.state.postalCode,
                      }}
                    />
                  )}
                  {this.state.step === 5
                    ? this.state.business
                      ? this._renderLoginForm()
                      : this._renderEmailSinForm()
                    : null}
                  <div
                    style={{
                      borderColor: "#D8CBC7",
                      borderStyle: "solid",
                      borderWidth: "0.3px",
                      width: "100%",
                      height: 0,
                      marginTop: "35px",
                      marginBottom: "25px",
                    }}
                  />
                </CardHeader>
                <CardBody></CardBody>
              </Card>
            </Col>
            <Col xl="3" lg="2"></Col>
          </Row>
        )}
        {this.state.alert}
      </div>
    );
  }
}

export default CreateNewUser;
