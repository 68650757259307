// const removeUser = () => {
//   saveUserToLocalStorage({});
//   saveProfileToLocalStorage({});
// };

// const saveUserToLocalStorage = (user) => {
//   localStorage.setItem("agencyUser", JSON.stringify(user));
//   return user;
// };
// const saveProfileToLocalStorage = (user) => {
//   localStorage.setItem("agencyUserProfile", JSON.stringify(user));
//   return user;
// };

const saveAgencyToLocalStorage = (agency) => {
  localStorage.setItem("agency", JSON.stringify(agency));
  localStorage.setItem("agencyId", JSON.stringify(agency._id));
  return agency;
};

const agencyReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      // saveUserToLocalStorage(action.payload.user);
      //console.log(action);
      return { ...state, user: action.payload.user };
    case "UPDATE_USER":
      return { ...state, user: { ...state.user, ...action.payload.updates } };

    case "LOGOUT":
      // localStorage.setItem("Token", null);
      localStorage.removeItem("Token");
      return { ...state, user: null, profile: null, images: [] };
    case "UPDATE_IMAGES":
      return { ...state, images: [...state.images, ...action.payload.updates] };
    case "SET_USER_IMAGES":
      // saveUserToLocalStorage(action.payload.user);
      //console.log(action);
      return { ...state, images: action.payload.images };
    case "SET_PROFILE":
      // saveProfileToLocalStorage(action.payload.profile);
      return { ...state, profile: action.payload.profile };
    case "REMOVE_USER":
      //console.log("remove", state);
      // removeUser();
      return { ...state, user: null, profile: null, images: [] };
    case "SET_AGENCY":
      // console.log(action);
      saveAgencyToLocalStorage(action.payload.agency);
      return { ...state, agency: action.payload.agency };

    //reducer state for live shifts
    case "SET_LIVE_BUSINESSES":
      return { ...state, liveBusinesses: action.payload.liveBusinesses };

    case "SET_SELECTED_LIVE_BUSINESS":
      return {
        ...state,
        selectedLiveBusiness: action.payload.selectedLiveBusiness,
      };
    case "SET_SELECTED_LIVE_DATE":
      return { ...state, selectedLiveDate: action.payload.selectedLiveDate };
    default:
      return state;
  }
};

export default agencyReducer;
