import React, { useRef, useState, useEffect } from "react";
import { Button, Row, Col, FormText, Label, FormGroup } from "reactstrap";
import moment from "moment";
import SignatureCanvas from "react-signature-canvas";
import api from "components/API/api";
import PdfViewer from "./PdfViewer.jsx";
import { AGENCY_INFO } from "AgencyInfo.js";

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

function EmploymentAgreement({
  handleNextStep,
  handlePrevStep,
  userInfo,
  handleSubmit,
}) {
  const signaturePad = useRef();
  const [signature, setSignature] = useState(null);
  const [rawSignature, setRawSignature] = useState(null);
  const [errorSignature, setErrorSignature] = useState(null);
  const [displayPDF, setDisplayPDF] = useState(false);
  const [pdf, setPdf] = useState(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  const clearSignature = () => {
    signaturePad.current.clear();
    setSignature(null);
  };

  const saveSignature = () => {
    let signature = signaturePad.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    setRawSignature(signature);
    setSignature(dataURLtoFile(signature, "signature"));
  };

  // submit the employment agreement
  const handleSubmitAgreement = async () => {
    try {
      const formData = new FormData();
      formData.append("signature", signature);
      formData.append("name", userInfo.name);
      formData.append("address", userInfo.address);

      const res = await api().post(`employment-agreement`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        responseType: "arraybuffer",
      });
      setDisplayPDF(true);
      const blob = new Blob([res.data], { type: "application/pdf" });
      setPdf(blob);
    } catch (error) {
      console.log(error);
    }
  };

  const createAccount = () => {
    //send signature and pdf to save to server
    console.log("here");
    handleSubmit(rawSignature, pdf);
  };

  const handleBack = () => {
    setDisplayPDF(false);
  };

  return displayPDF ? (
    <PdfViewer pdf={pdf} submit={createAccount} handleBack={handleBack} />
  ) : (
    <FormGroup>
      <Row
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          margin: 15,
        }}
      >
        <Row style={{ alignItems: "center" }}>
          <Col style={{ color: "#faa632", fontWeight: "bold" }}>
            EMPLOYMENT AGREEMENT
          </Col>
        </Row>

        <Row
          style={{
            margin: 10,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Row style={{ margin: 10, flexDirection: "column" }}>
            <Col>
              THIS AGREEMENT made with effect as of{" "}
              {moment().format("MMM DD YYYY")} .
            </Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>BETWEEN:</Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                [{AGENCY_INFO.name}]
              </Col>
            </Row>

            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                (the “Company”)
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>- and - </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                {userInfo.name}
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                {userInfo.address}
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                (the “Employee”)
              </Col>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col>
              <strong>WHEREAS</strong> the Employer wishes to employ the
              Employee on the terms and conditions set forth in this Agreement,
              and the Employee wishes to be employed by the Employer on such
              terms and conditions;
            </Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>NOW THEREFORE </strong> in consideration for the mutual
                covenants and promises set forth herein, and other good and
                valuable consideration, the receipt and sufficiency of which are
                hereby acknowledged, the parties hereby agree as follows:
              </Col>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col
              style={{
                fontWeight: "bold",
                color: "#faa632",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              Position
            </Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                1. Effective {moment().format("MMM DD YYYY")}, the Employee will
                be employed by the Company as a temporary assignment employee,
                reporting to <strong>Supervisor</strong>.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                2. The Company will assign or attempt to assign the Employee to
                perform work on a temporary basis for one or more client(s) of
                the Company (the “Client”).
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                3. The Company shall be under no obligation to provide the
                Employee with a particular work assignment or work for any
                minimum number of hours or at any minimum rate of pay, except as
                required by the Employment Standards Act, 2000, as amended (the
                “ESA”).
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                4. When an assignment is offered, the Company will advise the
                Employee of the estimated term of the assignment.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                5. While on a temporary work assignment, the Employee will
                perform the duties normally associated with the position of the
                Employee’s work assignment, as well as duties that may be
                assigned by the Company from time to time. The Employee will be
                required to report to and take direction from representatives of
                the Client. In the course of the Employee’s employment and while
                on a temporary assignment, the Employee agrees to comply with
                all applicable laws, by-laws, rules and regulations, including
                those established by the Company or the Client.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                6. The Employee acknowledges and agrees that the Company may
                place the Employee on temporary lay-off or unpaid leave of
                absence at any time in accordance with the provisions of the
                ESA.
              </Col>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col
              style={{
                fontWeight: "bold",
                color: "#faa632",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              Compensation
            </Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                7. The Company will pay the Employee for work performed as a
                temporary assignment employee at the rate of pay set out in the
                offer of temporary work assignment.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                8. The Employee will be entitled to overtime, public holiday
                pay, and other statutory entitlements only as provided under the
                ESA.
              </Col>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col
              style={{
                fontWeight: "bold",
                color: "#faa632",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              Termination of Assignment
            </Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                9. The Company may terminate a temporary work assignment at any
                time by providing the Employee with only the amount of notice
                required under the ESA.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                10. If the Employee is assigned a temporary work assignment for
                a period lasting more than four (4) weeks, the Employee may
                terminate the temporary work assignment by providing two (2)
                weeks’ advance written notice to the Company.
              </Col>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col
              style={{
                fontWeight: "bold",
                color: "#faa632",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              Termination of Employment
            </Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                11. The Company may terminate the Employee’s employment at any
                time by providing the Employee with the minimum amount of notice
                (or pay in lieu of notice) and severance pay required by the ESA
                in full satisfaction of all notice and severance obligations. If
                applicable, the Employee’s participation in all benefit plans
                provided to the Employee by the Company immediately prior to the
                termination of the Employee’s employment will be continued for
                only the applicable minimum statutory notice period as required
                by and in accordance with the ESA.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                12. The Employee may terminate their employment with the Company
                by providing two (2) weeks’ advance written notice to the
                Company.
              </Col>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col
              style={{
                fontWeight: "bold",
                color: "#faa632",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              Company Policies
            </Col>
            <Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  13. The Employee will be required to comply with all policies
                  and procedures of the Company as they may be implemented from
                  time to time.
                </Col>
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col
              style={{
                fontWeight: "bold",
                color: "#faa632",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              Confidentiality
            </Col>
            <Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  14. In the course of the Employee’s employment with the
                  Company, the Employee will have access to, and be entrusted
                  with, confidential and proprietary information relating to the
                  business of the Company and its Clients, including customer
                  and Client information, employee information, business plans,
                  marketing plans, financial information, product specifications
                  and processes, techniques, methods, procedures, systems,
                  instructions, and specialized know-how (the “Confidential
                  Information”). The Employee agrees, during the term of the
                  Employee’s employment and afterwards for as long as such
                  information is not generally available to the public, not to
                  use or disclose such Confidential Information for any purpose
                  other than in the proper exercise of the Employee’s duties for
                  the Company and its Clients, or when required by law.
                </Col>
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col
              style={{
                fontWeight: "bold",
                color: "#faa632",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              Ownership of Work
            </Col>
            <Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  15. In the course of the Employee’s employment for the
                  Company, the Employee may create or contribute to the creation
                  of work which may include written or electronic materials,
                  software, hardware, equipment, devices, specifications,
                  designs or processes (the “Work”). All such Work shall be the
                  property of the Company or the Client to whom the Employee is
                  assigned to provide services. The Employee agrees to execute
                  any documents required to assign all legal right, title and
                  interest in the Work to the Company or Client and hereby
                  irrevocably waives any moral rights that the Employee may have
                  in the Work. The Employee agrees to fully, freely, promptly
                  and without expense to the Company, communicate to the Company
                  all discoveries, inventions, or improvements in any way
                  related to the formulae, processes, procedures, goods,
                  methods, systems or know-how of the Company that the Employee
                  makes at any time during, or as a result of, the Employee’s
                  employment with the Company.
                </Col>
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col
              style={{
                fontWeight: "bold",
                color: "#faa632",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              Company and Client Property
            </Col>
            <Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  16. Upon leaving a temporary work assignment with a Client or
                  the Employee’s employment with the Company, at any time and
                  for any reason, the Employee must return any Client or Company
                  property, as applicable, in the Employee’s possession or
                  control, including all media or records, correspondence,
                  memoranda, drawings, pictures, reports, and any physical
                  evidence of any reports, analysis, designs, systems or
                  programs, whether in written or electronic form, without
                  keeping any copies thereof.
                </Col>
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col
              style={{
                fontWeight: "bold",
                color: "#faa632",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              Conditions of Employment
            </Col>
            <Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  17. The Company’s offer of employment under this Agreement is
                  conditional on the Employee confirming their eligibility to
                  work in Canada and on the Company being satisfied with any
                  reference or pre-hire background checks (including a criminal
                  background check, which confirms that the Employee has never
                  been convicted of a federal criminal offence for which the
                  Employee has not been pardoned) being conducted on the
                  Employee.
                </Col>
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col
              style={{
                fontWeight: "bold",
                color: "#faa632",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              General
            </Col>
            <Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  18. The Company collects, uses and discloses (to authorized
                  parties) personal information as required for the purpose of
                  administering the employment relationship, including
                  determining benefits eligibility, if any. The Company may
                  disclose this type of information to third parties for these
                  purposes, or as otherwise required by law. Personal
                  information includes information about the Employee that the
                  Employee provided on their application form and resume, and
                  during the Employee’s job interviews. It also includes
                  information about the Employee’s dependents, wage and
                  benefits, performance reviews, information collected during
                  investigations, and medical information in the case of illness
                  or absence. By accepting this offer, the Employee consents to
                  the collection, use, and disclosure of such personal
                  information for the purposes of administering the employment
                  relationship, as may be required from time to time.
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  19. This Agreement constitutes the entire agreement between
                  the Employee and the Company with respect to the Employee’s
                  employment and cancels and supersedes any prior understandings
                  and agreements between the Employee and the Company with
                  respect to the Employee’s employment. [
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  20. If any provision of this Agreement is determined to be
                  invalid or unenforceable in whole or in part, such invalidity
                  or unenforceability will attach only to such provision or part
                  of such provision and the remaining part of such provision and
                  all other provisions of this Agreement will continue in full
                  force and effect.
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  21. For greater certainty, should any provision of this
                  Agreement provide entitlements to the Employee that are less
                  than the Employee’s entitlements under the ESA, the
                  entitlements under the ESA shall prevail.
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  23. The Employee acknowledges that the Employee has had a
                  reasonable opportunity to obtain independent legal advice
                  regarding this Agreement, and that the Employee has reviewed
                  and understands its terms.
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  24. This Agreement may be signed in one or more counterparts,
                  each of which so signed shall be deemed to be an original, and
                  such counterparts together shall constitute one and the same
                  instrument. Delivery of an executed counterpart of this
                  Agreement by electronic means (including by submission through
                  the Company’s online employee registration portal) shall be
                  equally effective as delivery of a manually executed
                  counterpart hereof.
                </Col>
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Row
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  THIS AGREEMENT has been signed by the Employee on
                  {" " + moment().format("MMMM DD YYYY")} .
                </Col>
              </Row>

              <Label sm="2"></Label>
              <Row
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Col sm="8">
                  <SignatureCanvas
                    penColor="orange"
                    ref={signaturePad}
                    canvasProps={{
                      className: "signatureCanvas",
                    }}
                    onEnd={() => {
                      saveSignature();
                    }}
                    id="signature"
                  />
                  <Row>
                    <Col style={{ marginTop: 4, marginBottom: 4 }}>
                      (Input signature here)
                    </Col>
                  </Row>
                  <FormText color="danger" tag="span">
                    {errorSignature}
                  </FormText>
                </Col>
              </Row>
              <Row>
                <Col xs="8">
                  <Button color="secondary" onClick={clearSignature}>
                    Clear Signature
                  </Button>
                </Col>
              </Row>
            </Row>
          </Row>
        </Row>
      </Row>
      <Row
        style={{
          marginBottom: 10,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Col xs="8" style={{ fontSize: 16, marginBottom: 4 }}>
          {AGENCY_INFO.name}
        </Col>

        <Col xs="8">Name: {AGENCY_INFO.CEOName}</Col>

        <Col xs="8">Title: CEO</Col>

        <Col xs="8" style={{ fontSize: 12 }}>
          I have the authority to bind the Company
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col xs="6" align="left">
          <Button onClick={() => handlePrevStep()}>back</Button>
        </Col>
        <Col xs="6" align="right">
          <Button
            color="success"
            onClick={() => {
              if (!signature) {
                setErrorSignature("Please enter the signature");
                return;
              }
              handleSubmitAgreement();
            }}
          >
            Next
          </Button>
        </Col>
      </Row>
    </FormGroup>
  );
}

export default EmploymentAgreement;
