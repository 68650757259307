import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Button,
  CardImg,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody,
  CardHeader,
  Collapse,
  Row,
  Col,
} from "reactstrap";
import moment from "moment";
import CustomMonthPicker from "components/Calendar/CustomMonthPicker";
import AgencyContext from "context/AgencyContext";
import { useHistory, useParams } from "react-router-dom";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import api from "components/API/api";
import {
  LocationCity,
  Business,
  LocationOn,
  Schedule,
} from "@material-ui/icons";
const isBetween = function (start, end, date) {
  return date >= start && date < end;
};

const Module = function ({ shift, currentDate }) {
  const history = useHistory();
  const context = useContext(AgencyContext);

  return (
    <Card className="no-transition" style={{ backgroundColor: "#ececec" }}>
      <Row>
        <Col
          lg="1"
          md="2"
          sm="2"
          className="d-flex align-items-center justify-content-center"
        >
          <h6>{moment(shift.day).format("ddd  DD")} </h6>
        </Col>
        <Col lg="11" md="10" sm="10">
          {shift.data.map((work) => {
            return (
              <Card
                className="no-transition m-1"
                style={{ backgroundColor: "#fff" }}
              >
                <CardBody>
                  <Row>
                    <Col lg="4" md="4" sm="6" xs="12">
                      <div>
                        <CardTitle tag="h6">
                          <Schedule />
                          {moment(work.start).format("hh:mm a")}
                          {" - "}
                          {moment(work.end).format("hh:mm a")}
                        </CardTitle>
                      </div>
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="12">
                      <Business />

                      <span style={{ flex: 3 }}>{work.businessName}</span>
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="12">
                      <LocationOn />

                      <span style={{ flex: 3 }}>{work.businessLocation}</span>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            );
          })}
        </Col>
      </Row>
    </Card>
  );
};

export default function UpComingShifts(props) {
  //const { businessId } = useParams();
  const history = useHistory();
  const [shifts, setShifts] = useState([]);
  const context = useContext(AgencyContext);
  //   const selectedBusiness = context.shift.selectedBusiness;
  const agency = context.agency.agency;
  const [date, setDate] = useState(
    context.agency.selectedLiveDate || new Date()
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // if (!selectedBusiness) {
    // history.push("/admin/all-shift");
    // } else {
    if (agency) {
      loadLiveBusinesses(agency, date);
    }
    // }
    //get the profile type and set the profile type in context as well.
  }, [agency]);
  const loadLiveBusinesses = function (agency, date) {
    setIsLoading(true);
    let from = new Date(moment(date).startOf("month")),
      till = new Date(moment(date).endOf("month"));
    api()
      .get("/selected-for-work/user", {
        params: { from, till, agency: agency._id },
      })
      .then((res) => {
        setIsLoading(false);
        if (res.data.history && res.data.history.length <= 0) {
          setShifts([]);
          return;
        }
        let history =
          res.data.history &&
          res.data.history.map((data) => {
            return {
              _id: data._id,
              start: data.shift && data.shift.start,
              end: data.shift && data.shift.end,
              actualStart: data.actualStartTime,
              actualEnd: data.actualEndTime,
              payRate: data.shift && data.shift.payRate,
              payBasis: data.shift && data.shift.payBasis,
              estimatedPayDate: data.shift?.estimatedPayDate,
              description: data.shift?.description,
              businessLocation: data.shift?.businessLocation,
              businessName: data.shift && data.shift?.businessName,
              duration:
                data.shift &&
                data.shift.start &&
                data.shift.end &&
                moment(data.shift.end).diff(moment(data.shift.start), "hours"),
            };
          });

        let newHistory =
          history &&
          history.length > 0 &&
          history.sort((d1, d2) => {
            return moment(d1.start).isBefore(d2.start) ? -1 : 1;
          });

        //getting the date
        let allData = newHistory.map((d) => {
          d.day = moment(d.start).format("YYYY-MM-DD");
          return d;
        });

        let currentHistory = [];

        allData.reduce((groups, item) => {
          let val = item["day"]; // 5
          if (groups[val]) {
            groups[val] = groups[val];
            let index = currentHistory.findIndex((h) => {
              return h.day == val;
            });
            if (index > 0) {
              currentHistory[index].data.push(item);
            }
            groups[val].push(item);
          } else {
            currentHistory.push({ day: val, data: [item] });
            groups[val] = [item];
          }
          return groups;
        }, {});
        setShifts(currentHistory);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  return (
    <div className="content">
      <Row>
        <Col>
          <Card className="no-transition">
            <CardHeader>
              <div className="d-flex justify-content-between">
                <CardTitle tag="h4">My Scheduled Work History</CardTitle>
              </div>
              <p style={{ fontWeight: "bold" }}>
                Please note, this is only your scheduled work history. Your
                actual work history may be different.
              </p>
              <CustomMonthPicker
                initialDate={date}
                onChangeDate={(date) => {
                  loadLiveBusinesses(agency, date);
                }}
              />
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <LoadingOverlay />
              ) : (
                <Row>
                  {shifts.length > 0 ? (
                    shifts.map((shift, i) => (
                      <Col xl="12" lg="12" md="12" sm="12">
                        <Module
                          shift={shift}
                          key={i}
                          index={i}
                          // currentDate={date}
                        />
                      </Col>
                    ))
                  ) : (
                    <p>No work done during this month.</p>
                  )}
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
