/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import RegistrationContext from "context/RegistrationContext";
import {
  validateEmail,
  validateSin,
  getCurrentDate,
} from "components/Utilities/utilities.js";
// import { firebase, fs, store } from "components/Firebase/firebase.js"
import { Redirect } from "react-router-dom";
import MaskedInput from "react-text-mask";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  FormText,
  Form,
  Input,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import api from "../../components/API/api";
import { AGENCY_INFO } from "AgencyInfo";

class WaitInvite extends React.Component {
  render() {
    return (
      <div className="login-page">
        <Row zindex={1}>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form>
              <Card className="card-login">
                <CardHeader
                  style={{ alignItems: "center", textAlign: "center" }}
                >
                  <img
                    src="https://i0.wp.com/hydrasecurity.ca/wp-content/uploads/2022/04/Hydra-logo-full-100H.png?w=420&ssl=1"
                    alt={AGENCY_INFO.name}
                  ></img>
                  <br></br>
                  <CardTitle tag="h4">Registration complete</CardTitle>
                </CardHeader>
                <CardBody>
                  <p>
                    Your data has been received, you will soon receive an email
                    with instructions to continue the training process. Thank
                    you for registering.
                  </p>
                  <Row>
                    <Col align="center">
                      <Button
                        color="success"
                        onClick={() => {
                          let slug = this.props.match.params.slug;
                          this.props.history.push(
                            `/${slug}/register/continue-training`
                          );
                        }}
                      >
                        I have the code
                      </Button>
                    </Col>
                  </Row>
                  <p style={{ textAlign: "center" }}>
                    <a href={`/${this.props.match.params.slug}/register`}>
                      Back
                    </a>
                  </p>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

export default WaitInvite;
