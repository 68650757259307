import React, { useState, useEffect, useContext } from "react";
import RegistrationContext from "../../../context/RegistrationContext";
import {
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Row,
  Col,
  Alert,
  ModalHeader,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import TrainingContext from "context/TrainingContext";

const MAX_WRONG_ANSWERS = 3;

const shouldDisplayWrongAnswerMessage = (section) =>
  section.quiz.userAnswer &&
  section.quiz.userAnswer !== section.quiz.correctAnswer &&
  section.attempts < MAX_WRONG_ANSWERS;
const shouldDisplayCorrectAnswer = (section) =>
  section.quiz.userAnswer &&
  section.quiz.userAnswer !== section.quiz.correctAnswer &&
  section.attempts >= MAX_WRONG_ANSWERS;

const WrongMessageAlert = ({ section, toggle, modal }) => {
  const { slug } = useParams();
  return (
    <Modal toggle={toggle} isOpen={modal}>
      <ModalHeader>Wrong answer</ModalHeader>
      <ModalBody>
        {shouldDisplayWrongAnswerMessage(section)
          ? "Wrong answer, please check your answer."
          : section.quiz.explanation}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle} style={{ marginRight: 12 }}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

function TrainingShortTextAnswer(props) {
  const { slug } = useParams();
  const { trainingDispatch } = useContext(TrainingContext);
  const [answer, setAnswer] = useState(null);
  const [modal, setModal] = useState(false);
  const { section, history } = props;

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (shouldDisplayCorrectAnswer(section)) {
      setAnswer(section.quiz.correctAnswer);
    } else {
      setAnswer(section.quiz.userAnswer);
    }
  }, [section]);

  return (
    <>
      <h5>
        <span
          dangerouslySetInnerHTML={{
            __html: section.quiz.question.split("\n").join("<br/>"),
          }}
        />
      </h5>
      <WrongMessageAlert section={section} modal={modal} toggle={toggle} />
      <Input
        type={section.type === "shortNumberAnswer" ? "number" : "text"}
        onChange={(e) => setAnswer(e.target.value)}
        value={answer}
      />
      <Row>
        <Col>
          <Link to={`/${slug}/register/training`}>
            <Button style={{ marginTop: 16 }}>Back to menu</Button>
          </Link>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <Button
            style={{ marginTop: 16 }}
            color={"primary"}
            disabled={answer ? false : true}
            onClick={(e) => {
              trainingDispatch({
                type: "SET_ANSWER",
                sectionId: section.id,
                answer,
              });
              if (
                isNaN(section.quiz.correctAnswer)
                  ? section.quiz.userAnswer.toLowerCase() ===
                    section.quiz.correctAnswer.toLowerCase()
                  : section.quiz.userAnswer === section.quiz.correctAnswer
              ) {
                setAnswer("");
                // history.push(`/${slug}/register/training/${section.id + 1}`);
                trainingDispatch({
                  type: "SET_SELECTED_INDEX",
                  _index: props.index + 1,
                });
              } else {
                toggle();
              }
            }}
          >
            {props.lastModule ? "Finish" : "Next"}
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default TrainingShortTextAnswer;
