import { AGENCY_INFO } from "AgencyInfo";
import axios from "axios";

export const baseURL =
  process.env.NODE_ENV === "production"
    ? `https://staffing-node.herokuapp.com/`
    : "http://localhost:8000";

const api = (
  config = { headers: { client: "browser" } },
  token = JSON.parse(localStorage.getItem("Token"))
) => {
  //const config = {};
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: "Bearer " + token,
      AgencyId: localStorage.getItem("AgencyId"),
    };
  }
  return axios.create({
    baseURL,
    ...config,
  });
};

export default api;
