import React from "react";
import { useContext } from "react";
import RegistrationContext from "context/RegistrationContext";
import {
  Card,
  CardBody,
  Container,
  CardHeader,
  Row,
  Col,
  Button,
  Spinner,
} from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import TrainingMultipleChoice from "./components/TrainingMultipleChoice";
import TrainingShortTextAnswer from "./components/TrainingShortTextAnswer";
import Terms from "./components/Terms";
import TrainingVideo from "./components/TrainingVideo";
import TrainingCommunicationAnswer from "./components/TrainingCommunicationAnswer";
import { useParams } from "react-router-dom";
import TrainingContext from "context/TrainingContext";
import api from "components/API/api";
import AgencyContext from "context/AgencyContext";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import useUnload from "components/Hooks/useUnload";
const ONLOAD_MESSAGE = `Training must be completed for us to send you to work. If you do not complete now, you must complete at a later time by logging into your account from the login button on the top right corner.`;

const RenderSection = (props) => {
  const { section, history } = props;
  if (!section.type) {
    return null;
  }

  switch (section.type) {
    case "video":
      return <TrainingVideo {...props} slug={props.slug} />;
    case "multipleChoice":
      return <TrainingMultipleChoice {...props} slug={props.slug} />;
    case "shortTextAnswer":
    case "shortNumberAnswer":
      return <TrainingShortTextAnswer {...props} slug={props.slug} />;
    case "shortCommunicationAnswer":
      return <TrainingCommunicationAnswer {...props} slug={props.slug} />;
    case "terms":
      return <Terms {...props} slug={props.slug} />;
    case "signature":
    // return <Signature section={section} navigation={navigation} />
    default:
      history.push(`/${props.slug}/register/training`);
      return null;
  }
};

export default function TrainingSection(props) {
  const { registration, registrationDispatch } = useContext(
    RegistrationContext
  );
  const { training, trainingDispatch } = useContext(TrainingContext);

  const { agency } = useContext(AgencyContext);
  const { history } = props;

  const [section, setSection] = useState({});
  let sectionId = props.match.params.id;
  // const { slug } = useParams();
  let slug = agency?.agency?.slug;

  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(false);

  const sectionIsLocked = (section, index, currentTraining) => {
    return section.done
      ? false
      : index === 0
      ? false
      : currentTraining.content[index - 1].isCorrect
      ? false
      : currentTraining.content[index - 1].done
      ? false
      : true;
  };

  useUnload((e) => {
    e.preventDefault();
    e.returnValue = { ONLOAD_MESSAGE };
    setAlert(
      <SweetAlert
        warning
        // title={`Are you sure you want to `}
        onConfirm={() => {
          setAlert(null);
        }}
      >
        {ONLOAD_MESSAGE}
      </SweetAlert>
    );
  });

  const getTrainingFromIndex = (trainingList, trainingId) => {
    return training.trainingList.find(
      (t) => t._id === training.selectedTraining.trainingId
    );
  };
  useEffect(() => {
    if (!training.selectedTraining.trainingId) {
      history.push(`/${slug}/my-trainings`);
      return;
    }
    let currentTraining = getTrainingFromIndex(
      training.trainingList,
      training.selectedTraining.trainingId
    );

    //if all the content has been done and nothing left then set the current training to done.
    if (training.selectedTraining.index >= currentTraining.content.length) {
      let today = new Date();
      let expires = new Date();
      expires.setDate(today.getDate() + currentTraining.expire);
      setIsLoading(true);

      let userId =
        (registration.user && registration.user._id) ||
        (agency.user && agency.user._id);
      console.log("training completed");
      api()
        .post("/training-history", {
          training: currentTraining._id,
          user: userId,
          expires: expires,
          completedDate: new Date(),
          completed: true,
        })
        .then((resp) => {
          setIsLoading(false);

          if (training.trainingList && training.trainingList.length === 1) {
            setAlert(
              <SweetAlert
                success
                title={`Training Completed!`}
                onConfirm={() => {
                  props.history.push(`/${slug}/upcoming-shifts`);

                  trainingDispatch({ type: "RESET" });
                }}
              >
                You have successfully completed the trainings. You may close
                this window
              </SweetAlert>
            );
          } else {
            trainingDispatch({
              type: "SET_TRAINING_DONE",
            });
            history.push(`/${slug}/my-trainings`);
          }
        })
        .catch((e) => {
          setIsLoading(false);
          console.log("couldn't complete the training", e);
          history.push(`/${slug}/my-trainings`);
        });
    } else {
      let newSection = currentTraining.content[training.selectedTraining.index];

      if (
        newSection &&
        !sectionIsLocked(
          newSection,
          training.selectedTraining.index,
          currentTraining
        )
      ) {
        setSection({ ...newSection });
      } else {
        history.push(`/${slug}/my-trainings`);
      }
    }
  }, [training.selectedTraining]);

  return (
    <div className="content">
      <Row style={{ fontWeight: 400 }}>
        <Col>
          {/* <Container> */}
          {isLoading ? (
            <Card style={{ marginTop: 40 }} className="no-transition">
              <CardHeader>
                <h4 style={{ marginTop: 0 }}>
                  You have successfully completed the training. Please wait
                  while we are updating it.
                </h4>
              </CardHeader>
              <Spinner
                color="primary"
                style={{
                  width: "3rem",
                  height: "3rem",
                  margin: "3rem auto",
                }}
              />
            </Card>
          ) : (
            <Card style={{ marginTop: 20 }} className="no-transition">
              {/* <Container> */}
              <CardHeader>
                <h4 style={{ marginTop: 0 }}>{section.title}</h4>
                <hr />
              </CardHeader>
              <CardBody>
                <RenderSection
                  section={section}
                  sectionId={section._id}
                  slug={slug}
                  index={training.selectedTraining.index}
                  lastModule={
                    training.selectedTraining.index ===
                    (getTrainingFromIndex() &&
                      getTrainingFromIndex().content.length - 1)
                  }
                  {...props}
                />
              </CardBody>
              {/* </Container> */}
            </Card>
          )}
          {alert}
          {/* </Container> */}
        </Col>
      </Row>
    </div>
  );
}
