import React, { useReducer, useState, useContext, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import CreateNewUser from "views/Registration/CreateNewUser.jsx";
import TrainingMenu from "views/Registration/TrainingMenu.jsx";
import TrainingSection from "views/Registration/TrainingSection.jsx";
import ContinueTraining from "views/Registration/ContinueTraining.jsx";

import RegisterContext from "context/RegistrationContext";
import AgencyContext from "context/AgencyContext";
// import registrationReducer from "reducers/registrationReducer";
// import trainingReducer from "reducers/trainingReducer";
// import Progress from "components/ProgressBar/StepProgressBar.js";
import Error404 from "views/pages/Error404";
// import TRAINING_DATA from "constants/training";
import TRAINING_LIST from "constants/trainingList";
import RegisterNavbar from "components/Navbars/RegisterNavbar.jsx";

import { baseURL } from "components/API/api";
import WaitInvite from "../views/Registration/WaitInvite";
import { AGENCY_INFO } from "AgencyInfo";


function Admin(props) {
  const agencyContext = useContext(AgencyContext);
  const { registration, registrationDispatch } = useContext(RegisterContext);

  useEffect(() => {
    registrationDispatch({
      type: "SET_AGENCY",
      agency: agencyContext.agency.agency,
    });
  }, [agencyContext.agency.agency]);

  // let logo =
  //   agencyContext.agency &&
  //   agencyContext.agency.agency &&
  //   agencyContext.agency.agency._id
  //     ? `${baseURL}/agency/${agencyContext.agency.agency._id}/logo`
  //     : null;
  return (
    // <RegisterContext.Provider value={{ registration, registrationDispatch }}>
    <>
      <div style={{ paddingBottom: 120 }}>
        {agencyContext.agency.user ? null : (
          <RegisterNavbar
            logo={AGENCY_INFO.logo}
            companyName={
              agencyContext.agency.agency && agencyContext.agency.agency.name
            }
          />
        )} 
      </div>
      <Switch>
        <Route
          exact
          path="/:slug/register/training"
          render={(props) => <TrainingMenu {...props} />}
        />
        <Route
          path="/:slug/register/training/module"
          render={(props) => <TrainingSection {...props} />}
        />
        <Route
          exact
          path="/:slug/register/continue-training"
          render={(props) => <ContinueTraining {...props} />}
        />
        <Route
          exact
          path="/:slug/register/wait-for-invite"
          render={(props) => <WaitInvite {...props} />}
        />
        <Route
          exact
          path="/:slug/register"
          render={(props) => <CreateNewUser {...props} />}
        />
        <Route render={(props) => <Error404 {...props} />} />
      </Switch>
      <div className="text-center">
        <h6>
          © {new Date().getFullYear()}, made with{" "}
          <i className="fa fa-heart heart" />
          By Omadatek
        </h6>
      </div>
    </>
  );
}

export default Admin;
