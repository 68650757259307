// import TRAINING_DATA from "constants/training";

const setAnswer = (state, answer) => {
  let trainingList = state.trainingList;
  let training_id = state.selectedTraining.trainingId,
    index = state.selectedTraining.index;
  return trainingList.map((training) => {
    if (training._id === training_id) {
      return {
        ...training,
        content: training.content.map((section, i) => {
          if (i === index) {
            let finalAnswer = (section.quiz.userAnswer = answer
              .toString()
              .toLowerCase());
            if (finalAnswer == section.quiz.correctAnswer) {
              section.done = true;
              section.isCorrect = true;
            } else {
              section.done = true;
              section.isCorrect = false;
            }
            section.attempts++;
          }
          return section;
        }),
      };
    }
    return training;
  });

  // return saveStateToLocalStorage(
  //   state.map((section) => {
  //     if (section.id == sectionId) {
  //       let finalAnswer = (section.quiz.userAnswer = answer
  //         .toString()
  //         .toLowerCase());
  //       if (finalAnswer == section.quiz.correctAnswer) {
  //         section.done = true;
  //         section.isCorrect = true;
  //       } else {
  //         section.done = true;
  //         section.isCorrect = false;
  //       }
  //       section.attempts++;
  //     }
  //     return { ...section };
  //   }),
  // );
};

const setCommunicationAnswer = (state, answer) => {
  let trainingList = state.trainingList;
  let training_id = state.selectedTraining.trainingId,
    index = state.selectedTraining.index;

  return trainingList.map((training) => {
    if (training._id === training_id) {
      return {
        ...training,
        content: training.content.map((section, i) => {
          if (i === index) {
            let finalAnswer = (section.quiz.userAnswer = answer
              .toString()
              .toLowerCase());
            section.quiz.correctAnswer = finalAnswer;
            section.done = true;
            section.isCorrect = true;
            section.attempts++;
          }

          return section;
        }),
      };
    }
    return training;
  });
};

const setDone = (state) => {
  let trainingList = state.trainingList;
  let training_id = state.selectedTraining.trainingId,
    index = state.selectedTraining.index;

  //return trainingList;
  return trainingList.map((training) => {
    if (training._id === training_id) {
      return {
        ...training,
        content: training.content.map((section, i) => {
          if (i === index) {
            section.done = true;
          }

          return section;
        }),
      };
    }
    return training;
  });

  // return saveStateToLocalStorage(
  //   state.map((section) => {
  //     if (section.id === sectionId) {
  //       section.done = done;
  //       section.isCorrect = done;
  //     }
  //     return { ...section };
  //   }),
  // );
};

// const setTrainingDone = (state) => {
//   let trainingList = state.trainingList;
//   let training_id = state.selectedTraining.trainingId;
//   console.log("set training done");
//   return trainingList.map((training) => {
//     if (training._id === training_id) {
//       return {
//         ...training,
//         done: true,
//       };
//     }
//     return training;
//   });

//   // return saveStateToLocalStorage(
//   //   state.map((section) => {
//   //     if (section.id === sectionId) {
//   //       section.done = done;
//   //       section.isCorrect = done;
//   //     }
//   //     return { ...section };
//   //   }),
//   // );
// };

const setTrainingDone = (state) => {
  let trainingList = state.trainingList;
  let training_id = state.selectedTraining.trainingId;
  console.log("set training done");
  return trainingList.filter((training) => {
    if (training._id !== training_id) {
      return true;
    }
  });
};

const saveStateToLocalStorage = (state) => {
  localStorage.setItem("training", JSON.stringify(state));
  return state;
};

const trainingReducer = (state, action) => {
  switch (action.type) {
    case "SET_ANSWER":
      return {
        ...state,
        trainingList: setAnswer(state, action.answer),
      };
    case "SET_COMMUNICATION_ANSWER":
      return {
        ...state,
        trainingList: setCommunicationAnswer(state, action.answer),
      };
    case "SET_DONE":
      return {
        ...state,
        trainingList: setDone(state),
      };
    case "RESET":
      return {
        ...state,
        trainingList: [],
      };
    case "SET_ALL_TRAINING_DATA":
      return { ...state, trainingList: action.trainingList };
    case "SET_SELECTED_TRAINING":
      return {
        ...state,
        selectedTraining: { trainingId: action._id, index: action._index },
      };
    case "SET_SELECTED_INDEX":
      return {
        ...state,
        selectedTraining: {
          ...state.selectedTraining,
          index: action._index,
        },
      };
    case "SET_TRAINING_DONE":
      return {
        ...state,
        trainingList: setTrainingDone(state),
      };
    default:
      return state;
  }
};

export default trainingReducer;
