/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import UpcomingShifts from "views/AllShifts/UpcomingShifts.jsx";
import WorkHistory from "views/AllShifts/WorkHistory.jsx";
import ChequeIssues from "views/CheckPickup/ChequeIssues";
import ChequePickup from "views/CheckPickup/ChequePickup";
import ChequeProcessing from "views/CheckPickup/ChequeProcessing";
import Login from "views/pages/Login.jsx";
// import CheckPickUpAppointment from "views/CheckPickup/checkPickUpAppointment.jsx";
import TrainingMenu from "views/Training/TrainingMenu";
import TrainingSection from "views/Training/TrainingSection";
// import TaxCalculator from "views/TaxCalculator/TaxCalculator.jsx";
// shift board
import AllAvailableShifts from "views/ShiftBoard/AllAvailableShifts";
import ShiftDetails from "views/ShiftBoard/ShiftDetails";

export const routes = [
  {
    collapse: true,
    name: "My Shifts",
    icon: "nc-icon nc-tv-2",
    state: "myShiftsCollapse",
    views: [
      {
        path: "/upcoming-shifts",
        mini: "All",
        name: "Upcoming Shifts",
        component: UpcomingShifts,
        hidden: false,
      },
      {
        path: "/work-history",
        name: "Scheduled Work History",
        mini: "All",
        component: WorkHistory,
        hidden: false,
      },
    ],
  },
  {
    collapse: true,
    name: "Shift Board",
    state: "shiftBoardCollapse",
    icon: "nc-icon nc-briefcase-24",
    views: [
      {
        path: "/shift-board",
        name: "All Shifts",
        mini: "All",
        component: AllAvailableShifts,
        hidden: false,
      },
      {
        path: "/shift-board/:id",
        mini: "All",
        name: "Shift Details",
        component: ShiftDetails,
        hidden: true,
      },
    ],
  },

  // {
  //   path: "/pay-calculator",
  //   name: "Tax Calculator",
  //   icon: "nc-icon nc-diamond",
  //   layout: "/admin",
  //   component: TaxCalculator,
  // },

  //DISABLED UNTIL WE GET THIS CORRECTLY WORKING
  // {
  //   collapse: true,
  //   name: "Appointments",
  //   icon: "nc-icon nc-calendar-60",
  //   state: "AppointmentCollapse",
  //   views: [
  //     {
  //       path: "/check_pickup",
  //       mini: "All",
  //       name: "Book an Appointment",
  //       component: CheckPickUpAppointment,
  //       hidden: false,
  //     },
  //   ],
  // },
  //Cheque pickup is moved inside logged
  {
    collapse: true,
    name: "Cheque Pickup",
    icon: "nc-icon nc-calendar-60",
    state: "AppointmentCollapse",
    views: [
      {
        path: "/cheque-pickup",
        mini: "All",
        name: "cheque Pickup",
        component: ChequePickup,
        hidden: false,
      },
      {
        path: "/cheque-issues",
        mini: "All",
        name: "Cheque Issues",
        component: ChequeIssues,
        hidden: false,
      },
      // {
      //   path: "/cheque-processing",
      //   mini: "All",
      //   name: "Cheque Processing",
      //   component: ChequeProcessing,
      //   hidden: false,
      // },
    ],
  },
  {
    collapse: true,
    name: "My Trainings",
    icon: "nc-icon nc-hat-3",
    state: "trainingCollapse",
    hidden: false,
    views: [
      {
        path: "/my-trainings",
        mini: "All",
        name: "Trainings",
        component: TrainingMenu,
        hidden: false,
      },
      {
        path: "/training/module",
        mini: "All",
        name: "Trainings",
        component: TrainingSection,
        hidden: true,
      },
    ],
  },

  // {
  //   path: "/qr-sign-off",
  //   name: "QR Sign-Off",
  //   icon: "nc-icon nc-diamond",
  //   layout: "/admin",
  //   component: QRSignOff,
  // },
  // {
  //   path: "/login",
  //   name: "Logout",
  //   icon: "nc-icon nc-minimal-left",
  //   layout: "/auth",
  //   component: Login,
  // },
];

export default routes;
