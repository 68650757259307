import { AGENCY_INFO } from "AgencyInfo";
import React, { useEffect } from "react";
import { Button, Row, Col, Label, FormGroup } from "reactstrap";

export default function PrivacyPolicy({ handleNextStep, handlePrevStep }) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <FormGroup>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          margin: 10,
        }}
      >
        <Row>
          <Col style={{ color: "#faa632", fontWeight: "bold" }}>
            {AGENCY_INFO.name} PRIVACY POLICY
          </Col>
        </Row>
        <Row style={{ alignItems: "center" }}>
          <Col>
            (<strong>Effective Date:</strong> September 28th, 2022) (
            <strong>Last modified:</strong> September 28th, 2022)
          </Col>
        </Row>

        <Row
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              1.INTRODUCTION
            </Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                Please read the below “<strong>Privacy Policy</strong>”
                carefully before downloading the {AGENCY_INFO.name} mobile
                application (which may also be referred to herein as the “
                <strong>App</strong>”), which is being made available to you by{" "}
                <strong>{AGENCY_INFO.name}</strong> (the “Company,” “{AGENCY_INFO.name},” “us”, “we”, or “our”) via the Apple App Store (for
                iOS devices) or Google Play (Android), or before using the{" "}
                {AGENCY_INFO.name}
                website (the “Site”), collectively referred to herein as the “
                <strong>Services</strong>.”
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                {AGENCY_INFO.name} respects your personal information (which
                means any information about you as an identifiable individual)
                and we are committed to protecting it through our compliance
                with this Policy and applicable law. This Privacy Policy
                describes:
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>{"\u2022" + " "}</strong> The types of personal
                information we collect from you and that you provide when you
                access or use the Company’s Services; and
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>{"\u2022" + " "}</strong> Our practices for collecting,
                using, and disclosing that information.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                We will only collect your personal information when we have your
                consent or as otherwise required or permissible by law.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                {AGENCY_INFO.name} uses the App to administer the employment
                relationship with you. We may make occasional updates to this
                policy, and will always have a current policy posted to our
                services.
              </Col>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              WHEN YOU APPLY FOR SHIFTS
            </Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                You understand that when you apply for employment opportunities
                via the App (“<strong>Shifts</strong>”), {AGENCY_INFO.name} will
                collect, use, and disclose your information in accordance with
                this Policy. Shifts may be posted by and for work with {AGENCY_INFO.name}
                 at {AGENCY_INFO.name} customers (“
                <strong>Customers</strong>”) or Shifts posted by third party
                companies (“
                <strong>Contractors</strong>”).
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                You understand that if you are using the Services to apply for
                Shifts with Contractors, those Contractors will have access to
                all relevant information you submit to {AGENCY_INFO.name} to
                assess suitability with those Contractor’s requirements (via an
                external service, “Digistaff”). If you do not wish Contractors
                to have access to your information, please do not use the App to
                apply for Contractor Shifts.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                Contractors may choose to have {AGENCY_INFO.name} administer
                aspects of your employment with them and in those cases you
                would use the App to sign in and out of Contractor Shifts. We do
                not control the privacy practices of Contractors.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                When you apply for Shifts with {AGENCY_INFO.name} we will share
                certain information with Customers that they require for you to
                be able to work at their sites.
              </Col>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              HOW WE COLLECT INFORMATION
            </Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                We collect personal information directly from you when you
                provide it to us during registration and certain information
                automatically when you use the App or the Site.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                We will only collect, use and disclose as much information as is
                reasonably necessary to meet the purposes for which it was
                collected.
              </Col>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              Information You Submit and That We Collect
            </Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                When you register for the Services, we will ask you to provide
                your first name, last name, telephone number, date of birth,
                information pertaining to past employment, and your email
                address. We may collect additional information from you that is
                necessary for the administration of the employment relationship
                with you, which may include all or some of the following: SIN,
                Vaccine status, mailing address, work availability, and proof of
                immigration/work permit documentation. We require all of this
                information to register, process and administer your employment
                with us.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                You will also be asked to create a username and password to
                access your account.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                We may also collect feedback about you from Customers regarding
                your performance (star rating system).
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                You may also, at your option, choose to provide us with
                additional information such as any other licenses or
                certifications you hold, your past employment history, interests
                or hobbies, and other relevant skills.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                We will not share your social insurance number or other
                information with Contractors or other third parties outside of
                what is necessary to administer your employment relationship
                with {AGENCY_INFO.name}.
              </Col>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              Tracking Technologies and Information Collected Automatically
            </Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                When you download, access, and use the App, it will use
                technology to automatically collect:
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>{"\u2022" + " "}</strong>
                <strong>Usage Details.</strong> When you access and use the App,
                we may automatically collect certain details of your access to
                and use of the App, including traffic data, location data, logs,
                and other communication data and the resources that you access
                and use on or through the App.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>{"\u2022" + " "}</strong>
                <strong>Device Information.</strong> We collect information
                about your mobile device and internet connection, including the
                device’s unique device identifier, IP address, operating system,
                browser type, mobile network information, and the device’s
                telephone number as well as device diagnostic data (crash data
                and performance data, for example).
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                When you use the Site or the App, the following additional
                information will be collected automatically:
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>{"\u2022" + " "}</strong> We use so-called “cookies,”
                web beacons, and other similar tracking technologies, including
                from third-party partners such as Google, to be able to better
                target advertisements and for marketing purposes.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>{"\u2022" + " "}</strong>These technologies allow us to
                display our promotional material to you on when you visit
                external sites.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                We may share information related to your access and engagement
                on our Site with certain third parties (including third-party
                websites or social networking platforms) for the purposes of
                providing you with better targeted advertisements.
              </Col>
            </Row>
          </Row>

          <Row
            style={{
              margin: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <Row
              style={{
                fontWeight: "bold",
                color: "#faa632",
                justifyContent: "center",
              }}
            >
              HOW WE USE YOUR INFORMATION
            </Row>

            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                We use your personal information:
              </Col>
            </Row>

            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>{"\u2022" + " "}</strong> To create an account for you.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>{"\u2022" + " "}</strong> To administer your employment
                relationship with {AGENCY_INFO.name}.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>{"\u2022" + " "}</strong> To provide you with the
                Services and to allow you to apply for Shifts with{" "}
                {AGENCY_INFO.name}
                and/or Contractors.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>{"\u2022" + " "}</strong> To facilitate your
                communications with Contractors, including enabling a chat
                functionality.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>{"\u2022" + " "}</strong> We use so-called “cookies,”
                web beacons, and other similar tracking technologies, including
                from third-party partners such as Google, to be able to better
                target advertisements and for marketing purposes.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>{"\u2022" + " "}</strong>To track your Shifts with {AGENCY_INFO.name}t and Contractors, where applicable.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>{"\u2022" + " "}</strong> To communicate with you, which
                includes sending SMS text messages and push notifications to you
                for authentication purposes and to alert you to your upcoming
                Shift.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>{"\u2022" + " "}</strong>To run analytics (we provide
                your IP address to Google Analytics; when we obtain the reports,
                we only receive aggregated information, which is not linked to
                any users of the App or Site).
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                If you choose to opt in to our mailing list, we may send you,
                from time to time, promotional information we think will be of
                interest to you. You will have the opportunity to opt out of
                those communications at any time following the instructions in
                the communication or by contacting us indicating your wishes at
                {AGENCY_INFO.privacyPolicyContactEmail}
              </Col>
            </Row>

            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                The usage and device information we automatically collect helps
                us to improve our Services and to deliver a better experience by
                enabling us to:
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>{"\u2022" + " "}</strong> Estimate our audience size and
                usage patterns.
              </Col>
            </Row>

            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>{"\u2022" + " "}</strong> Recognize when the App or the
                Site is used and which features are accessed.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                We collect this information on an aggregated basis only and will
                not be linked with your personal information.
              </Col>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              SHARING YOUR INFORMATION
            </Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>{"\u2022" + " "}</strong>To Customers to the extent
                necessary for you to perform work on Customer sites or as may be
                required by law;
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>{"\u2022" + " "}</strong>With your consent, we will
                share information about your performance with Customers.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>{"\u2022" + " "}</strong>To service providers,
                third-party processors, and other third parties we use to
                deliver the Services.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>{"\u2022" + " "}</strong> To a purchaser in the event of
                a merger, divestiture, restructuring, reorganization,
                dissolution, or other sale or transfer of some or all of the
                Company’s assets.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>{"\u2022" + " "}</strong>As may be required by law or as
                legitimately requested by law enforcement or pursuant to a legal
                obligation, in which case we will use reasonable efforts to
                provide notice to you.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>{"\u2022" + " "}</strong>Contractors will have access to
                your name and employment history and may invite you to apply for
                a Shift via a notification within the App. You may also view
                Contractor Shifts by browsing all Shifts on the App. We will not
                share your contact information or any other personal information
                until you apply for a Contractor Shift, at which point you are
                enabling your contact information and work history to be
                transferred to our affiliate Scout, where it can be accessed by
                Contractors. Digistaff has its own privacy policies, which {AGENCY_INFO.name} does not control. Digistaff will collect your
                information in accordance with their privacy policy. Please make
                sure you read and understand their privacy policy.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>{"\u2022" + " "}</strong>We will not share any
                additional information with Scout or Contractors. If you are
                accepted as an employee by the Contractor, you may be asked by
                the Contractor to provide additional information required for
                your employment.
              </Col>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              YOUR CHOICES
            </Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                You may choose to opt-out from receiving notifications about
                Shifts or from having your name and profile visible to
                Contractors. However, you may not be able to use all Services
                provided, including being notified or contacted about Contractor
                Shifts.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                You may also choose to disable sharing the star-rating
                information with other Customers.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                The information you provide using the chat functionality is at
                your discretion. We ask you not to submit any sensitive personal
                information about you or others when you use the chat function
                with us or with Contractors.
              </Col>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              ACCESSING, CORRECTING, AND DELETING YOUR PERSONAL INFORMATION
            </Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                You may send us an email at {AGENCY_INFO.privacyPolicyContactEmail} to request access
                to, correct, or delete any personal information that you have
                provided to us. We will accommodate your request within a
                reasonable time. Depending on the nature of the request, it may
                mean that you will no longer be able to use our Services.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                If you delete the App from your mobile device, we will delete
                your account but may retain certain information, in accordance
                with applicable law and the provisions of this Privacy Policy.
              </Col>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              DATA RETENTION
            </Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                We will retain information collected via {AGENCY_INFO.name} for
                as long as we are providing Services to you with respect to such
                information. We will retain information for as long as it may be
                required under applicable laws and regulations. We will delete
                information when it is no longer required, subject to any legal
                retention periods. If you wish for us to retain your information
                for longer, please contacts us.
              </Col>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              DATA SECURITY
            </Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                We follow industry recognized standards on information security
                management to safeguard your information. Unfortunately, the
                transmission of information via the internet and mobile
                platforms is not completely secure. Although we follow all legal
                requirements and do our best to protect your personal
                information, we cannot guarantee you that it will be.
              </Col>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>CHILDREN</Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                We do not knowingly collect information from anyone under the
                age of 18. If you are under the age of 18, you may not download
                the App or use the Services.
              </Col>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              DATA TRANSFERS
            </Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                {AGENCY_INFO.name} is located in Toronto, Ontario and all data
                will be stored in data centres in Canada. We use certain
                third-party providers who are located in the United States who
                may access personal information for support purposes.
              </Col>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              CHANGES TO OUR PRIVACY POLICY
            </Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                We may update our privacy policy from time to time. If we make
                material changes to how we treat our users’ personal
                information, we will alert you to those changes.
              </Col>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              QUESTIONS AND CONTACTING US
            </Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                If you have any questions or wish to contact us regarding our
                use of personal information, you can contact our Privacy Officer
                at {AGENCY_INFO.privacyPolicyContactEmail}.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                Should you have any complaints about this Policy or about how we
                process your personal information, we hope that you will contact
                us directly, but you are also entitled to contact the Office of
                the Privacy Commissioner of Canada toll-free: 1-800-282-1376.
              </Col>
            </Row>
          </Row>
        </Row>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col xs="6" align="left">
          <Button onClick={() => handlePrevStep()}>back</Button>
        </Col>
        <Col xs="6" align="right">
          <Button color="success" onClick={() => handleNextStep()}>
            Next
          </Button>
        </Col>
      </Row>
    </FormGroup>
  );
}
