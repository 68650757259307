import React, { useState, useEffect, useContext } from "react";
import RegistrationContext from "../../../context/RegistrationContext";
import {
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Row,
  Col,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import TrainingContext from "context/TrainingContext";

const shouldDisplayWrongAnswerMessage = (section) =>
  section.quiz.userAnswer &&
  section.quiz.userAnswer !== section.quiz.correctAnswer &&
  section.attempts < section.quiz.options.length - 1;
const shouldDisplayCorrectAnswer = (section) =>
  section.quiz.userAnswer &&
  section.quiz.userAnswer !== section.quiz.correctAnswer &&
  section.attempts >= section.quiz.options.length - 1;

const WrongMessageAlert = ({ section, toggle, modal }) => {
  return (
    <Modal toggle={toggle} isOpen={modal}>
      <ModalHeader>Wrong answer</ModalHeader>
      <ModalBody>
        {shouldDisplayWrongAnswerMessage(section)
          ? "Wrong answer, please check your answer."
          : section.quiz.explanation}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle} style={{ marginRight: 12 }}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

function TrainingMultipleChoice(props) {
  const { trainingDispatch } = useContext(TrainingContext);
  const [answer, setAnswer] = useState(null);
  const [modal, setModal] = useState(false);
  const { section, history } = props;
  const { slug } = useParams();

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (shouldDisplayCorrectAnswer(section)) {
      setAnswer(section.quiz.correctAnswer);
    } else {
      setAnswer(section.quiz.userAnswer);
    }
  }, [section]);

  return (
    section && (
      <>
        <h5>{section.quiz.question}</h5>
        <WrongMessageAlert section={section} modal={modal} toggle={toggle} />
        {section.quiz.options.map((text, index) => (
          <Row
            key={index}
            style={{
              height: 40,
              alignContent: "center",
              backgroundColor: "#e9ecef",
              margin: 0,
              marginTop: 16,
              borderRadius: 8,
            }}
          >
            <Col
              onClick={(e) => {
                setAnswer(index);
              }}
            >
              <div style={{ marginLeft: 20 }}>
                <Input type="checkbox" readOnly checked={answer == index} />{" "}
                {text}
              </div>
            </Col>
          </Row>
        ))}
        <Row>
          <Col>
            <Link to={`/${slug}/register/training`}>
              <Button style={{ marginTop: 16 }}>Back to menu</Button>
            </Link>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <Button
              style={{ marginTop: 16 }}
              color={"primary"}
              disabled={answer == null}
              onClick={(e) => {
                trainingDispatch({
                  type: "SET_ANSWER",
                  // sectionId: section.id,
                  answer,
                });
                if (section.quiz.userAnswer === section.quiz.correctAnswer) {
                  // history.push(`/${slug}/register/training/${section.id + 1}`);
                  trainingDispatch({
                    type: "SET_SELECTED_INDEX",
                    _index: props.index + 1,
                  });
                } else {
                  toggle();
                }
              }}
            >
              {props.lastModule ? "Finish" : "Submit"}
            </Button>
          </Col>
        </Row>
      </>
    )
  );
}

export default TrainingMultipleChoice;
