import React, { useState, useEffect, useContext } from "react";
import RegistrationContext from "../../../context/RegistrationContext";
import {
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Row,
  Col,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import TrainingContext from "context/TrainingContext";

function Terms(props) {
  const { training, trainingDispatch } = useContext(TrainingContext);
  const [answer, setAnswer] = useState(false);
  const { section, history } = props;

  const { slug } = useParams();
  return (
    <>
      <h5>
        <span
          dangerouslySetInnerHTML={{
            __html: section.text.split("\n").join("<br/>"),
          }}
        />
      </h5>
      <Row
        style={{
          height: 40,
          alignContent: "center",
          backgroundColor: "#e9ecef",
          margin: 0,
          marginTop: 16,
          borderRadius: 8,
        }}
      >
        <Col
          onClick={(e) => {
            setAnswer(!answer);
          }}
        >
          <div style={{ marginLeft: 20 }}>
            <Input type="checkbox" checked={answer} /> I have read and accept
            the terms and conditions.
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Link to={`/${slug}/register/training`}>
            <Button style={{ marginTop: 16 }}>Back to menu</Button>
          </Link>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <Button
            style={{ marginTop: 16 }}
            color={"primary"}
            disabled={answer ? false : true}
            onClick={(e) => {
              trainingDispatch({
                type: "SET_DONE",
              });
              setAnswer(false);
              // trainingDispatch({
              //   type: "SET_DONE",
              //   sectionId: section.id,
              // });
              // setAnswer(null);
              // history.push(`/${slug}/register/training/${section.id + 1}`);
              trainingDispatch({
                type: "SET_SELECTED_INDEX",
                _index: props.index + 1,
              });
            }}
          >
            {props.lastModule ? "Finish" : "Submit"}
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default Terms;
