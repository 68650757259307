/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { AGENCY_INFO } from "AgencyInfo";
import { validateEmail } from "components/Utilities/utilities.js";
import RegistrationContext from "context/RegistrationContext";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import MaskedInput from "react-text-mask";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import api from "../../components/API/api";

class ContinueTraining extends React.Component {
  static contextType = RegistrationContext;
  constructor(props) {
    super(props);
    this.state = {
      //logged User
      user: {},
      //Login details
      code: null,
      email: null,
      errorCode: "",
      errorEmail: "",

      //misc states
      isLoading: false,
      alert: null,
    };
  }

  componentDidMount() {
    // this.userDataRef = fs.collection("user_data")
    // firebase.auth().onAuthStateChanged((user) => {
    //   if (user) {
    //     // User is signed in.
    //     this.setState({ user })
    //   } else {
    //     // No user is signed in.
    //     firebase
    //       .auth()
    //       .signInAnonymously()
    //       .catch(function (error) {
    //         // Handle Errors here.
    //         var errorCode = error.code
    //         var errorMessage = error.message
    //         console.warn(errorCode, errorMessage)
    //       })
    //     console.log("No Logged User")
    //   }
    // })
  }

  handleSubmit = () => {
    let tempState = this.state; // Copy of state
    let hasError = false;
    // Start of Error Handling

    if (tempState.email == null || tempState.email == "") {
      this.setState({ errorEmail: "* Must have an email" }); // No email text
    } else {
      if (tempState.email != null && validateEmail(tempState.email) === false) {
        this.setState({ errorEmail: "* Please input a valid email" }); //invalid email text
        hasError = true;
      } else {
        this.setState({ errorEmail: "" });
      }
    }

    if (tempState.password == null || tempState.password == "") {
      this.setState({ errorPassword: "* Must have an password" }); // No email text
    } else {
      this.setState({ errorPassword: "" });
    }

    if (tempState.code == null || tempState.code == "") {
      this.setState({ errorCode: "* Must have a code." }); //No sin
      hasError = true;
    } else {
      if (tempState.code.length < 5) {
        this.setState({ errorCode: "* Please input a valid code." }); //invalid sin
        hasError = true;
      } else {
        this.setState({ errorCode: "" });
      }
    }

    if (hasError) {
      return;
    } else {
      this.prepareToLogin(tempState); // runs function after loading is set as true.
    }
  };

  openTrainingAlreadyCompleteModal = () => {
    this.setState({
      sin: null,
      email: null,
      phone: null,
      isLoading: false,
      alert: (
        <SweetAlert
          warning
          title="Your general training is already complete."
          onConfirm={this.hideAlert}
        />
      ),
    });
  };

  openTrainingIncompleteModal = (thisUser) => {
    this.setState({
      sin: null,
      email: null,
      phone: null,
      isLoading: false,
      alert: (
        <SweetAlert
          success
          title="Login successful!"
          onConfirm={this.hideAlert}
        />
      ),
    });
    this.context.userStateDispatch({ type: "SET_USER", user: thisUser });
    this.context.trainingDispatch({ type: "RESET" });
    this.props.history.push("/training");
  };

  prepareToLogin = async (tempState) => {
    try {
      const { data } = await api().post("/qualification-invite/login", {
        email: this.state.email,
        password: this.state.password,
        code: this.state.code,
      });
      console.log(data);
      if (data.user) {
        this.context.registrationDispatch({
          type: "SET_USER",
          user: data.user,
        });
        // this.context.trainingDispatch({ type: "RESET" });
        let slug = this.props.match.params.slug;
        this.props.history.push(`/${slug}/register/training`);
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        alert: (
          <SweetAlert
            warning
            title="An error occurred"
            onConfirm={this.hideAlert}
          >
            {error.response.data.message || error.message}
          </SweetAlert>
        ),
      });
    }

    // try {
    //   await firebase.firestore().collection("anonymous_data").doc(this.state.user.uid).set({
    //     sin: tempState.sin,
    //     email: tempState.email,
    //     phone: tempState.phone,
    //     createdAt: new Date(),
    //   })
    //   const getAnonymousUserLogin = firebase.functions().httpsCallable("getAnonymousUserLogin")
    //   const resp = await getAnonymousUserLogin({ sin: tempState.sin, email: tempState.email, phone: tempState.phone })
    //   console.log(resp)
    //   if (resp.error || !resp.data.id) {
    //     console.log("No valid user")
    //     this.setState({
    //       isLoading: false,
    //       alert: <SweetAlert warning title="Incorrect login details" onConfirm={this.hideAlert} />,
    //     })
    //   } else {
    //     const user = resp.data
    //     if (user.completedTraining === "boolean") {
    //       let booleanTraining = user.completedTraining
    //         ? this.openTrainingAlreadyCompleteModal()
    //         : this.openTrainingIncompleteModal(user)
    //     } else if (typeof user.completedTraining === "string") {
    //       if (user.completedTraining === "Yes" || user.completedTraining === "yes") {
    //         this.openTrainingAlreadyCompleteModal()
    //       } else {
    //         this.openTrainingIncompleteModal(user)
    //       }
    //     } else {
    //       if ("general" in user.completedTraining) {
    //         if ("completed" in user.completedTraining.general) {
    //           let objectTraining = user.completedTraining.general.completed
    //             ? this.openTrainingAlreadyCompleteModal()
    //             : this.openTrainingIncompleteModal(user)
    //         } else {
    //           this.openTrainingIncompleteModal(user)
    //         }
    //       } else {
    //         this.openTrainingIncompleteModal(user)
    //       }
    //     }
    //   }
    // } catch (error) {
    //   console.warn(error)
    //   this.setState({
    //     isLoading: false,
    //     alert: (
    //       <SweetAlert
    //         warning
    //         title="Error detected, please try again. If this issue continues, please contact an administrator"
    //         text={`${error}`}
    //         onConfirm={this.hideAlert}
    //       />
    //     ),
    //   })
    // }
  };

  keyPress = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit();
    }
  };

  loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={
          <Spinner color="light" style={{ width: "3rem", height: "3rem" }} />
        }
        onConfirm={this.hideAlert}
        showConfirm={false}
      />
    );
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  render() {
    return (
      <div className="login-page">
        {this.state.isLoading ? (
          this.loadingOverlay()
        ) : (
          <Row zindex={1}>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form>
                <Card className="card-login">
                  <CardHeader
                    style={{ alignItems: "center", textAlign: "center" }}
                  >
                    <img
                      src={AGENCY_INFO.registrationBanner}
                      alt={AGENCY_INFO.name}
                    ></img>
                    <br></br>
                    <CardTitle tag="h4">Continue Training</CardTitle>
                    <p>
                      To continue training, please enter your credentials and
                      the training invitation code that was sent to your email.
                    </p>
                    <p>
                      If you have not yet received the code, wait or contact the
                      agency.
                    </p>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <Label>* Email</Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Input
                            type="email"
                            autoComplete="off"
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorEmail}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>* Password</Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Input
                            type="password"
                            autoComplete="off"
                            onChange={(e) =>
                              this.setState({ password: e.target.value })
                            }
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorPassword}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>* Qualification Invitation Code</Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <MaskedInput
                            mask={[/\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/]}
                            className="form-control"
                            autoComplete="off"
                            placeholder="xxx xxx"
                            value={this.state.code}
                            onChange={(e) => {
                              //Allows the user to type in any format. Only numbers will be added to the state
                              let input = e.target.value;
                              let replacedCode = input.replace(/[^0-9]/g, "");
                              this.setState({ code: replacedCode });
                            }}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorCode}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col align="center">
                        <Button color="success" onClick={this.handleSubmit}>
                          Continue Training
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        )}
        {this.state.alert}
      </div>
    );
  }
}

export default ContinueTraining;
