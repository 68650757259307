import React, { useState, useEffect } from "react";
import {
  ExpandMore,
  AddCircleOutline,
  MoreVert,
  ChevronLeft,
  ChevronRight,
  Today,
} from "@material-ui/icons";
import moment from "moment";
import MonthPicker from "./MonthPicker";

export default function CustomCalendar({
  style,
  initialDate,
  onChangeDate,
  disablePreviousDate,
}) {
  const [date, setDate] = useState(moment(initialDate) || moment());

  const changeDate = function (date, change) {
    let newDate = new Date(new Date(date).setDate(1));
    if (newDate.getMonth() + change > 11) {
      newDate.setMonth(0);
      newDate.setFullYear(newDate.getFullYear() + 1);
    } else if (newDate.getMonth() + change < 0) {
      newDate.setMonth(11);
      newDate.setFullYear(newDate.getFullYear() - 1);
    } else {
      newDate.setMonth(newDate.getMonth() + change);
    }
    return newDate;
  };

  // useEffect(() => {
  //   if (
  //     new Date(date).getFullYear() != new Date(initialDate).getFullYear() ||
  //     new Date(date).getMonth() != new Date(initialDate).getMonth()
  //   ) {
  //     if (onChangeDate) onChangeDate(new Date(date));
  //   }
  // }, [date]);

  const onChanged = (date) => {
    if (onChangeDate) onChangeDate(new Date(date));
  };

  const disablePreviousDates = (current) => {
    const today = new Date();
    return current.isAfter(today);
  };

  return (
    <div className="d-flex justify-content-between" style={{ ...style }}>
      <div
        style={{ cursor: disablePreviousDate ? "not-allowed" : "pointer" }}
        onClick={() => {
          // if (disablePreviousDate) {
          //   if (date < new Date()) {
          //     return;
          //   }
          // }
          setDate(changeDate(date, -1));
          onChangeDate(changeDate(date, -1));
        }}
      >
        <ChevronLeft />
        Previous Month
      </div>
      <div>
        <MonthPicker
          onChange={(d) => {
            // if (
            //   new Date(date).getFullYear() != new Date(d).getFullYear() ||
            //   new Date(date).getMonth() != new Date(d).getMonth()
            // ) {
            setDate(moment(d));
            if (onChangeDate) onChangeDate(new Date(d));
            // }
          }}
          date={date}
        />
      </div>

      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          setDate(changeDate(date, 1));
          onChangeDate(changeDate(date, 1));
        }}
      >
        Next Month
        <ChevronRight />
      </div>
    </div>
  );
}
