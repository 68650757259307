import logo from '../src/assets/img/hd-group.png';

export const AGENCY_INFO = {
    name: 'HD Group',
    slug: 'hd-group',
    logo: logo,
    CEOName: 'Yashpal Dhaliwal',
    privacyPolicyContactEmail: 'kevin@hdgroup.ca',
    registrationBanner: 'https://www.jotform.com/uploads/mannyhdgroup/form_files/LYR%20FOOD%20Head%20wide%20jpeg.395.jpg',
    registrationBannerAltText: 'LYR Foods, division of HD Group',
    IOS: "https://apps.apple.com/us/app/hd-group-jobs-work-schedule/id1561042723",
    ANDROID: "https://play.google.com/store/apps/details?id=ca.hdgroup.worker"   
};