import { AGENCY_INFO } from "AgencyInfo";
import React from "react";
import { useEffect } from "react";
import { Button, Row, Col, Label, FormGroup } from "reactstrap";

function TermsOfUse({ handleNextStep, handlePrevStep }) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <FormGroup>
      <Row
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          margin: 15,
        }}
      >
        <Row style={{ alignItems: "center" }}>
          <Col style={{ color: "#faa632", fontWeight: "bold" }}>
            {AGENCY_INFO.name} Terms of Use (Effective as of September 28, 2022)
          </Col>
        </Row>

        <Row
          style={{
            margin: 10,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Row style={{ fontWeight: "bold", marginTop: 4, marginBottom: 4 }}>
            Please read the following carefully. IMPORTANT!
          </Row>{" "}
          <Row>
            <Col style={{ marginTop: 4, marginBottom: 4 }}>
              These Terms of Use (“<strong>Terms</strong>”) govern your use and
              access of this website (the “<strong>Site</strong>”), our mobile
              applications (the “<strong>Apps</strong>”) and our services,
              including {AGENCY_INFO.name}’s platform that can be used to provide
              its users with access to shift work (collectively, the “
              <strong>Services</strong>”) which are owned, operated and provided
              by 1904390 Ontario Inc. o/a {AGENCY_INFO.name} (
              <strong>“{AGENCY_INFO.name}”, “we”, “our”, “us” </strong>). Please
              read these Terms carefully before using our Site, Apps and
              Services. Any person who wishes to use our Site, Apps and Services
              must accept these Terms without change. By using our Site, Apps,
              and Services, you are indicating your acknowledgment and
              acceptance of these Terms and all related policies and guidelines
              of this Site, including our privacy policy, as described in these
              Terms are incorporated by reference.
            </Col>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              1. Modifications to Terms
            </Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                We may change these Terms or any other policies or guidelines
                governing our Site, Apps and Services at any time in our sole
                discretion. Any changes will be effective upon the posting of
                the revisions on our Site. Your use of our Site, Apps and
                Services will be subject to the most current version of the
                Terms posted on our Site at the time of such use. Your continued
                use of our Site, Apps and Services after such changes are
                implemented constitutes your acknowledgment and acceptance of
                the changes. Please consult these Terms regularly. if you do not
                agree to any changes to these Terms, please immediately
                discontinue your use of our Site, Apps and Services.
              </Col>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              2. Privacy Policy
            </Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                Please click here to read and review our Privacy Policy, which
                describes our privacy policies and practices in detail, as we
                may amend such policy from time to time. We advise you to check
                our Privacy Policy on a frequent basis for changes. You hereby
                consent to our use of your personal information in accordance
                with the terms and for the limited purposes described in our
                Privacy Policy. By agreeing to these Terms, you acknowledge and
                agree that certain information about you is subject to our
                Privacy Policy.
              </Col>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              3. About our Services
            </Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>(a)</strong> Our Service allow individuals who are
                looking for temporary work and/or shirts (each a “
                <strong>User</strong>” and collectively, the “Users’) to apply
                for shift work with either {AGENCY_INFO.name} or certain third
                parties (“
                <strong>Contractors</strong>”) which post shifts through the
                Services (each a “<strong>Shift</strong>”). Shifts offered by
                {AGENCY_INFO.name} will be displayed as {AGENCY_INFO.name} Shifts
                through the Services (“<strong>Employment Shifts</strong>”).
                When you apply for a Employment Shift offered by {AGENCY_INFO.name},
                your terms of employment shall be governed by your written
                employment agreement with {AGENCY_INFO.name}. Shifts offered by
                Contractors will be displayed as third party through the
                Services (“
                <strong>Contractor Shifts</strong>”).
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>(b)</strong> The Services will allow Users to apply for
                Shifts based on that Users availability. If you are a User, you
                acknowledge and agree that you will only apply for Shifts for
                which you are available. We will notify you through the Services
                if you are selected for a Shift. For Contractor Shifts, if you
                apply to a Contractor Shift, certain personal information about
                you will be shared with the Contractor, such as your work
                history, name and address. Contractors will use this information
                to determine your eligibility for a Contractor Shift. If you are
                selected for a Contractor Shift we will then share further
                information with the Contractor that will allow the Contractor
                to engage you, such as your social insurance number. By applying
                for a Contractor Shift, you acknowledge and agree {AGENCY_INFO.name}
                may share your personal information with the applicable
                Contractor for the purposes described in these Terms.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>(c)</strong> Contractor Shifts are offered by
                Contractors and are not offered by {AGENCY_INFO.name}. The Services
                merely connect Contractors with Users and allow Contractors to
                manage its engagement of any Users. Contractors are independent
                third-parties who will engage each User directly and will
                negotiate and contract terms of any services for any Shift. With
                respect to any Contractor Shifts, You acknowledge and agree that
                {AGENCY_INFO.name} (i) shall not be responsible for any Contractor
                offers, Contractor screenings, Contractor decisions, and actual
                employment presented by third parties for any Contractor Shift;
                (ii) does not represent or warrant that you will receive any
                offers for employment or otherwise through the Site, App or
                Services; (iii) is not responsible for, and does not guarantee,
                the completeness, accuracy, validity, or timeliness of
                information listed by any third parties; (iv) shall not be
                responsible for any materials posted by third parties,
                including, but not limited to, job openings and employment
                listings; (v) is not your employer or agent with respect to any
                Contractor Shifts and {AGENCY_INFO.name} is not and will not be a
                party to any agreement between a User and a Contractor for any
                Contractor Shifts, even if that agreement is formed through the
                Services; (vi). You are solely responsible for evaluating any
                prospective employers and any information provided by any third
                party for any Contractor Shifts; and (vii) you are solely
                responsible for collecting and remitting any taxes, withholdings
                or other payments to any applicable government authority.
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                <strong>(d)</strong> If you are a User, when applying for a
                Shift, you represent and warrant that: (i) you possess, have and
                maintain all appropriate, up-to-date, valid and required
                licenses, credentials, approvals and authorizations to work and
                render the specific services required for the Shift; (ii) you
                shall perform your duties and obligations and provide the
                services required for each Shift in accordance with all
                applicable laws; (iii) you will perform your duties and
                obligations in a professional, workmanlike manner in accordance
                with industry standards and will not perform any Shifts while
                under the influence of drugs or alcohol; ad (iv) all information
                you provide to us or any user of the Services, whether at the
                time you establish your account, when you apply for a Shift or
                thereafter, is true, accurate and not misleading.
              </Col>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              4. Intended Users
            </Col>
            <Row>
              <Row style={{ marginTop: 4, marginBottom: 4 }}>
                You must be at least eighteen (18) years old to use our Services
                and Apps. Your use of our Services and Apps is a representation
                to us that you are at least eighteen (18) years of age. You
                agree to cooperate with us and any reasonable measures we may
                use to verify your identity and authority in connection with
                your use of our Services and Apps.
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              5. Your Registration Obligations
            </Col>
            <Row>
              <Col style={{ marginTop: 4, marginBottom: 4 }}>
                You will be required to register and/or set up an account to use
                certain portions of our Services or Apps. In order to do so, you
                may be provided, or required to choose, a password, username,
                and/or other registration information, including, but not
                limited to, your employment history, personal information
                (collectively, “<strong>Registration Information</strong>”). You
                agree and represent that all Registration Information provided
                by you is accurate and up-to-date. If any of your Registration
                Information changes, you must update it by using the appropriate
                update mechanism on our Site or Apps, if available. If we
                believe that the Registration Information that you provided is
                not correct, current, or complete, we reserve the right to
                refuse you access to our Site, Services or Apps. However, we
                have no obligation to verify the accuracy, currency completeness
                or usefulness of any Registration Information that you have
                provided to us.
              </Col>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              6. Username/Password
            </Col>
            <Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  <strong>(a)</strong> If you register or set up an account on
                  the Site or Apps, you will be solely responsible for
                  maintaining the confidentiality of any username, password, and
                  similar information. You may not authorize others to use your
                  username, password, or similar information. You are solely
                  responsible for all usage or activity on your account
                  including, but not limited to, use of the account by any
                  person who uses your Registration Information, with or without
                  authorization, or who has access to any computer on which your
                  account resides or is accessible.
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  <strong>(b)</strong> If you have reason to believe that your
                  account is no longer secure (for example, following a loss,
                  theft or unauthorized disclosure or use of your Registration
                  Information), you must promptly change the affected
                  Registration Information by using the appropriate update
                  mechanism on the Site, if available. We will not be
                  responsible for the unauthorized use of your profile by any
                  other person and disclaim any and all liability that may be
                  associated with such use.
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  <strong>(c)</strong> We have the right to cancel or suspend or
                  terminate your account, your use or your access to the Site,
                  Services or Apps, as well as remove and delete any information
                  or Registration Information related to the Site or any one of
                  the Services (and terminate your use thereof), for any reason
                  whatsoever and at any time in its sole discretion, without
                  notice and without liability to you or any other person.
                </Col>
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              7. License Grant and Restrictions on Use
            </Col>
            <Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  <strong>(a)</strong> So long as you comply with these Terms,
                  {AGENCY_INFO.name} hereby grants to you a limited, non-exclusive,
                  non-transferable, revocable, non-sublicensable right to
                  download and use the Apps on a compatible mobile or desktop
                  device solely for the purposes of accessing and using the
                  Services. You may not use our Services or Apps for any other
                  purpose without our express prior written consent.
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  <strong>(b)</strong> The Apps is licensed, not sold, to you.
                  Except as expressly provided for in these Terms, {AGENCY_INFO.name}
                  reserves all rights, title and interest in and to the Apps and
                  Services, including all intellectual property and other
                  proprietary rights.
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  <strong>(c)</strong> You may not and may not authorize any
                  other party to: (i) co‑brand our Services or Apps; (ii) frame
                  our Site; or (iii) hyperlink to our Site, without the express
                  prior written permission of an authorized representative of
                  {AGENCY_INFO.name}. For purposes of these Terms, “co‑branding” means
                  to display any name, logo, trade-mark, or other means of
                  attribution or identification of any party in such a manner as
                  is reasonably likely to give a user the impression that such
                  other party has the right to display, publish, or distribute
                  our Services and Apps or which may confuse a user as the
                  nature of the relationship between us and any party. You agree
                  to cease and desist causing any unauthorized co-branding and
                  framing or hyperlinking upon notice from us and at all times
                  you will cooperate with us following our discovery of any such
                  illegal activity.
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  <strong>(d)</strong> {AGENCY_INFO.name} may, at any time and
                  without notice to you, suspend, disable, limit or terminate
                  access or use of all or part of the Apps or the Services for
                  any reason and without any liability to you whatsoever. While
                  {AGENCY_INFO.name} takes great care to ensure the Apps and Services are
                  accurate, errors and/or inaccuracies may occur. {AGENCY_INFO.name}
                  may change or update information on the Site at any time
                  without notice, and availability of the Services or the Apps.
                  We make no representations that the Apps or Services are
                  compatible with, or will function or operate with your device
                  or equipment.
                </Col>
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              8. Additional Terms for Mobile Users
            </Col>
            <Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  <strong>(a)</strong> Your use of our Apps must be in
                  accordance with all third party usage rules and policies that
                  may exist from time to time (“Usage Policies”) applicable to
                  you or your use of our Apps that may relate to any service
                  provider, mobile device platform or the method you downloaded
                  our Apps. You are solely responsible for determining the{" "}
                  <strong>Usage Policies</strong> that apply to your use of the
                  Apps.
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  <strong>(b)</strong> Your use of the Apps that {AGENCY_INFO.name}
                  provides to you designed for use on an Apple iOS-powered
                  mobile device (an <strong>"iOS Apps"</strong>) requires usage
                  of data services. You acknowledge and agree that you are
                  solely responsible for any and all data fees that your
                  wireless service carrier may charge and any other applicable
                  fees in connection with your use of the iOS Apps.
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  <strong>(c)</strong> You must not use this Apps on any device
                  that you know or have reason to believe has had its standard
                  protections and limitations have been removed, including, but
                  not limited to a device that has been “rooted” or
                  “jailbroken”.
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  <strong>(d)</strong> The following additional terms and
                  conditions apply with respect to any iOS Apps:
                </Col>
              </Row>
              <Row style={{ marginLeft: 4, marginTop: 4, marginBottom: 4 }}>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(i)</strong> You acknowledge that these terms are
                    between you and {AGENCY_INFO.name} only, and not with Apple,
                    Inc. ( "Apple").
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(ii)</strong> Your use of {AGENCY_INFO.name}'s iOS Apps
                    must comply with Apple's then-current App Store Terms of
                    Service, as applicable.
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(iii)</strong> {AGENCY_INFO.name}, and not Apple, is
                    solely responsible for our iOS Apps and the Services and
                    content available thereon.
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(iv)</strong> Your use of the iOS Apps is limited to
                    Apple branded products that you own or control and as
                    permitted by the Usage Policies set forth in the applicable
                    App Store.
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(v)</strong> You acknowledge that Apple has no
                    obligation to provide maintenance and support services
                    whatsoever with respect to our iOS Apps.
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(vi)</strong> To the maximum extent permitted by
                    applicable law, Apple will have no warranty obligations,
                    whether express or implied by law whatsoever with respect to
                    our iOS Apps. To the extent not effectively disclaimed, any
                    warranties remain with {AGENCY_INFO.name}. In the event of any
                    failure of the iOS Apps to conform to any applicable
                    warranty, you may notify Apple and Apple will refund the
                    purchase price for iOS Apps to you and to the maximum extent
                    permitted by applicable law, Apple will have no other
                    warranty obligation whatsoever with respect to the iOS Apps
                    and any other claims, losses, liabilities, damages, costs or
                    expenses attributable to any failure to confirm to any
                    warranty will be {AGENCY_INFO.name}'s sole responsibility.
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(vii)</strong> You agree that {AGENCY_INFO.name}, and
                    not Apple, is responsible for addressing any claims by you
                    or any third party relating to our iOS Apps or your
                    possession and/or use of our iOS Apps, including, but not
                    limited to: (i) product liability claims; (ii) any claim
                    that the iOS Apps fails to conform to any applicable legal
                    or regulatory requirement; and (iii) claims arising under
                    consumer protection or similar legislation, and all such
                    claims are governed solely by these terms and any law
                    applicable to us as provider of the iOS Apps.
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(viii)</strong> You agree that {AGENCY_INFO.name}, and
                    not Apple, shall be responsible, in the event of any third
                    party claim that the iOS Apps or your possession and use of
                    our iOS Apps infringes that third party’s intellectual
                    property rights, and {AGENCY_INFO.name}, not Apple, will be
                    solely responsible for the investigation, defense,
                    settlement and discharge of any such third party
                    intellectual property infringement claim.
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(ix)</strong> You represent and warrant that (i) you
                    are not located in a country that is subject to a U.S.
                    Government embargo, or that has been designated by the U.S.
                    Government as a "terrorist supporting" country; and (ii) you
                    are not listed on any U.S. Government list of prohibited or
                    restricted parties.
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(x)</strong> You agree to comply with all applicable
                    third party terms of agreement when using our iOS Apps
                    (e.g., you must not be in violation of your wireless data
                    service terms of agreement when using iOS Apps).
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(xi)</strong> If you have any questions, complaints
                    or claims regarding the iOS Apps, please contact {AGENCY_INFO.name}.
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(xii)</strong> The parties agree that Apple and
                    Apple's subsidiaries are third party beneficiaries to these
                    terms as they relate to your license of {AGENCY_INFO.name}'s iOS
                    Apps. Upon your acceptance of these terms, Apple will have
                    the right (and will be deemed to have accepted the right) to
                    enforce these terms against you as they relate to your
                    license of the iOS Apps as a third party beneficiary
                    thereof.
                  </Col>
                </Row>
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              9. Proprietary Content
            </Col>
            <Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  <strong>(a)</strong> The entire contents of our Apps and Site,
                  including without limitation, all names, designs, graphics,
                  images, illustrations, photographs, artwork, video, audio,
                  software, code, data, text, displays, the Apps and Site’s
                  “look and feel”, logos, slogans, trade-marks, trade names,
                  service marks, domain names, and the design, selection,
                  arrangement and presentation of the Site as a collective work
                  and/or compilation is protected under Canadian copyright,
                  trade-mark, competition and other laws of Canada and other
                  nations, and is owned by us or has been licensed to us. Except
                  as expressly provided in these Terms, nothing contained in
                  this Site and provided by our Services or the Apps should be
                  construed as granting, by implication, estoppel, or otherwise,
                  any license or right to use any trade-mark, service mark, or
                  any other intellectual property.
                </Col>
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              10. Third Party Content
            </Col>
            <Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  <strong>(a)</strong> Our Services and Apps may also contain
                  information or materials (“{" "}
                  <strong>Third Party Content</strong>
                  ”) that is owned or provided by persons other than {AGENCY_INFO.name} (“ <strong>Third Party Providers</strong>”), such as
                  our partner companies. We make no representations and
                  warranties with respect to any such Third Party Content and do
                  not undertake to conduct any independent investigation of
                  Third Party Content. Inclusion of Third Party Content from
                  Third Party Providers with our Services or Apps does not mean,
                  unless expressly stated otherwise, that we endorse or agree
                  with such Third Party Content.
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  <strong>(b)</strong> You understand that you have no
                  proprietary rights in the Third Party Content and that the
                  Third Party Providers retain all proprietary right, title or
                  interest, including copyright, in their respective Third Party
                  Content.
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  <strong>(c)</strong> You must use your best efforts to stop
                  any such copying or distribution immediately after you become
                  aware of such use. You may not copy, sell, license, transfer,
                  publish, reproduce, modify, display, reverse engineer,
                  decompile, disassemble, adapt, translate, transmit, arrange,
                  bundle, sublicense, export, merge, loan, rent, lease, assign,
                  share, outsource, host, distribute in any way, prepare
                  derivative works based on, re-post, make available to any
                  person or otherwise use, either directly or indirectly, any of
                  the Third Party Content, in whole or in part, in any form or
                  by any means whatsoever, be they physical, electronic or
                  otherwise, for any public or commercial purpose, without the
                  prior written consent of the Third Party Providers. You must
                  use your best efforts to stop any such copying or distribution
                  immediately after you become aware of such use.
                </Col>
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col bold style={{ fontWeight: "bold", color: "#faa632" }}>
              11. Hyperlinks
            </Col>
            <Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  Our Services and Apps contain hyperlinks to other sites that
                  are not maintained by, or related to, {AGENCY_INFO.name}.
                  Hyperlinks to such sites are provided solely as a convenience
                  to you and do not imply any endorsement by {AGENCY_INFO.name} of,
                  any affiliation with or endorsement by the owner of the linked
                  site. We have no control over such third party sites. We are
                  not responsible for the availability of such external sites
                  and no endorsement of any third party products or services is
                  expressed or implied by any information, material or content
                  referred to or included on or linked from or to our Services
                  or Apps. Use of such third party sites, including any
                  information, material and services in these sites, is solely
                  at your own risk. You also understand and agree that our
                  Privacy Policy is applicable only while you are using our
                  Services and Apps. Once you are linked to another site, you
                  should read the privacy statement of that site before
                  disclosing any personal information.
                </Col>
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              12. Use of our Site, Apps and Services
            </Col>
            <Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  <strong>(a)</strong> We expect that you will access and use
                  our Site, Apps and Services in a professional, responsible and
                  business-like manner in accordance with applicable laws. By
                  using our Services or Apps, you have the opportunity to engage
                  in dialogue with representatives of {AGENCY_INFO.name} if you send us
                  emails or contact us through our Site. We welcome your
                  questions, suggestions, ideas and other information
                  (collectively “Suggestions”) that you communicate to us so
                  long as any such Suggestions or interaction is not obscene,
                  illegal, threatening, defamatory, libellous, harmful, abusive,
                  harassing, tortuous, homophobic, sexist, vulgar, obscene,
                  hateful or racially, ethically or otherwise objectionable,
                  invasive of privacy, infringing of third party intellectual
                  property or privacy rights, or is otherwise injurious to third
                  parties.
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  <strong>(b)</strong> You are not obligated to provide us with
                  Suggestions regarding our Site, Services or Apps. However, if
                  you do provide us with Suggestions, then we retain all rights
                  to any Suggestions that you may provide to us and you agree to
                  grant us a non-exclusive, worldwide, royalty-free, irrevocable
                  license to use your Suggestions in any commercial manner.
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  <strong>(c)</strong> You further agree that you must use our
                  Site, Apps and Services for lawful purposes only and represent
                  and warrant that you will not use our Site, Apps and Services
                  to:
                </Col>
              </Row>
              <Row style={{ marginLeft: 4, marginTop: 4, marginBottom: 4 }}>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(i)</strong> e-mail, provide us with or otherwise
                    transmit any Suggestions that you do not have a right to
                    transmit under any law or under contractual or fiduciary
                    relationships (such as proprietary and confidential
                    information learned or disclosed as part of employment
                    relationships or under nondisclosure agreements);
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(ii)</strong> e-mail, provide us with or otherwise
                    transmit any Suggestions that infringes, misappropriates, or
                    otherwise violates any third party’s copyright, patent,
                    trade-mark, or other proprietary right of publicity or
                    privacy, or encourages or enables any other party to do so;
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(iii)</strong> remove any proprietary notices or
                    labels, or any promotional or advertising material, from our
                    Site, Apps and Services;
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(iv)</strong> e-mail, transmit or send via the
                    Internet any unsolicited or unauthorized advertising,
                    branding, promotional materials, commercial solicitation,
                    political campaigning, “junk mail,” “spam,” “chain letters,”
                    “pyramid schemes,” or any other form of solicitation;
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(v)</strong> impersonate any person or entity or
                    misrepresent your affiliation with any other person, company
                    or entity, including without limitation, using a false email
                    address or misleading us as to your identity when you are on
                    our Site or otherwise communicating with us;
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(vi)</strong> upload, e-mail, send via the Internet
                    or otherwise transmit, any software viruses or any other
                    computer code, files or programs designed to interrupt,
                    destroy, alter, or limit the functionality of any computer
                    software or hardware or telecommunications equipment or that
                    imposes an unreasonable or disproportionately large load on
                    the Site’s infrastructure or limits the functionality of any
                    {AGENCY_INFO.name} or third party computer hardware, software,
                    networks, or hardware or telecommunications equipment;
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(vii)</strong> interfere or attempt to interfere
                    with or disrupt our Site, Apps or Services including but not
                    limited to our servers or networks connected to our Site,
                    including attempting to interfere with the access of any
                    other user, host or network, including without limitation,
                    overloading, initiating, propagating, participating,
                    directing or attempting any “denial of service” attacks,
                    “spamming”, “crashing”, “flooding” or “mail-bombing” our
                    Site or disobeying any requirements, procedures, policies or
                    regulations of networks connected to our Site, Apps and
                    Services;
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(viii)</strong> direct bots, spiders, crawlers,
                    avatars, intelligent agents or any other automated process
                    at our computer systems or otherwise, create unreasonable
                    load upon any of our computer hardware, network, storage,
                    input/output or electronic control devices;
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(ix)</strong> intentionally or unintentionally
                    violate any applicable local, provincial, national or
                    international law, rule or regulation applicable in
                    connection with our Site, Apps and;
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(x)</strong> modify, adapt, assign, copy,
                    sublicense, translate, rent, sell, reverse engineer,
                    decompile or disassemble any of our Services or Apps (except
                    as may be expressly permitted in these Terms), including but
                    not limited to any portion of our Site;
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(xi)</strong> Collect, use, disclose, store or
                    distribute any personal information of a third party without
                    their express consent;
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(xii)</strong> Transmit any Information or
                    Registration Information known by you to be false,
                    inaccurate or misleading; or
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: 4, marginBottom: 4 }}>
                    <strong>(xiii)</strong> Transmit any Information or
                    Registration Information that is intimate, private or that
                    you would not want the public to view or have access to.
                  </Col>
                </Row>
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              13. Disclaimer of Warranties/Limitation of Liability
            </Col>
            <Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  <strong>(a)</strong> You understand that we will use
                  commercially reasonable efforts to provide our Site, Apps or
                  Services but we cannot and do not guarantee or promise any
                  specific and intended results, including but not limited to
                  financial or commercial, from the use of our Site, Apps or
                  Services or any Shifts. You acknowledge that any reliance on
                  our Services and Apps will be at your own risk and that you
                  must evaluate and bear all risks associated with the use of
                  our Services and Apps. YOU ARE SOLELY RESPONSIBLE FOR ANY USE
                  OF AND RESULTS FROM USING OUR SITE, APPS OR SERVICES. YOUR USE
                  OF OUR SITE, APPS AND SERVICES IS ENTIRELY AT YOUR OWN RISK.
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  <strong>(b)</strong> We do not represent or warrant that files
                  or information available for access or downloading from the
                  Internet, hyperlinked sites, our Site or any files and emails
                  from us will be free of viruses, worms, malware, Trojan horses
                  or other code that may manifest contaminating or destructive
                  properties. YOUR USE OF THE INTERNET IS AT YOUR OWN RISK AND
                  YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGES TO YOUR COMPUTER
                  SYSTEMS OR LOSS OF DATA THAT RESULTS FROM ANY INTERNET ACCESS
                  OR OUR SITE, SERVICES, OR APPS.
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  <strong>(c)</strong> YOUR USE OF OUR SITE, SERVICES AND APPS
                  IS AT YOUR OWN RISK. OUR SITE, SERVICES AND APPS ARE PROVIDED
                  ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT
                  REPRESENTATIONS, WARRANTIES, GUARANTEES OR CONDITIONS OF ANY
                  KIND, EITHER EXPRESS OR IMPLIED. WE EXPRESSLY DISCLAIM ALL
                  REPRESENTATIONS, WARRANTIES, GUARANTEES AND CONDITIONS,
                  INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
                  FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT OR
                  NON-INFRINGEMENT OR THOSE ARISING OUT A COURSE OF DEALING,
                  PERFORMANCE OR USAGE OF TRADE. WE MAKE NO REPRESENTATIONS,
                  WARRANTIES OR CONDITIONS REGARDING THE AVAILABILITY
                  APPROPRIATENESS, COMPLETENESS, ACCURACY, OR CURRENCY OF OUR
                  SITE, SERVICES OR APPS. FOR GREATER CLARITY, WE DO NOT
                  REPRESENT OR WARRANT THAT: (I) OUR SITE, APPS OR SERVICES DO
                  NOT AND WILL NOT INFRINGE, OR BE SUBJECT TO ANY INTELLECTUAL
                  PROPERTY CLAIMS, OR A CLAIM OF INFRINGING THE TRADEMARK OR
                  OTHER RIGHTS OF ANOTHER PARTY; (II) OUR SITE OR APPS WILL BE
                  UNINTERRUPTED, FUNCTIONAL, SECURE, RELIABLE, ACCURATE,
                  COMPLETE, CURRENT, USEFUL, SUITABLE, SECURE OR ERROR-FREE;
                  (III) OUR SITE, APPS OR SERVICES WILL BE SUITABLE, ACCURATE,
                  CORRECT, RELIABLE, COMPLETE, TIMELY, ERROR-FREE OR WILL MEET
                  YOUR REQUIREMENTS AND EXPECTATIONS IN ANY WAY; (IV) ANY
                  DEFECTS IN OUR SITE, SERVICES OR APPS WILL BE CORRECTED; AND
                  (V) WE HAVE VERIFIED OR ENDORSED ANY THIRD PARTY CONTENT. OUR
                  SITE, SERVICES AND APPS MAY INCLUDE TECHNICAL INACCURACIES OR
                  TYPOGRAPHICAL ERRORS, AND WE MAY MAKE CHANGES OR IMPROVEMENTS
                  TO THE SITE, SERVICES AND APPS AT ANY TIME.
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  <strong>(d)</strong> IN NO EVENT SHALL {AGENCY_INFO.name}, ITS
                  DIRECTORS, OFFICERS, SHAREHOLDERS, EMPLOYEES, THIRD PARTY
                  PROVIDERS, AGENTS OR ADVISORS (“ASSOCIATED PERSONS”) BE LIABLE
                  TO YOU OR ANY OTHER PERSON OR ENTITY FOR ANY DIRECT, INDIRECT,
                  EXEMPLARY, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE
                  DAMAGES, INCLUDING LOSS OF INCOME, LOSS OF PROFITS, LOSS OF
                  ANTICIPATED SALES, LOSS OF OPPORTUNITIES, BUSINESS
                  INTERRUPTION, FAILURE TO REALIZE UNEXPECTED SAVINGS, DAMAGE TO
                  PROPERTY, CLAIMS OF THIRD PARTIES, LOSS OF GOODWILL, USE, DATA
                  OR OTHER INTANGIBLE LOSSES OR OTHER ECONOMIC OR PERSONAL LOSS
                  ARISING FROM OR IN CONNECTION WITH: (I) YOUR USE OF OR
                  RELIANCE ON ANY INFORMATION PROVIDED BY US ON OUR SITE,
                  SERVICES OR APPS; (II) YOUR USE OF OR INABILITY TO USE OUR
                  SITE, APPS OR SERVICES; (III) YOUR VIOLATIONS OF TRADEMARK
                  INFRINGEMENT OR OTHER INTELLECTUAL PROPERTY RIGHTS; (IV) ANY
                  FAILURE OF PERFORMANCE OF OUR SITE, SERVICES AND APPS, WHETHER
                  RELATED TO ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY IN
                  OPERATION OR TRANSMISSION, COMPUTER VIRUSES OR LINE FAILURE;
                  (V) THE UNAUTHORIZED USE OF YOUR ACCOUNT AND PASSWORD BY ANY
                  PERSON; (VI) ANY THIRD PARTY CONTENT OR HYPERLINKED SITES;
                  (VII) YOUR REGISTRATION INFORMATION OR INFORMATION THAT YOU
                  TRANSMITTED OR SHARED THROUGH OR IN CONNECTION WITH OUR
                  SERVICES; OR (VIII) ANY OTHER MATTERS RELATING TO OUR SITE,
                  SERVICES AND APPS, WHETHER BASED IN CONTRACT, NEGLIGENCE,
                  STRICT LIABILITY, FUNDAMENTAL BREACH, FAILURE OF ESSENTIAL
                  PURPOSE OR OTHERWISE, WHETHER OR NOT WE HAD ANY KNOWLEDGE,
                  ACTUAL OR CONSTRUCTIVE, THAT YOU MIGHT INCUR SUCH DAMAGES. THE
                  LIMITATIONS ON {AGENCY_INFO.name}'S LIABILITY OR THE LIABILITY OF
                  ASSOCIATED PERSONS TO YOU IN THIS SECTION SHALL APPLY WHETHER
                  OR NOT {AGENCY_INFO.name} OR SUCH ASSOCIATED PERSONS HAVE BEEN
                  ADVISED OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF ANY
                  SUCH LOSSES ARISING.
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  <strong>(e)</strong> WE DISCLAIM ANY AND ALL SUCH ABOVE
                  REPRESENTATIONS, WARRANTIES AND CONDITIONS TO THE FULLEST
                  EXTENT PERMISSIBLE UNDER APPLICABLE LAW. CERTAIN JURISDICTIONS
                  DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR CONDITIONS
                  OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE
                  LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS,
                  EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU AND YOU MAY
                  HAVE ADDITIONAL RIGHTS.
                </Col>
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              14. Indemnity
            </Col>
            <Row>
              <Row>
                <Col style={{ marginTop: 4, marginBottom: 4 }}>
                  You agree at all times to defend, indemnify and hold harmless
                  {AGENCY_INFO.name}, its employees, contractors, agents, officers,
                  directors, successors and assigns (the “
                  <strong>Indemnified Parties</strong>”) from and against any
                  claims, losses, judgements, actions, proceedings, damages,
                  costs and expenses (including without limitation, reasonable
                  legal and other fees and disbursements) incurred by any of the
                  foregoing parties due to or resulting from your use or misuse
                  of our Site, Services or Apps, or from your violation of these
                  Terms, including your failure to appropriately obtain Consent
                  from a third party. You will also indemnify and hold the
                  Indemnified Parties harmless from and against any claims
                  brought by third parties arising out of your use of our Site,
                  Services and Apps, including but not limited to trademark
                  infringement or other intellectual property claims from third
                  parties.
                </Col>
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              15. System Security
            </Col>
            <Row>
              <Row style={{ marginTop: 4, marginBottom: 4 }}>
                You are prohibited from using any services or facilities
                provided in connection with our Site, Services or Apps to
                compromise security or tamper with our system resources and/or
                accounts. The use or distribution of tools designed for
                compromising security (e.g., password guessing programs,
                cracking tools or network probing tools) is strictly prohibited.
                We reserve the right to investigate suspected violations of
                these Terms and to fully cooperate with any law enforcement
                authorities or court order requesting or directing us to
                disclose the identity of anyone believed to be violating these
                Terms.
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              16. Dispute Resolution
            </Col>
            <Row>
              <Row style={{ marginTop: 4, marginBottom: 4 }}>
                Any dispute, controversy or claim arising out of or relating to
                these Terms, including any disputes relating to the Site,
                Services or Apps, will be finally resolved by arbitration under
                the Arbitration Rules of the ADR Institute of Canada, Inc. The
                arbitration will be conducted by one arbitrator and the seat of
                Arbitration will be Toronto, Ontario. The language of the
                arbitration will be English. The decision of the arbitrator
                shall be binding upon the parties hereto.
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              17. Governing Law; Jurisdiction
            </Col>
            <Row>
              <Row style={{ marginTop: 4, marginBottom: 4 }}>
                These Terms and any action related thereto or related to our
                Site, Services or Apps shall be governed, controlled,
                interpreted and defined by and under the laws of the Province of
                Ontario and the federal laws of Canada applicable therein
                without giving effect to any principles of conflicts of laws.
                The United Nations Convention on the International Sale of Goods
                is explicitly excluded from this agreement. Subject to Section
                18, you agree that any action at law or in equity arising out of
                or relating to these Terms or your use of our Site, Services or
                Apps will be filed only in a court located in Toronto, Ontario,
                Canada, and you hereby irrevocably and unconditionally consent
                and submit to the non-exclusive jurisdiction of such courts for
                the purpose of any such action. Your use of our Site, Services
                and Apps is void where prohibited by laws in jurisdictions to
                which you are otherwise subject, and you agree not to visit or
                use our Site, Services or Apps in any such circumstances.
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              18. Termination
            </Col>
            <Row>
              <Row style={{ marginTop: 4, marginBottom: 4 }}>
                You acknowledge and agree that these Terms shall remain in
                effect for so long as you use our Site, Services or Apps. You
                agree that this agreement can only be terminated by you once you
                have stopped using our Site, Services or Apps. We reserve the
                right to suspend or terminate your use of our Site, Services or
                Apps and remove and discard any information or Registration
                Information related to you and your use of our Site, Services or
                Apps at any time, for any reason, with or without cause, if we
                believe that you are violating these Terms in any way. If you
                wish to terminate your account, you may choose to cease use of
                the Site or to send us a notification. If you are dissatisfied
                with our Site, Services or Apps, including these Terms, your
                sole remedy is to discontinue using our Site, Services or Apps
                by ceasing to use the Site and Apps, which includes ceasing to
                use the Services.
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              19. Entire Agreement
            </Col>
            <Row>
              <Row style={{ marginTop: 4, marginBottom: 4 }}>
                These Terms and any of our other legal notices, policies and
                guidelines linked to these Terms constitute the entire agreement
                between you and {AGENCY_INFO.name} relating to your use of our Site,
                Services and Apps and supersede any prior understandings or
                agreements (whether oral or written), claims, representations,
                and understandings of the parties regarding such subject matter
                and the Terms may not be amended or modified except in writing
                or by making such amendments or modifications available on our
                Site.
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              20. No Agency; Third Party Beneficiary
            </Col>
            <Row>
              <Row style={{ marginTop: 4, marginBottom: 4 }}>
                We are not your agent, fiduciary, trustee, or other
                representative. Nothing expressed or mentioned in or implied
                from these Terms is intended or will be construed to give to any
                person other than the parties hereto any legal or equitable
                right, remedy, or claim under or in respect to these Terms.
                These Terms and all of the representations, warranties,
                covenants, conditions, and provisions hereof are intended to be
                and are for the sole and exclusive benefit of you, {AGENCY_INFO.name} and the Associated Persons.
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              21. Assignment
            </Col>
            <Row>
              <Row style={{ marginTop: 4, marginBottom: 4 }}>
                You may not assign, convey, subcontract or delegate your rights,
                duties or obligations hereunder without our prior written
                consent. {AGENCY_INFO.name} may assign these Terms and the rights
                and obligations granted hereunder without your consent.
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              22. Severability
            </Col>
            <Row>
              <Row style={{ marginTop: 4, marginBottom: 4 }}>
                If any provision of the Terms is found by a court of competent
                jurisdiction to be invalid, the parties nevertheless agree that
                the court should endeavour to give effect to the parties’
                intentions as reflected in the provision, and the other
                provisions of the Terms remain in full force and effect.
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              23. No Waiver
            </Col>
            <Row>
              <Row style={{ marginTop: 4, marginBottom: 4 }}>
                We will not be considered to have waived any of our rights or
                remedies described in these Terms unless the waiver is in
                writing and signed by us. No delay or omission by us in
                exercising its rights or remedies will impair or be construed as
                a waiver. Any single or partial exercise of a right or remedy
                will not preclude further exercise of any other right or remedy.
                Our failure to enforce the strict performance of any provision
                of these Terms will not constitute a waiver of our right to
                subsequently enforce such provision or any other provisions of
                these Terms.
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              24. Headings
            </Col>
            <Row>
              <Row style={{ marginTop: 4, marginBottom: 4 }}>
                The headings used in these Terms are included for convenience
                only and have no legal or contractual effect and shall not
                affect the construction or interpretation of these Terms.
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              25. Language
            </Col>
            <Row>
              <Row style={{ marginTop: 4, marginBottom: 4 }}>
                You agree that English will be the language of our Site and of
                all transactions occurring in connection with our Services and
                Apps, and you agree to waive any right to use and rely upon any
                other language or translations. You acknowledge that it is your
                express wish that these Terms have been drawn up in English.
                C'est la volenté expresse des parties que la présente convention
                ainsi que tous les documents legales qui s'y rattachent soient
                rédigés en anglais.
              </Row>
            </Row>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col style={{ fontWeight: "bold", color: "#faa632" }}>
              26. Last Modified
            </Col>
          </Row>
          <Row>
            <Row style={{ marginTop: 4, marginBottom: 4 }}>
              These Terms and Conditions were last modified on September 28,
              2022.
            </Row>
          </Row>
        </Row>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col xs="6" align="left">
          <Button onClick={() => handlePrevStep()}>back</Button>
        </Col>
        <Col xs="6" align="right">
          <Button color="success" onClick={() => handleNextStep()}>
            Next
          </Button>
        </Col>
      </Row>
    </FormGroup>
  );
}

export default TermsOfUse;
