import React, { useState } from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import { useParams } from "react-router-dom";
// core components

function RegisterNavbar(props) {
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  });

  const { slug } = useParams();

  // React.useEffect(() => {
  //   setLogoUrl(props.logo);
  // }, [props.logo]);
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className="fixed-top"
        expand="lg"
        id="navbar-main"
        // color="info"
        style={{
          marginBottom: 10,
          padding: 15,
          backgroundColor: "#fa7320",
        }}
      >
        <Container>
          <div className="navbar-translate">
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                // setBodyClick(true);
                setCollapseOpen(!collapseOpen);
              }}
            >
              <span
                className="navbar-toggler-bar bar1"
                style={{ backgroundColor: "white" }}
              />
              <span
                className="navbar-toggler-bar bar2"
                style={{ backgroundColor: "white" }}
              />
              <span
                className="navbar-toggler-bar bar3"
                style={{ backgroundColor: "white" }}
              />
            </button>
            <NavbarBrand
              id="navbar-brand"
              to={`/${slug}`}
              tag={Link}
              style={{ margin: 0, padding: 0 }}
            >
              <img
                style={{ maxHeight: "70px", marginLeft: 40 }}
                src={props.logo}
                alt={props.companyName || "Logo"}
              ></img>
            </NavbarBrand>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  className="mr-2"
                  color="default"
                  nav
                  to={`/${slug}/auth/login`}
                  tag={Link}
                  style={{
                    color: "#fff",
                    margin: 0,

                    fontSize: "18px",
                  }}
                >
                  Login/Training
                </DropdownToggle>
              </UncontrolledDropdown>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  className="mr-2"
                  color="default"
                  //caret
                  nav
                  to={`/${slug}/register`}
                  tag={Link}
                  style={{
                    color: "#fff",
                    margin: 0,
                    fontSize: "18px",
                  }}
                >
                  Register
                </DropdownToggle>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default RegisterNavbar;
