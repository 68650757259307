import { AGENCY_INFO } from "AgencyInfo"

export default [
  {
    _id: "5fab5f69ad1542813c3e4368",
    public: false,
    name: "WHMIS Training",
    content: [
      {
        quiz: {
          options: [],
        },
        _id: "5fab6003100d377b2ce394d5",
        title: "Video 1: Sajit Intro",
        type: "video",
        url: "2lS1hMPODys",
      },
      {
        quiz: {
          options: [],
        },
        _id: "5fadaef1afe7d30e380efd74",
        title: "Video 10: Sajit Intro",
        type: "video",
        url: "zzuld-5Othk",
      },
      {
        quiz: {
          options: [],
        },
        _id: "5fab5f69ad1542813c3e436a",
        title: "Video 2: Employee Hours and Overtime Pay",
        type: "video",
        url: "j32tjynHFgY",
      },
      {
        quiz: {
          options: ["True", "False"],
          question: "Good Housekeeping is essential to safety.",
          correctAnswer: "0",
          explanation:
            'Good housekeeping is essential to safety. "Clean as you work". Maintain good housekeeping in your work area.',
        },
        _id: "5fabd9964d5e275efc540f15",
        title: "Sajit Quiz: Question 9",
        type: "multipleChoice",
      },
      {
        quiz: {
          options: [],
        },
        _id: "5fab5f69ad1542813c3e436b",
        title: "Video 3: Employee Standards in Ontario",
        type: "video",
        url: "2lS1hMPODys",
      },
    ],
    business: "5f74b28743f1be3ba46e0e33",
    agency: "5f7e310cfdca282d24c428f9",
    createdBy: "5f76390e8cd40f40cc663065",
    __v: 0,
  },
  {
    _id: "5fac1c6a35ef865afc39f4a1",
    public: false,
    name: "Safety Training - Set 1",
    content: [
      {
        quiz: {
          options: [],
        },
        _id: "5fac1c6a35ef865afc39f4a2",
        title: "Video 1: Training Intro",
        type: "video",
        url: "zzuld-5Othk",
      },
      {
        quiz: {
          options: [],
        },
        _id: "5fac1c6a35ef865afc39f4a3",
        title: "Video 2: Employee Hours and Overtime Pay",
        type: "video",
        url: "j32tjynHFgY",
      },
      {
        quiz: {
          options: [],
        },
        _id: "5fac1c6a35ef865afc39f4a4",
        title: "Video 3: Employee Standards in Ontario",
        type: "video",
        url: "38LCJsymrfQ",
      },
      {
        quiz: {
          options: [],
        },
        _id: "5fac1c6a35ef865afc39f4a5",
        title: "Video 4: Plant Rules GMP Presentation",
        type: "video",
        url: "2lS1hMPODys",
      },
      {
        quiz: {
          options: [],
        },
        _id: "5fac1c6a35ef865afc39f4a6",
        title: "Video 5: Peanut Allergy",
        type: "video",
        url: "00uwiD4G4YE",
      },
      {
        quiz: {
          options: ["yes", "No"],
          question: "question 2",
          correctAnswer: "0",
          explanation: "explanation here",
        },
        _id: "5faec82ede197006373ab2f0",
        title: "Multiple Choicee",
        type: "multipleChoice",
        url: "",
        text: "",
      },
      {
        quiz: {
          options: [],
          question: "10+2",
          correctAnswer: "12",
          explanation: "",
        },
        _id: "5faec907de197006373ab2f6",
        title: "BAsics",
        type: "shortNumberAnswer",
        url: "",
        text: "",
      },
      {
        quiz: {
          options: [],
          question: "which agency applying for ?",
          correctAnswer: AGENCY_INFO.name,
          explanation: "",
        },
        _id: "5faed12bde197006373ab32e",
        title: "Short Text Answer",
        type: "shortTextAnswer",
        url: "",
        text: "",
      },
      {
        quiz: {
          options: [],
          question: "what is your first language?",
          correctAnswer: "English",
          explanation: "",
        },
        _id: "5faed1c6de197006373ab332",
        title: "Communications Section",
        type: "shortCommunicationAnswer",
        url: "",
        text: "",
      },
    ],
    agency: "5f7e310cfdca282d24c428f9",
    createdBy: "5f76390e8cd40f40cc663065",
    __v: 0,
  },
];
