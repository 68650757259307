/*

List of Storage Items. 

*/

//CHEQUES DATA//
export function setStorageChequesData(data) {
  try {
    localStorage.setItem("chequesData", data);
  } catch {
    console.warn("Problem setting 'chequesData' to local storage");
  }
}
export function getStorageChequesData() {
  try {
    return localStorage.getItem("chequesData");
  } catch {
    console.warn("Problem getting 'chequesData' from local storage");
  }
}

//CHEQUES DATA//
export function setStorageShiftsData(data) {
  try {
    localStorage.setItem("shiftsData", data);
  } catch {
    console.warn("Problem setting 'shiftsData' to local storage");
  }
}
export function getStorageShiftsData() {
  try {
    return localStorage.getItem("shiftsData");
  } catch {
    console.warn("Problem getting 'shiftsData' from local storage");
  }
}

// GENERAL USE CASE
// key: String, data: Any
export function setStorageItem(key, data) {
  try {
    localStorage.setItem(key, data);
  } catch {
    console.warn("Problem setting '", key, "' to local storage");
  }
}

// key: String
export function getStorageItem(key) {
  try {
    return localStorage.getItem(key);
  } catch {
    console.warn("Problem getting '", key, "' from local storage");
  }
}

// USER DATA //
///////////////
// data: Any
export function setStorageUserData(data) {
  try {
    localStorage.setItem("userData", data);
  } catch {
    console.warn("Problem setting 'userData' to local storage");
  }
}
export function getStorageUserData() {
  try {
    return localStorage.getItem("userData");
  } catch {
    console.warn("Problem getting 'userData' from local storage");
  }
}

// BUSINESS DATA //
///////////////////
// data: Any
export function setStorageBusinessData(data) {
  try {
    localStorage.setItem("businessData", data);
  } catch {
    console.warn("Problem setting 'businessData' to local storage");
  }
}
export function getStorageBusinessData() {
  try {
    return localStorage.getItem("businessData");
  } catch {
    console.warn("Problem getting 'businessData' from local storage");
  }
}

// MANAGERS DATA //
///////////////////
// data: Any
export function setStorageManagersData(data) {
  try {
    localStorage.setItem("managersData", data);
  } catch {
    console.warn("Problem setting 'managersData' to local storage");
  }
}
export function getStorageManagersData() {
  try {
    return localStorage.getItem("managersData");
  } catch {
    console.warn("Problem getting 'managersData' from local storage");
  }
}

// SELECTED USER DATA//
///////////////////
// data: Any
export function setStorageSelectedUserData(data) {
  try {
    localStorage.setItem("selectedUserData", data);
  } catch {
    console.warn("Problem setting 'selectedUserData' to local storage");
  }
}
export function getStorageSelectedUserData() {
  try {
    return localStorage.getItem("selectedUserData");
  } catch {
    console.warn("Problem getting 'selectedUserData' from local storage");
  }
}
// SELECTED USER DATA HISTORY//
///////////////////
// data: Any
export function setStorageSelectedUserDataHistory(data) {
  try {
    localStorage.setItem("selectedUserDataHistory", data);
  } catch {
    console.warn("Problem setting 'selectedUserDataHistory' to local storage");
  }
}
export function getStorageSelectedUserDataHistory() {
  try {
    return localStorage.getItem("selectedUserDataHistory");
  } catch {
    console.warn(
      "Problem getting 'selectedUserDataHistory' from local storage"
    );
  }
}

// USER HISTORY DATA //
///////////////////
// data: Any
export function setStorageUserHistoryData(data) {
  try {
    localStorage.setItem("userHistoryData", data);
  } catch {
    console.warn("Problem setting 'userHistoryData' to local storage");
  }
}
export function getStorageUserHistoryData() {
  try {
    return localStorage.getItem("userHistoryData");
  } catch {
    console.warn("Problem getting 'userHistoryData' from local storage");
  }
}

// Validate Email
export function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// Validate SIN. We can only check if a SIN MAY be valid, not if it's actually assigned to anyone
export function validateSin(num) {
  var inputSin = num.toString();
  var sumSin = 0;
  var doubledNumberSin = false;

  // right to left, double every other digit according to that 121212121 digit multiplication thing
  for (var i = inputSin.length - 1; i >= 0; i--) {
    var currentDigit = parseInt(inputSin.charAt(i)); // lets us math the digits
    if (doubledNumberSin) {
      // if doubled digit > 9, we add the 2 resulting digits together
      if (currentDigit * 2 > 9) {
        sumSin += currentDigit * 2 - 9;
      } else {
        sumSin += currentDigit * 2;
      }
    } else {
      sumSin += currentDigit; // just adds the non-doubled digit normally
    }
    var doubledNumberSin = !doubledNumberSin;
  }

  // sum all digits, then /10. If the remainder is 0 then the function returns true for a valid sin. Else we get false and the sin is invalid
  return sumSin % 10 == 0 ? true : false;
}

//returns a time 1 year in the future in the format as a string "yyyy-mm-dd: hh:mm:ss"
export function getExpireDate() {
  let expireDate = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  );
  let dateString = JSON.stringify(expireDate);
  let replacedDate = dateString.replace(/^\"|\.[0-9]{3}$/g, "").split("T");
  let updateDate = replacedDate[0];
  let replacedTime = replacedDate[1].split(".");
  let time = replacedTime[0];
  let expireDateTime = updateDate.concat(": ").concat(time);
  return expireDateTime;
}

//returns the current time in the format as a string "yyyy-mm-dd: hh:mm:ss"
export function getCurrentDate() {
  let currentDate = new Date();
  let dateString = JSON.stringify(currentDate);
  let replacedDate = dateString.replace(/^\"|\.[0-9]{3}$/g, "").split("T");
  let updateDate = replacedDate[0];
  let replacedTime = replacedDate[1].split(".");
  let time = replacedTime[0];
  let updateDateTime = updateDate.concat(": ").concat(time);
  return updateDateTime;
}

export function stringifyTimeStamp(Date) {
  let dateString = JSON.stringify(Date);
  let replacedDate = dateString.replace(/^\"|\.[0-9]{3}$/g, "").split("T");
  let updateDate = replacedDate[0];
  // let replacedTime = replacedDate[1].split(".");
  //let time = replacedTime[0]
  let updateDateTime = updateDate; //.concat(": ").concat(time)
  return updateDateTime;
}

//Utility for react-table for case insensative search filter

export function filterCaseInsensitive(filter, row) {
  const id = filter.pivotId || filter.id;

  if (row[id] === null) {
    return false;
  }
  return row[id] !== undefined
    ? String(String(row[id]).toLowerCase()).startsWith(
        filter.value.toLowerCase()
      )
    : true;
}

//date to hh:mm am/pm format
export const dateToTimeString = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};


// status enum for shift
export const statusEnum = {
  available: 0,
  unavailable: 1,
  noResponse: 2,
  selectedForWork: {
    Applied: 0 /****when user applies to a shift */,
    selected: 1 /***when user is selected for shift */,
    Backup: 2, //when backup is created
    Rejected: 3, //when rejected
    UnScheduled: 4, //when unscheduled by admins
    Dropped: 5, //when shift dropped by user
  },
  suspended: 4,
};
