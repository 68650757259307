export const systemUserIdStart = 10000;
export const systemBusinessIdStart = 100;
export const STRIPE_PUBLIC_KEY = "pk_test_51HKU4ADOMol0pQALCZcIDCiyAY1mo6nPyGlAQhAB1l694YQk4Z7nJWWzmI4JAECkVNS6dI6TMryz2zNTMjUq11Ft00Do7LglMA";

export const PROVINCE_LIST = [
    { value: "Alberta", label: "Alberta" },
    {
      value: "British Columbia",
      label: "British Columbia",
    },
    { value: "Manitoba", label: "Manitoba" },
    {
      value: "New Brunswick",
      label: "New Brunswick",
    },
    {
      value: "Newfoundland and Labrador",
      label: "Newfoundland and Labrador",
    },
    {
      value: "Northwest Territories",
      label: "Northwest Territories",
    },
    { value: "Nova Scotia", label: "Nova Scotia" },
    { value: "Nunavut", label: "Nunavut" },
    { value: "Ontario", label: "Ontario" },
    {
      value: "Prince Edward Island",
      label: "Prince Edward Island",
    },
    { value: "Quebec", label: "Quebec" },
    { value: "Saskatchewan", label: "Saskatchewan" },
    { value: "Yukon", label: "Yukon" },
  ];